import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import { Alert, Typography, Button } from '@passthrough/uikit';
import { SignInEnterEmailStep } from 'pages/signin_v2/enter_email';
import { SignInEnterPasswordStep } from 'pages/signin_v2/enter_password';
import { SignInConfirmEmailStep } from 'pages/signin_v2/confirm_email';
import { SignInWaitStep } from 'pages/signin_v2/wait';
import { SignInTOTPStep } from 'pages/signin_v2/totp';
import { SignInSMSStep } from 'pages/signin_v2/sms';
import { ForgotPassword } from 'pages/signin_v2/forgot_password';
import { ForgotPasswordWait } from 'pages/signin_v2/forgot_password_wait';
import { DASH_URL, validatePath } from 'services/urls';
import { Spinner } from 'components/spinner';
import { resetSupport } from 'components/support';
import { SignInPageContainer } from 'components/sign_in_page_container';
import { useWhiteLabelConfig } from 'services/providers/theme';
import * as api from 'services/api';

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    overflow: 'hidden',
    maxWidth: '460px',
  },
  step: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  hiddenStep: {
    height: 0,
  },
  error: {
    marginTop: theme.spacing(1),
    textAlign: 'left',
  },
  contentHeader: {
    display: 'flex',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  backButton: {
    marginTop: theme.spacing(3),
  },
}));

const STEP_ENTER = 1;
const STEP_CONFIRM = 2;
const STEP_PASSWORD = 3;
const STEP_WAIT = 4;
const STEP_TOTP = 5;
const STEP_SMS = 6;
const STEP_FORGOT_PASSWORD = 7;
const STEP_FORGOT_PASSWORD_WAIT = 8;

const EmailEnterStepTimeouts = {
  enter: 0,
  exit: 500,
};

const EmailConfirmationStepTimeouts = {
  enter: 500,
  exit: 0,
};

function buildSamlUrl(metaDataId, nextUrl) {
  // avoid passing along the string 'null' as the nextUrl
  if (nextUrl) {
    return `/sso/${metaDataId}/?next=${nextUrl}`;
  }

  return `/sso/${metaDataId}/`;
}

function sectionHeadingFromStep(step) {
  if (step === STEP_FORGOT_PASSWORD || step === STEP_FORGOT_PASSWORD_WAIT) {
    return 'Reset your password';
  }
  if (step === STEP_WAIT) {
    return 'Check your email';
  }
  return 'Sign in';
}

function Content({
  userId,
  email,
  setEmail,
  nextUrl,
  step,
  setStep,
  errorComponent,
  socialProvider,
  setSocialProvider,
  setMiscError,
  flashMessage,
}) {
  const classes = useStyles();
  return (
    <div className={classes.stepContainer}>
      <div className={classes.contentHeader}>
        <Typography variant="section-heading">
          {sectionHeadingFromStep(step)}
        </Typography>
        {flashMessage ? <Alert severity="success">{flashMessage}</Alert> : null}
      </div>

      <Slide
        direction="right"
        in={step === STEP_ENTER}
        appear={false}
        mountOnEnter
        unmountOnExit
        timeout={EmailEnterStepTimeouts}
      >
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_ENTER,
          })}
        >
          <SignInEnterEmailStep
            email={email}
            errorComponent={errorComponent}
            onChange={setEmail}
            onConfirm={() => {
              setStep(STEP_WAIT);
            }}
            onPasswordRequired={() => {
              setStep(STEP_PASSWORD);
            }}
            onTOTPRequired={() => {
              setStep(STEP_TOTP);
            }}
            onSMSRequired={() => {
              setStep(STEP_SMS);
            }}
            onSamlRequired={(metadataId) => {
              window.location.replace(buildSamlUrl(metadataId, nextUrl));
            }}
            onSocialAuthEnabled={(data) => {
              setSocialProvider(data.provider);
              setEmail(email);
              setStep(STEP_CONFIRM);
            }}
            nextUrl={nextUrl}
          />
        </div>
      </Slide>
      <Slide
        direction="left"
        in={step === STEP_CONFIRM}
        appear={false}
        mountOnEnter
        unmountOnExit
        timeout={EmailConfirmationStepTimeouts}
      >
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_CONFIRM,
          })}
        >
          <SignInConfirmEmailStep
            userId={userId}
            email={email}
            errorComponent={errorComponent}
            socialProvider={socialProvider}
            onConfirm={() => {
              setStep(STEP_WAIT);
            }}
            onPasswordRequired={() => {
              setStep(STEP_PASSWORD);
            }}
            onSamlRequired={(metadataId) => {
              window.location.replace(buildSamlUrl(metadataId, nextUrl));
            }}
            onTOTPRequired={() => {
              setStep(STEP_TOTP);
            }}
            onSMSRequired={() => {
              setStep(STEP_SMS);
            }}
            onSwitch={() => {
              setStep(STEP_ENTER);
            }}
            nextUrl={nextUrl}
          />
        </div>
      </Slide>
      <Slide
        direction="left"
        in={step === STEP_PASSWORD}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_PASSWORD,
          })}
        >
          <SignInEnterPasswordStep
            email={email}
            errorComponent={errorComponent}
            onConfirm={() => {
              setStep(STEP_WAIT);
            }}
            onSwitch={() => {
              setStep(STEP_ENTER);
            }}
            nextUrl={nextUrl}
          />
        </div>
      </Slide>
      <Slide
        direction="left"
        in={step === STEP_TOTP}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_TOTP,
          })}
        >
          <SignInTOTPStep
            email={email}
            errorComponent={errorComponent}
            onSwitch={() => {
              setStep(STEP_ENTER);
            }}
            onForgotPassword={() => {
              setStep(STEP_FORGOT_PASSWORD);
            }}
          />
        </div>
      </Slide>
      <Slide direction="left" in={step === STEP_SMS} mountOnEnter unmountOnExit>
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_SMS,
          })}
        >
          <SignInSMSStep
            email={email}
            errorComponent={errorComponent}
            setMiscError={setMiscError}
            onSwitch={() => {
              setStep(STEP_ENTER);
            }}
            onForgotPassword={() => {
              setStep(STEP_FORGOT_PASSWORD);
            }}
          />
        </div>
      </Slide>
      <Slide
        direction="right"
        in={step === STEP_FORGOT_PASSWORD}
        mountOnEnter
        unmountOnExit
      >
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_FORGOT_PASSWORD,
          })}
        >
          <ForgotPassword
            email={email}
            onConfirm={() => setStep(STEP_FORGOT_PASSWORD_WAIT)}
            onSwitch={() => setStep(STEP_ENTER)}
            setMiscError={setMiscError}
          />
        </div>
      </Slide>
      <Fade in={step === STEP_WAIT}>
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_WAIT,
          })}
        >
          <SignInWaitStep email={email} />
        </div>
      </Fade>
      <Fade in={step === STEP_FORGOT_PASSWORD_WAIT}>
        <div
          className={clsx(classes.step, {
            [classes.hiddenStep]: step !== STEP_FORGOT_PASSWORD_WAIT,
          })}
        >
          <ForgotPasswordWait
            email={email}
            onSwitch={() => setStep(STEP_ENTER)}
          />
        </div>
      </Fade>
    </div>
  );
}

function ContentError({ goBack }) {
  const classes = useStyles();
  const { productName } = useWhiteLabelConfig();

  return (
    <div className={classes.stepContainer}>
      <div className={classes.contentHeader}>
        <Typography variant="section-heading">Account not found</Typography>
      </div>

      <div className={classes.step}>
        <Typography>
          Please double check that the email address you entered is the address
          where you received your original {productName} invitation. If you were
          invited to {productName} with a different email address, try signing
          in with that one.
        </Typography>

        <div className={classes.backButton}>
          <Button variant="secondary" onClick={goBack}>
            Back to sign in
          </Button>
        </div>
      </div>
    </div>
  );
}

export function SignIn({ signinError, flashMessage, clearFlashMessage }) {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nextUrl = queryParams.get('next');
  const showMagicError = location.pathname.includes('/magic/');
  const classes = useStyles();
  const [userId, setUserId] = useState(decodeURI(params.userId || ''));
  const initialStep = userId ? STEP_CONFIRM : STEP_ENTER;
  const [step, setStep] = useState(initialStep);
  const [email, setEmail] = useState('');
  const [socialProvider, setSocialProvider] = useState(null);
  const [loadingAlreadyLoggedIn, setLoadingAlreadyLoggedIn] = useState(true);
  const [loadingEmail, setLoadingEmail] = useState(!!userId);
  const [miscError, setMiscError] = useState('');

  const error = location.search.includes('failed');
  const removeError = () => {
    setMiscError('');
    history.replace({ search: '' });
  };

  function checkAlreadyLoggedIn() {
    api
      .me()
      .then((response) => {
        // Verify we are the same user in the URL as the one logged in
        if (userId && userId !== response.data.id) {
          // If not the same user, logout so we stay on the
          // signin page of userId from URL
          api.logout().then(() => {
            setLoadingAlreadyLoggedIn(false);
            resetSupport();
          });
        } else {
          // will default to dashboard
          history.replace(validatePath(nextUrl || DASH_URL));
        }
      })
      .catch(() => {
        setLoadingAlreadyLoggedIn(false);
      });
  }

  function getEmail() {
    if (!userId || email) {
      return;
    }

    api
      .userEmail({ userId })
      .then((response) => {
        setEmail(response.data.email);
        setSocialProvider(response.data.socialProvider);
        setLoadingEmail(false);
      })
      .catch(() => {
        setLoadingEmail(false);
        setStep(STEP_ENTER);
      });
  }

  useEffect(checkAlreadyLoggedIn, []);
  useEffect(getEmail, [userId]);

  useEffect(() => {
    if (flashMessage && step !== initialStep) {
      clearFlashMessage();
    }
  }, [flashMessage, step, initialStep]);

  if (loadingEmail || loadingAlreadyLoggedIn) {
    return <Spinner fullScreen />;
  }

  const errorMsg = showMagicError
    ? "The sign in link you used is expired or invalid. Don't worry, you can request a new one below."
    : miscError || signinError;

  const errorComponent = errorMsg ? (
    <div className={classes.error}>
      <Alert severity="error">{errorMsg}</Alert>
    </div>
  ) : null;

  return (
    <SignInPageContainer>
      {error ? (
        <ContentError goBack={removeError} />
      ) : (
        <Content
          userId={userId}
          setUserId={setUserId}
          email={email}
          setEmail={setEmail}
          nextUrl={nextUrl}
          socialProvider={socialProvider}
          setSocialProvider={setSocialProvider}
          step={step}
          setStep={setStep}
          errorComponent={errorComponent}
          setMiscError={setMiscError}
          flashMessage={flashMessage}
        />
      )}
    </SignInPageContainer>
  );
}
