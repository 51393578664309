import React from 'react';
import clsx from 'clsx';
import { Typography as PassTypography } from '@passthrough/uikit';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tooltipArrow: {
    color: theme.palette.primary.main,
  },
  // draftCommentIcon: {
  //   color: theme.palette.error.main,
  // },
  // unresolvedThreadIcon: {
  //   color: theme.palette.warning.main,
  // },
  updatedAnswerIcon: {
    color: theme.palette.warning.main,
  },
  otherQuestionsIcon: {
    color: theme.palette.info.main,
  },
  pageText: {
    fontWeight: 500,
  },
  pageTextMargin: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  listItem: {
    padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(0.5)}px`,
  },
  iconRoot: {
    minWidth: '0px',
    paddingRight: '5px',
  },
}));

function pluralize(number) {
  return number > 1 ? 's' : '';
}

export function PDFTimelineTooltip({
  children,
  pageNum,
  numPages,
  numQuestionsOnPage,
  activeQuestionOnPage,
  activeUpdatedAnswerOnPage,
  numUpdatedAnswersOnPage,
}) {
  const classes = useStyles();
  const showList = Boolean(numUpdatedAnswersOnPage);

  function ListItemWrapper({ id, icon, text }) {
    return (
      <ListItem key={id} className={classes.listItem}>
        <ListItemIcon className={classes.iconRoot}>{icon}</ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItem>
    );
  }

  function getSelectedIconClass() {
    if (activeUpdatedAnswerOnPage) {
      return classes.updatedAnswerIcon;
    }
    return classes.otherQuestionsIcon;
  }

  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      title={
        <>
          <div
            className={clsx({
              [classes.pageTextMargin]: showList,
            })}
          >
            <Typography className={classes.pageText}>
              Page {pageNum} of {numPages}
            </Typography>
            {numQuestionsOnPage > 0 ? (
              <PassTypography variant="label" color="text.secondary">
                {numQuestionsOnPage} question{pluralize(numQuestionsOnPage)}
              </PassTypography>
            ) : null}
          </div>
          {showList && (
            <List>
              {activeQuestionOnPage && (
                <ListItemWrapper
                  id="selected"
                  icon={
                    <FiberManualRecordIcon
                      fontSize="small"
                      className={getSelectedIconClass()}
                    />
                  }
                  text={<Typography>Selected question</Typography>}
                />
              )}

              {numUpdatedAnswersOnPage > 0 ? (
                <ListItemWrapper
                  id="updatedAnswers"
                  icon={
                    <FiberManualRecordIcon
                      className={classes.updatedAnswerIcon}
                      fontSize="small"
                    />
                  }
                  text={
                    <Typography>
                      {numUpdatedAnswersOnPage} updated answer
                      {pluralize(numUpdatedAnswersOnPage)}
                    </Typography>
                  }
                />
              ) : null}
            </List>
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  );
}
