import { objectEquals } from 'services/utils';
import {
  ALLOWED_OPERATIONS,
  NUMBER_LOGIC_TYPES,
  FREE_TEXT_TYPES,
  YES_NO_LOGIC_TYPES,
} from '../../constants';

export const getId = (q) => q.id || q.sectionName;

export const getDiff = ({ data, draftData }) => {
  const publishedById = data.reduce(
    (prev, next) => ({
      ...prev,
      [getId(next)]: next,
    }),
    {},
  );
  const publishedIds = data.map(getId);
  const draftIds = draftData.map(getId);
  const questionOrder = objectEquals(publishedIds, draftIds)
    ? null
    : { oldOrder: publishedIds, newOrder: draftIds };
  const created = draftData.filter(
    (d) => !Object.keys(publishedById).includes(getId(d)),
  );
  const deleted = data.filter((d) => !draftIds.includes(getId(d)));
  const updated = draftData
    .map((q) => {
      const oldVersion = publishedById[getId(q)];

      if (!oldVersion || objectEquals(q, oldVersion)) {
        return null;
      }

      return [oldVersion, q];
    })
    .filter((q) => q !== null);
  return {
    questionOrder,
    updated,
    deleted,
    created,
  };
};

export const validateCustomLogic = (questionnaire) => {
  const published = questionnaire.data.reduce(
    (prev, next) => ({
      ...prev,
      [next.id]: next,
    }),
    {},
  );

  const changed = questionnaire.draftData.filter(
    (q) =>
      published[q.id] &&
      (questionnaire.draftCustomQuestions[q.id] ||
        questionnaire.draftCustomSignatures[q.id]) &&
      !objectEquals(q, published[q.id]),
  );

  if (changed.length) {
    return `You've edited a question/signature with custom logic for
      ${changed.map((q) => q.id).join(', ')}.
      Please double check with an engineer that the edits are compatible
      with the custom logic.
      `;
  }

  return null;
};

export const validateQuestionnaireTime = (
  questionnaire,
  draftQuestionnaireUpdateTime,
) => {
  if (draftQuestionnaireUpdateTime !== questionnaire.updatedAt) {
    return 'Your draft questionnaire is out of date, please refresh to be able to publish';
  }
  return null;
};

export const validateConditionOperation = (questionnaire) => {
  const answerTypeByQuestion = questionnaire.draftData.reduce(
    (prev, next) => ({
      ...prev,
      [next.id]: next.answerType,
    }),
    {},
  );

  const findConditions = (deps) =>
    deps.flatMap((condition) =>
      condition.children ? findConditions(condition.children) : condition,
    );

  const isInvalidCondition = (dep) => {
    if (dep.type === 'multi_condition') return false;
    const answerType = answerTypeByQuestion[dep.questionId];
    const allowedOperations = ALLOWED_OPERATIONS[answerType] || [];
    return !allowedOperations.includes(dep.op || 'eq');
  };

  const validateQuestion = (question) => {
    const invalidConditions = [
      ...new Set(
        findConditions(question.deps || [])
          .filter(isInvalidCondition)
          .map((dep) => dep.questionId),
      ),
    ];

    if (invalidConditions.length) {
      return `${
        question.id
      } has invalid conditions depending on ${invalidConditions.join(', ')}`;
    }

    return null;
  };

  return questionnaire.draftData
    .map(validateQuestion)
    .filter((err) => Boolean(err));
};

export const validateAutoFill = (questionnaire) => {
  const answerTypeByQuestion = questionnaire.draftData.reduce(
    (prev, next) => ({
      ...prev,
      [next.id]: next.answerType,
    }),
    {},
  );

  const validateQuestion = (question) => {
    if (!question.autoFill) {
      return null;
    }

    switch (question.autoFill.type) {
      case 'sum': {
        if (!NUMBER_LOGIC_TYPES.includes(question.answerType)) {
          return `${question.id} answer type is not supported by the selected AutoFill type.`;
        }

        if (!question.autoFill?.questionIds?.length) {
          return `${question.id} has an invalid AutoFill configuration. You must select at least one question.`;
        }

        if (
          question.autoFill.questionIds.some(
            (id) => !NUMBER_LOGIC_TYPES.includes(answerTypeByQuestion[id]),
          )
        ) {
          return `${question.id} has an invalid question selection for AutoFill.`;
        }

        return null;
      }
      case 'copy': {
        const toQuestionType =
          answerTypeByQuestion[question.autoFill.questionId];

        if (!toQuestionType) {
          return `${question.id} has an invalid AutoFill configuration depending on ${question.autoFill.questionId}`;
        }

        if (question.answerType !== toQuestionType) {
          return `${question.id} answer type is not compatible with ${question.autoFill.questionId} for AutoFill.`;
        }
        return null;
      }
      case 'raw': {
        if (NUMBER_LOGIC_TYPES.includes(question.answerType)) {
          const number = Number(question.autoFill.raw);
          if (Number.isNaN(number)) {
            return `${question.id} has an invalid AutoFill value. Must be a number.`;
          }
          return null;
        }

        if (YES_NO_LOGIC_TYPES.includes(question.answerType)) {
          if (typeof question.autoFill.raw !== 'boolean') {
            return `${question.id} has an invalid AutoFill value. Must be a boolean.`;
          }
          return null;
        }

        if (FREE_TEXT_TYPES.includes(question.answerType)) {
          if (typeof question.autoFill.raw !== 'string') {
            return `${question.id} has an invalid AutoFill value. Must be text.`;
          }
          return null;
        }

        return `${question.id} answer type is not supported by the selected AutoFill type.`;
      }
      default:
        return null;
    }
  };

  return questionnaire.draftData
    .map(validateQuestion)
    .filter((err) => Boolean(err));
};

export const getErrors = (questionnaire, draftQuestionnaireUpdateTime) => {
  const errors = [
    validateQuestionnaireTime(questionnaire, draftQuestionnaireUpdateTime),
    ...validateConditionOperation(questionnaire),
    ...validateAutoFill(questionnaire),
  ];
  return errors.filter((err) => err !== null);
};

export const getWarnings = (questionnaire) => {
  const warnings = [validateCustomLogic(questionnaire)];
  return warnings.filter((err) => err !== null);
};

export const getQuestionsWithLinks = (questionnaire) => {
  const linkedQuestions = questionnaire.draftData
    .filter((item) => item.links?.length > 0)
    .map((item) => item.id)
    .flat();

  return linkedQuestions;
};
