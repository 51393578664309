import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { DndSingleFileUpload } from 'components/dnd_file_upload/single_file_upload';
import { PDFNameChecker } from 'components/document_upload/pdf_name_checker';
import { getEmptyFile } from 'components/file_upload';
import { genDataTestLabel } from '../utils';

const useStyles = makeStyles((theme) => ({
  errorText: {
    // default actually works
  },
  helperText: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'normal',
    lineHeight: '1.5rem',
  },
  upload: {
    display: 'block',
    margin: theme.spacing(1, 0),
  },
}));

export function DiligenceFileInput({
  file,
  fileError,
  fileUploadUrl,
  onChange,
  formLabel,
  helpText = '',
  investorName,
  isStaff = false,
  enableNameChecks = false,
  ...extraInputProps
}) {
  const classes = useStyles();

  const fileDataTestLabel = genDataTestLabel(formLabel);

  const [error, setError] = React.useState(null);
  const [fileToCheck, setFileToCheck] = React.useState(getEmptyFile());

  const handleChange = (newFile) => {
    if (enableNameChecks && isStaff && newFile?.fileName) {
      setFileToCheck(newFile);
    } else {
      onChange(newFile);
    }
    setError(null);
  };

  return (
    <FormControl
      className={classes.upload}
      fullWidth
      error={!!(fileError || error?.message)}
    >
      <FormLabel className={classes.label} component="legend">
        {formLabel}
      </FormLabel>

      <FormHelperText className={classes.helperText}>{helpText}</FormHelperText>

      <DndSingleFileUpload
        file={file}
        onChange={handleChange}
        url={fileUploadUrl}
        {...extraInputProps}
        data-test={`diligence_v2_${fileDataTestLabel}_file_input`}
      />
      {isStaff && enableNameChecks ? (
        <PDFNameChecker
          investorName={investorName}
          investorLegalName={null}
          file={file}
          setFile={onChange}
          fileToCheck={fileToCheck}
          setFileToCheck={setFileToCheck}
          setFileError={setError}
          isStaff={isStaff}
          alreadyCheckedPdf={!!file?.fileId}
          entityType="node"
        />
      ) : null}
      {fileError ? (
        <FormHelperText className={classes.errorText}>
          {fileError}
        </FormHelperText>
      ) : null}
      {error?.message ? (
        <FormHelperText className={classes.errorText}>
          {error.message}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
