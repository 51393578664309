import React, { useState } from 'react';

import { useSmsOtpTimer } from 'components/resend_verification_button';
import { getSingleErrorFromResponse } from 'services/utils';
import * as api from 'services/api';

import { MFAFlow } from './mfa';
import { SMSForm } from './sms_form';

export function SignInSMSStep({
  email,
  errorComponent,
  onSwitch,
  setMiscError,
  onForgotPassword,
}) {
  const [password, setPassword] = useState('');
  const [smsOtpCode, setSmsOtpCode] = useState('');
  const [smsOtpError, setSmsOtpError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendError, setResendError] = useState('');
  const { allowSend, timeoutText, onSend } = useSmsOtpTimer({
    onEnd: () => setResendDisabled(false),
  });

  function handleSubmit() {
    setSmsOtpError('');
    setMiscError('');
    setLoading(true);

    return api.loginWithSMS({ email, password, smsOtpCode }).catch((error) => {
      const { response } = error;

      if (response?.status === 400) {
        setSmsOtpError(
          response?.data.smsOtpCode || getSingleErrorFromResponse(response),
        );
      } else if (response?.status === 401) {
        setSmsOtpError('Incorrect verification code.');
      } else if (response?.status === 429) {
        setSmsOtpError(
          'Too many sign in attempts. Please wait a few minutes before trying again.',
        );
      } else {
        setSmsOtpError(getSingleErrorFromResponse(error.response));
      }

      setLoading(false);
      throw error;
    });
  }

  function handleResend(useLoading = true) {
    if (!allowSend) return;
    if (useLoading) setResendLoading(true);

    setResendDisabled(true);
    setMiscError('');
    setResendError('');

    api
      .resendVerification({ email, password })
      .then(() => {
        setResendLoading(false);
        onSend();
      })
      .catch((error) => {
        setResendDisabled(false);
        setResendLoading(false);
        if (error.response?.status === 429) {
          setResendError('Too soon to resend text message.');
        } else {
          setMiscError(getSingleErrorFromResponse(error.response));
        }
      });
  }

  return (
    <MFAFlow
      email={email}
      errorComponent={errorComponent}
      onSwitch={onSwitch}
      password={password}
      setPassword={setPassword}
      onForgotPassword={onForgotPassword}
      onPasswordSuccess={() => handleResend(false)}
      methodComponent={
        <SMSForm
          onSubmit={handleSubmit}
          smsOtpCode={smsOtpCode}
          setSmsOtpCode={setSmsOtpCode}
          smsOtpError={smsOtpError}
          setSmsOtpError={setSmsOtpError}
          loading={loading}
          resendLoading={resendLoading}
          allowSend={allowSend}
          timeoutText={timeoutText}
          onResend={handleResend}
          resendDisabled={resendDisabled}
          resendError={resendError}
        />
      }
    />
  );
}
