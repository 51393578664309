import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { hasLPAnswerChanged } from 'services/utils';
import {
  doesThreadExist,
  isThreadResolved,
  countNumInternalNoteEvents,
} from 'services/thread_utils';
import { PDF_WIDTH } from 'services/pdf_dimensions';
import { Skeleton } from '@material-ui/lab';
import { PDFTimelineTooltip } from './tooltip';

const useStyles = makeStyles((theme) => ({
  timeline: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: `${PDF_WIDTH}px`, // the width of the pdf viewer
    height: '35px',
  },
  slice: {
    height: theme.spacing(2),
    transition: 'height 0.1s',
    '&:hover': {
      height: `${theme.spacing(4.5)}px`,
      border: 'solid white',
      borderWidth: '0px 1px 0px 1px',
    },
    border: '0px solid white',
    cursor: 'pointer',
  },
  savedCommentDraft: {
    backgroundColor: theme.palette.error.main,
  },
  unresolvedCommentThread: {
    backgroundColor: theme.palette.warning.main,
  },
  updatedAnswer: {
    backgroundColor: theme.palette.warning.main,
  },
  pageWithQuestions: {
    backgroundColor: theme.palette.info.main,
  },
  pageWithoutQuestions: {
    height: '8px',
    backgroundColor: theme.palette.neutral.main,
  },
  unselected: {
    opacity: 0.6,
  },
  current: {
    height: `${theme.spacing(4.5)}px`,
    border: 'solid white',
    borderWidth: '0px 1px 0px 1px',
  },
}));

export function PDFTimeline({
  page,
  setPage,
  numPages,
  questions,
  activeQuestion,
  changeEventsPerQuestion,
  priorAnswers,
  lpVersion = false,
  className,
  loading,
}) {
  const classes = useStyles();

  if (loading) {
    return (
      <Skeleton
        className={clsx(classes.timeline, className)}
        animation="wave"
        variant="rect"
      />
    );
  }

  const pagesWithQuestionData = {};

  const pageArray = [...Array(numPages).keys()];
  const sliceWidth = 1 / pageArray.length;
  if (!questions) {
    return null;
  }
  questions.forEach((q) => {
    q.pdfBoxes?.forEach((box) => {
      if (box.highlightType === 'context') return;
      if (box.page in pagesWithQuestionData) {
        if (!pagesWithQuestionData[box.page].includes(q)) {
          pagesWithQuestionData[box.page].push(q);
        }
      } else {
        pagesWithQuestionData[box.page] = [q];
      }
    });
  });

  return (
    <div className={clsx(classes.timeline, className)}>
      {pageArray.map((i) => {
        const pageNum = i + 1;
        const questionsOnPage = pagesWithQuestionData[pageNum] || [];
        const activeQuestionOnPage =
          activeQuestion &&
          questionsOnPage.find((q) => q.label === activeQuestion.label);

        const draftCommentsOnPage = questionsOnPage.filter((q) =>
          Boolean(q.draftComment),
        );
        const pageHasDraftComment = draftCommentsOnPage
          .map((q) => q.pdfBoxes)
          .flat()
          .map((box) => box.page)
          .includes(pageNum);
        const activeDraftCommentOnPage =
          activeQuestionOnPage && Boolean(activeQuestion.draftComment);

        const unresolvedCommentThreadsOnPage = questionsOnPage.filter((q) => {
          const changeEvents = changeEventsPerQuestion[q.label];
          if (lpVersion) {
            return q.needsConfirmation;
          }
          return (
            doesThreadExist(changeEvents) && !isThreadResolved(changeEvents)
          );
        });
        const pageHasUnresolvedCommentThread = unresolvedCommentThreadsOnPage
          .map((q) => q.pdfBoxes)
          .flat()
          .map((box) => box.page)
          .includes(pageNum);

        const activeUnresolvedCommentThreadOnPage =
          activeQuestionOnPage &&
          doesThreadExist(changeEventsPerQuestion[activeQuestion.label]) &&
          !isThreadResolved(changeEventsPerQuestion[activeQuestion.label]);

        const activeUnresolvedState = lpVersion
          ? activeQuestion?.needsConfirmation
          : activeUnresolvedCommentThreadOnPage;

        const pageHasInternalNote =
          !lpVersion &&
          Boolean(
            questionsOnPage.filter((q) => {
              const changeEvents = changeEventsPerQuestion[q.label];
              return countNumInternalNoteEvents(changeEvents) > 0;
            }).length,
          );

        const updatedAnswersOnPage = questionsOnPage.filter(
          (q) =>
            priorAnswers[q.label] &&
            hasLPAnswerChanged(priorAnswers[q.label], q),
        );
        const pageHasUpdatedAnswer = updatedAnswersOnPage
          .map((q) => q.pdfBoxes)
          .flat()
          .map((box) => box.page)
          .includes(pageNum);
        const activeUpdatedAnswerOnPage =
          activeQuestionOnPage &&
          priorAnswers[activeQuestion.label] &&
          hasLPAnswerChanged(
            priorAnswers[activeQuestion.label],
            activeQuestion,
          );

        const pageHasAnswer = questionsOnPage.length > 0;
        let conditionalClass = classes.pageWithoutQuestions;

        if (activeDraftCommentOnPage) {
          conditionalClass = classes.savedCommentDraft;
        } else if (activeQuestionOnPage && activeUnresolvedState) {
          conditionalClass = classes.unresolvedCommentThread;
        } else if (activeUpdatedAnswerOnPage) {
          conditionalClass = classes.updatedAnswer;
        } else if (activeQuestionOnPage) {
          conditionalClass = classes.pageWithQuestions;
        } else if (pageHasDraftComment) {
          conditionalClass = classes.savedCommentDraft;
        } else if (pageHasUnresolvedCommentThread) {
          conditionalClass = classes.unresolvedCommentThread;
        } else if (pageHasUpdatedAnswer) {
          conditionalClass = classes.updatedAnswer;
        } else if (pageHasAnswer) {
          conditionalClass = classes.pageWithQuestions;
        }

        return (
          <PDFTimelineTooltip
            key={pageNum}
            pageNum={pageNum}
            numPages={numPages}
            numQuestionsOnPage={questionsOnPage.length}
            activeQuestionOnPage={activeQuestionOnPage}
            activeDraftCommentOnPage={activeDraftCommentOnPage}
            activeUnresolvedThreadOnPage={activeUnresolvedCommentThreadOnPage}
            activeUpdatedAnswerOnPage={activeUpdatedAnswerOnPage}
            numDraftCommentsOnPage={draftCommentsOnPage.length}
            numUnresolvedThreadsOnPage={unresolvedCommentThreadsOnPage.length}
            numUpdatedAnswersOnPage={updatedAnswersOnPage.length}
            pageHasInternalNote={pageHasInternalNote}
            lpVersion={lpVersion}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={clsx(classes.slice, conditionalClass, {
                [classes.current]: pageNum === page,
                [classes.unselected]: !activeQuestionOnPage,
              })}
              style={{ width: `${sliceWidth * 100}%` }}
              onClick={() => setPage(pageNum)}
              onKeyDown={() => {}}
            />
          </PDFTimelineTooltip>
        );
      })}
    </div>
  );
}
