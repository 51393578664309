import React from 'react';
import { Answers } from '../answers';

export function InfoQuestion({
  updateLpDoc,
  QuestionStepper,
  label,
  QuestionPromptComponent,
}) {
  function handleSubmit(e) {
    e.preventDefault();
    return updateLpDoc({ label, answer: null });
  }

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent
        // Info questions are always optional, but we don't want the
        // (Optional) prefix text prepended
        addPrefixText={false}
      />
      <Answers short>
        <QuestionStepper
          disabled={false}
          handleSubmit={handleSubmit}
          hasInput={false}
        >
          Continue
        </QuestionStepper>
      </Answers>
    </form>
  );
}
