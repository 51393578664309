import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import FormHelperText from '@material-ui/core/FormHelperText';
import { getEmptyFile } from 'components/file_upload';
import { containsLpName, cleanLPName } from 'services/utils';
import { StaffReviewFile } from 'components/staff_review_file';
import { InternalChip } from '@passthrough/uikit';
import * as api from 'services/api';

export function PDFNameChecker({
  investorName,
  investorLegalName,
  file,
  setFile,
  fileToCheck,
  setFileToCheck,
  setFileError,
  isStaff,
  alreadyCheckedPdf = false,
  entityType = 'lp',
}) {
  const { fundId } = useParams();
  const [skipStaffReview, setSkipStaffReview] = useState(alreadyCheckedPdf);
  const [foundNameInPDF, setFoundNameInPDF] = useState(false);

  function formatNamesForMessage() {
    if (investorLegalName) {
      return (
        `the ${entityType} name "${cleanLPName(investorName)}"` +
        ` or the ${entityType}'s legal name "${cleanLPName(investorLegalName)}"`
      );
    }
    return `the ${entityType} name "${cleanLPName(investorName)}"`;
  }

  function alternateChecks() {
    if (
      !containsLpName(fileToCheck.fileName, investorName) &&
      !containsLpName(fileToCheck.fileName, investorLegalName)
    ) {
      setFileError({
        message:
          `You uploaded "${fileToCheck.fileName}", which doesn't appear` +
          ` to include ${formatNamesForMessage()}` +
          ' in the file contents. To force the upload, the file name' +
          ` must include ${formatNamesForMessage()}.`,
        isStaff: true,
      });
    } else {
      setFile(fileToCheck);
      setFileError(null);
    }
  }

  function staffFileUploadCheck() {
    // Check the file contents for either the lp name or the legal name.
    // If not found, ensure that the file name contains the lp name
    // or legal name, and do the manual file review.
    api
      .fileUploadNameCheck({
        fundId,
        fileId: fileToCheck.fileId,
        lpName: investorName,
        legalName: investorLegalName,
      })
      .then((response) => {
        if (response.data.passedNameCheck) {
          // Found the investor name in the file contents, so we don't
          // need to do the other checks.
          setFoundNameInPDF(true);
          setFile(fileToCheck);
          setFileError(null);
          setSkipStaffReview(true);
        } else {
          alternateChecks();
        }
        setFileToCheck(getEmptyFile());
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          // The request timed out, do the alternate checks instead.
          alternateChecks();
        }
        if (error.response?.status === 400) {
          setFileError({
            message:
              `The file "${fileToCheck.fileName}" couldn't be read.` +
              ' It may be encrypted or corrupt.',
            isStaff: true,
          });
        }
        setFileToCheck(getEmptyFile());
      });
  }

  useEffect(() => {
    if (!file?.fileId) {
      setFoundNameInPDF(false);
      setSkipStaffReview(false);
      setFileToCheck(getEmptyFile());
    }
  }, [file?.fileId]);

  useEffect(() => {
    if (fileToCheck?.fileId) {
      staffFileUploadCheck();
    }
  }, [fileToCheck?.fileId]);

  if (!(isStaff && investorName)) {
    return null;
  }

  return (
    <>
      {fileToCheck?.fileId ? (
        <FormHelperText>
          <InternalChip /> Checking file contents for {formatNamesForMessage()}
          ...
        </FormHelperText>
      ) : null}
      {foundNameInPDF ? (
        <FormHelperText>
          <InternalChip /> Found {formatNamesForMessage()} in file contents.
          Skipping manual review.
        </FormHelperText>
      ) : null}
      <StaffReviewFile
        lpName={investorName}
        file={file}
        skipStaffReview={skipStaffReview}
        onReject={() => setFile(getEmptyFile())}
      />
    </>
  );
}
