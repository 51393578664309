import React from 'react';
import { Alert, Modal } from '@passthrough/uikit';
import { getEmptyFile } from 'components/file_upload';
import { getSingleErrorFromResponse } from 'services/utils';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { DndSingleFileUpload } from 'components/dnd_file_upload/single_file_upload';

export const OfferingDocReplaceDialog = ({
  fundId,
  document,
  onClose,
  onUpdate,
}) => {
  const open = Boolean(document);
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(getEmptyFile());
  const [error, setError] = React.useState(null);
  const { toast } = useToast();

  const handleSubmit = () => {
    setLoading(true);
    api
      .offeringDocUpdate({
        fundId,
        offeringDocId: document.id,
        fileId: file.fileId,
      })
      .then(() => {
        onUpdate();
        onClose();
        toast(`Replaced ${document.name}`);
      })
      .catch((e) => {
        const message = getSingleErrorFromResponse(e.response);
        setError(message);
        setLoading(false);
      });
  };

  const handleChange = (f) => {
    setError(null);
    setFile(f);
  };

  return (
    <Modal
      headerLabel="Replace document"
      subheaderLabel={document?.name}
      open={open}
      onClose={onClose}
      size="xs"
      primaryButtonText="Save"
      primaryButtonLoading={loading}
      primaryButtonDisabled={!file?.fileId}
      onSubmit={handleSubmit}
      onExited={() => {
        setFile(getEmptyFile());
        setError(null);
        setLoading(false);
      }}
      showCancelButton
    >
      <Alert severity="warning">
        This document will be replaced for all existing and new investors.
      </Alert>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <DndSingleFileUpload
        file={file}
        onChange={handleChange}
        url={`/api/fund/${fundId}/file/upload/`}
        acceptedFileTypes="application/pdf"
      />
    </Modal>
  );
};
