import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { SSNInput } from 'components/ssn';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function SSNQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  frontendLabel,
  QuestionPromptComponent,
}) {
  const initialSsn = answer || '';
  const [ssn, setSsn] = useState(initialSsn);
  const [errorMsg, setErrorMsg] = useState('');

  const isValid = ssn.match(/\d\d\d-\d\d-\d\d\d\d/);
  const hasInput = Boolean(ssn);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: ssn, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setSsn(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setSsn(initialSsn);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="ssn"
          autoFocus
          label={frontendLabel || 'Social Security Number'}
          variant="outlined"
          value={ssn}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          InputProps={{
            inputComponent: SSNInput,
          }}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!isValid}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
