import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { parseISO, format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, UserDisplay } from '@passthrough/uikit';

import * as api from 'services/api';
import { Callout } from 'components/Callout';

import { HistoryFileDownload } from './history_file_download';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 3, 2, 3),
  },
  date: {
    padding: theme.spacing(1, 0),
  },
  invisible: {
    display: 'none',
  },
  timelineContent: {
    padding: theme.spacing(0.5, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    wordBreak: 'break-word',
  },
  timelineItem: {
    minHeight: '0',
  },
  paper: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0, 0.5, 0),
    backgroundColor: theme.palette.neutral.fill,
  },
  included: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
  userName: {
    color: theme.palette.text.primary,
  },
  connector: {
    borderLeftWidth: '2px',
    borderLeftStyle: 'dashed',
    backgroundColor: theme.palette.background.default,
  },

  draftBorder: {
    borderColor: theme.palette.divider,
  },
  waitingBorder: {
    borderColor: theme.palette.status.waiting,
  },
  signedBorder: {
    borderColor: theme.palette.status.signed,
  },
  doneBorder: {
    borderColor: theme.palette.success.main,
  },

  draftBackground: {
    backgroundColor: theme.palette.action.disabled,
  },
  waitingBackground: {
    backgroundColor: theme.palette.status.waiting,
  },
  signedBackground: {
    backgroundColor: theme.palette.status.signed,
  },
  doneBackground: {
    backgroundColor: theme.palette.success.main,
  },
}));

const API_TYPES = ['API_CREATED', 'API_SENT_TO_INVESTOR'];
const SDK_TYPES = ['SDK_STARTED', 'SDK_SIGNED'];
const PASSIVE_EVENT_TYPES = ['DILIGENCE_RECOMMEND_REMOVED'];

const TYPE_MAPPING = {
  API_CREATED: 'draft',
  DRAFT_CREATED: 'draft',
  EXTERNAL_SIGNUP: 'draft',
  ADDED_SIDELETTER: 'draft',
  REMOVED_SIDELETTER: 'draft',
  ADDED_OFFERING: 'draft',
  REMOVED_OFFERING: 'draft',
  REPLACED_OFFERING: 'draft',
  MOVED_CLOSING: 'draft',
  MOVED_DILIGENCE: 'draft',
  SENT_TO_INVESTOR: 'waiting',
  SENT_TO_INVESTOR_NO_EMAIL: 'waiting',
  API_SENT_TO_INVESTOR: 'waiting',
  SENT_REMINDER_TO_INVESTOR: 'waiting',
  ADDITIONAL_USER_ADDED: 'draft',
  USER_REMOVED: 'draft',
  STARTED: 'waiting',
  SDK_STARTED: 'waiting',
  SIGNED: 'signed',
  SDK_SIGNED: 'signed',
  LP_REVIEW_REQUESTED: 'signed',
  LP_MAKE_CHANGES: 'waiting',
  CHANGES_REQUESTED: 'waiting',
  COMMENTS_SENT: 'waiting',
  UNDO_CHANGE_REQUEST: 'signed',
  APPROVED: 'signed',
  UNAPPROVED: 'waiting',
  DILIGENCE_APPROVED: 'signed',
  DILIGENCE_UNAPPROVED: 'waiting',
  QUESTIONNAIRE_APPROVED: 'signed',
  QUESTIONNAIRE_UNAPPROVED: 'waiting',
  SENT_TO_COUNTERSIGNER: 'signed',
  COUNTERSIGNED: 'done',
  MARKED_FULLY_EXECUTED: 'done',
  UPLOADED_UNSIGNED: 'draft',
  UPLOADED_SIGNED: 'signed',
  UPLOADED_FULLY_EXECUTED: 'done',
  RESENT_CLOSING_EMAIL: 'done',
  CUSTOM_ACTION: 'signed',
  DILIGENCE_RECOMMEND_CRITICAL: 'draft',
  DILIGENCE_RECOMMEND_HIGH: 'draft',
  DILIGENCE_RECOMMEND_MODERATE: 'draft',
  DILIGENCE_RECOMMEND_LOW: 'draft',
  DILIGENCE_RECOMMEND_NO_RATING: 'draft',
  DILIGENCE_RECOMMEND_NO_CHOICE: 'draft',
  DILIGENCE_RECOMMEND_REMOVED: 'draft',
  EXEMPTED_FROM_DILIGENCE: 'signed',
  WAIVED_DILIGENCE_VALIDATION: 'signed',
  REQUIRED_DILIGENCE_VALIDATION: 'signed',
  CLEARED_SIGNATURES: 'waiting',
};

const ACTION_MAPPING = {
  API_CREATED: 'created draft',
  DRAFT_CREATED: 'created draft',
  EXTERNAL_SIGNUP: 'self signed-up',
  ADDED_SIDELETTER: 'added document',
  REMOVED_SIDELETTER: 'removed document',
  ADDED_OFFERING: 'added document',
  REMOVED_OFFERING: 'removed document',
  REPLACED_OFFERING: 'replaced document',
  MOVED_CLOSING: 'moved from',
  MOVED_DILIGENCE: 'Diligence questionnaire moved from',
  SENT_TO_INVESTOR: 'sent to investor',
  SENT_TO_INVESTOR_NO_EMAIL: 'sent to investor',
  API_SENT_TO_INVESTOR: 'sent to investor',
  SENT_REMINDER_TO_INVESTOR: 'sent reminder to investor',
  ADDITIONAL_USER_ADDED: '',
  USER_REMOVED: '',
  STARTED: 'started',
  SDK_STARTED: 'started',
  SIGNED: 'completed',
  SDK_SIGNED: 'completed',
  UNDO_CHANGE_REQUEST: 'dismissed change request',
  CHANGES_REQUESTED: 'requested changes',
  COMMENTS_SENT: 'sent comments',
  LP_REVIEW_REQUESTED: 'requested a review',
  LP_MAKE_CHANGES: 'made changes',
  SENT_FOR_SIGNATURE: 'sent for investor signature',
  APPROVED: 'approved',
  UNAPPROVED: 'undid approval',
  DILIGENCE_APPROVED: 'completed the review for diligence',
  DILIGENCE_UNAPPROVED: 'reversed the review for diligence to incomplete',
  QUESTIONNAIRE_APPROVED: 'approved the questionnaire',
  QUESTIONNAIRE_UNAPPROVED: 'unapproved the questionnaire',
  SENT_TO_COUNTERSIGNER: 'sent to countersigner',
  COUNTERSIGNED: 'countersigned',
  MARKED_FULLY_EXECUTED: 'marked as fully executed',
  UPLOADED_UNSIGNED: 'uploaded documents',
  UPLOADED_SIGNED: 'uploaded signed documents',
  UPLOADED_FULLY_EXECUTED: 'uploaded fully executed documents',
  RESENT_CLOSING_EMAIL: 'sent the closing email',
  CUSTOM_ACTION: 'performed custom action',
  DILIGENCE_RECOMMEND_CRITICAL:
    'selected critical risk as recommended risk rating',
  DILIGENCE_RECOMMEND_HIGH: 'selected high risk as recommended risk rating',
  DILIGENCE_RECOMMEND_LOW: 'selected low risk as recommended risk rating',
  DILIGENCE_RECOMMEND_MODERATE:
    'selected moderate risk as recommended risk rating',
  DILIGENCE_RECOMMEND_NO_RATING: 'was unable to assess risk',
  DILIGENCE_RECOMMEND_NO_CHOICE: 'removed recommended risk rating',
  DILIGENCE_RECOMMEND_REMOVED: 'Risk rating removed due to a change made by',
  EXEMPTED_FROM_DILIGENCE: 'deleted diligence',
  WAIVED_DILIGENCE_VALIDATION: 'made diligence optional',
  REQUIRED_DILIGENCE_VALIDATION: 'made diligence required',
  CLEARED_SIGNATURES: 'cleared signatures from',
};

const DATA_MOVED_TYPES = ['MOVED_CLOSING', 'MOVED_DILIGENCE'];

function ItemDescription({
  isPassiveEvent,
  userName,
  groupName,
  extraName,
  extraSecondName,
  extraConnector,
  email,
  message,
  action,
  date,
  files,
}) {
  const classes = useStyles();

  if (isPassiveEvent) {
    return (
      <>
        <Typography variant="body" size="small" color="text.secondary">
          {action}{' '}
          <UserDisplay variant="inline" name={userName} email={email} />
        </Typography>

        <Typography variant="label" size="small" color="text.secondary">
          {format(date, 'h:mm a')}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="body" size="small" color="text.secondary">
        <UserDisplay variant="inline" name={userName} email={email} />
        {groupName ? (
          <>
            {' in '}
            <span className={classes.userName}>{groupName}</span>
            {' group'}
          </>
        ) : null}{' '}
        {action}
        {extraName ? (
          <>
            {' '}
            <span className={classes.userName}>{extraName}</span>
          </>
        ) : null}
        {extraSecondName ? (
          <>
            {' '}
            {extraConnector}{' '}
            <span className={classes.userName}>{extraSecondName}</span>
          </>
        ) : null}
        {message ? (
          <>
            {' with a message: '}
            <Callout message={message} dense />
          </>
        ) : null}
        {files?.length ? <HistoryFileDownload files={files} /> : null}
      </Typography>

      <Typography variant="label" size="small" color="text.secondary">
        {format(date, 'h:mm a')}
      </Typography>
    </>
  );
}

function Item({
  type,
  isPassiveEvent,
  userName,
  email,
  action,
  date,
  prevDate,
  message,
  groupName,
  extraName,
  extraConnector,
  extraSecondName,
  isLast,
  files,
}) {
  const classes = useStyles();
  const prevDay = prevDate ? format(prevDate, 'd MMM yyyy') : '';
  const day = format(date, 'd MMM yyyy');

  return (
    <>
      {prevDay === day ? null : (
        <div className={classes.date}>
          <Typography variant="body" size="small" color="text.secondary">
            {day}
          </Typography>
        </div>
      )}
      <TimelineItem className={classes.timelineItem}>
        <TimelineOppositeContent className={classes.invisible} />

        <TimelineSeparator>
          <TimelineDot
            className={clsx({
              [classes.draftBorder]: type === 'draft',
              [classes.waitingBorder]: type === 'waiting',
              [classes.signedBorder]: type === 'signed',
              [classes.doneBorder]: type === 'done',
            })}
            variant="outlined"
          />
          {isLast ? null : (
            <TimelineConnector
              className={clsx(classes.draftBorder, classes.connector)}
            />
          )}
        </TimelineSeparator>

        <TimelineContent className={classes.timelineContent}>
          <ItemDescription
            isPassiveEvent={isPassiveEvent}
            userName={userName}
            groupName={groupName}
            extraName={extraName}
            extraSecondName={extraSecondName}
            extraConnector={extraConnector}
            email={email}
            message={message}
            action={action}
            date={date}
            files={files}
          />
        </TimelineContent>
      </TimelineItem>
    </>
  );
}

/**
 * A skeleton for the timeline item. Should resemble the Item component.
 */
function LoadingItem() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.date}>
        <Typography variant="body" size="small" color="text.secondary">
          <Skeleton width="100px" />
        </Typography>
      </div>

      <TimelineItem>
        <TimelineOppositeContent className={classes.invisible} />
        <TimelineSeparator>
          <TimelineDot variant="outlined" />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Typography variant="body" size="small" color="text.secondary">
            <Skeleton width="70%" />
          </Typography>
          <Typography variant="label" size="small" color="text.secondary">
            <Skeleton width="10%" />
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </>
  );
}

function formatImpactedUserData(impactedUsers) {
  return impactedUsers.map(({ name, email }, i) => {
    const includeComma = i < impactedUsers.length - 1;
    return (
      <>
        <UserDisplay variant="inline" name={name} email={email} />
        {includeComma ? ', ' : ''}
      </>
    );
  });
}

function getAction(event) {
  if (event.type === 'ADDITIONAL_USER_ADDED') {
    return <>added {formatImpactedUserData(event.impactedUsers)}</>;
  }
  if (event.type === 'USER_REMOVED') {
    return <>removed {formatImpactedUserData(event.impactedUsers)}</>;
  }

  if (
    (event.type === 'DRAFT_CREATED' || event.type === 'API_CREATED') &&
    event.impactedUsers?.length > 0
  ) {
    const numUsers = event.impactedUsers.length;

    return (
      <>
        created draft with {numUsers} collaborator{numUsers > 1 ? 's' : ''}:{' '}
        {formatImpactedUserData(event.impactedUsers)}
      </>
    );
  }

  return ACTION_MAPPING[event.type];
}

function SmartItem({ event, prevEvent, isLast }) {
  const isApi = API_TYPES.includes(event.type);
  const isSdk = SDK_TYPES.includes(event.type);

  return (
    <Item
      type={TYPE_MAPPING[event.type]}
      isPassiveEvent={PASSIVE_EVENT_TYPES.includes(event.type)}
      action={getAction(event)}
      userName={isApi ? 'API' : event.userName}
      email={event.userEmail}
      groupName={event.customGroupName}
      extraName={
        (isSdk ? ' via SDK' : null) ||
        event.sideletterName ||
        event.oldClosingName ||
        event.customActionText
      }
      extraConnector={DATA_MOVED_TYPES.includes(event.type) ? 'to' : ''}
      extraSecondName={event.newClosingName}
      date={parseISO(event.date)}
      prevDate={prevEvent?.date ? parseISO(prevEvent.date) : null}
      message={event.message}
      files={event.files || []}
      isLast={isLast}
    />
  );
}

export function HistoryTab({ change }) {
  const { fundId, lpClosingId } = useParams();
  const [events, setEvents] = useState(null);
  const classes = useStyles();

  function getEvents() {
    api.lpClosingEvents({ fundId, lpClosingId }).then((response) => {
      setEvents(response.data);
    });
  }

  useEffect(getEvents, [fundId, change]);

  return (
    <div className={classes.root}>
      <Timeline>
        {events ? (
          events.map((event, index) => (
            <SmartItem
              key={event.id}
              event={event}
              prevEvent={index === 0 ? null : events[index - 1]}
              isLast={index === events.length - 1}
            />
          ))
        ) : (
          <LoadingItem />
        )}
      </Timeline>
    </div>
  );
}
