import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Typography, Icons } from '@passthrough/uikit';

import { Spinner } from 'components/spinner';
import { useTasks } from 'services/providers/tasks';
import { useFunds } from 'services/providers/funds';
import { useMe } from 'services/providers/me';
import * as urls from 'services/urls';
import { getSelectedStaffOrg } from 'services/utils';
import { useWhiteLabelConfig } from 'services/providers/theme';
import { useRegionRedirect } from 'services/region';

import { EmptyState } from 'components/empty_v2';
import { PageContainer } from 'components/page_container';
import { TaskList } from './task_list';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: '1.3rem', // same as button xl
    alignSelf: 'center',
  },
}));

export function TasksPage() {
  const [tasks, isLoadingTasks, refreshTasks] = useTasks();
  const [funds, isLoadingFunds, refreshFunds] = useFunds();
  const [me] = useMe();
  const history = useHistory();
  const selectedStaffOrg = getSelectedStaffOrg(me);
  const whiteLabelConfig = useWhiteLabelConfig();
  const region = useRegionRedirect();
  const classes = useStyles();

  useEffect(refreshTasks, []);
  useEffect(refreshFunds, []);

  let dashboardOrg = me.dashboardOrgs[0];
  if (selectedStaffOrg) {
    dashboardOrg = me.dashboardOrgs.find((org) => org.id === selectedStaffOrg);
  }

  const skipUrl = dashboardOrg?.id
    ? urls.organizationFundsUrl({ organizationId: dashboardOrg?.id })
    : urls.FUNDS_URL;

  if (isLoadingTasks || isLoadingFunds || region.isLoading) {
    return <Spinner fullScreen />;
  }

  let tasksToDisplay = tasks;
  if (whiteLabelConfig?.organizationIds?.length) {
    tasksToDisplay = tasksToDisplay?.filter((taskData) =>
      whiteLabelConfig.organizationIds.includes(taskData.orgId),
    );
  }
  if (me.isStaff) {
    tasksToDisplay = tasksToDisplay?.filter(
      (taskData) => taskData.orgId === selectedStaffOrg,
    );
  }

  let fundsToDisplay = funds;
  if (me.isStaff) {
    fundsToDisplay = fundsToDisplay.filter(
      (fund) => fund.organizationId === selectedStaffOrg,
    );
  }

  if (tasksToDisplay.length === 0 && dashboardOrg?.id) {
    return <Redirect to={skipUrl} />;
  }

  if (tasksToDisplay.length === 0 && fundsToDisplay.length > 0) {
    return <Redirect to={urls.FUNDS_URL} />;
  }

  // If the user doesn't have any data in the current region and they have
  // data in a different region, we must redirect them to the right region.
  if (
    tasksToDisplay.length === 0 &&
    fundsToDisplay.length === 0 &&
    region.url
  ) {
    window.location.href = region.url;
    return null;
  }

  if (tasksToDisplay.length === 0 && fundsToDisplay.length === 0) {
    const ctaProps = me.hasCompletedInvestment
      ? {
          ctaText: 'View all investments',
          ctaOnClick: () => history.push(urls.INVESTMENTS_URL),
          ctaPermission: true,
        }
      : {};

    return (
      <EmptyState
        title="No tasks"
        text="Any investments waiting for your actions will appear here."
        Icon={Icons.Checklist}
        iconVariant="success"
        {...ctaProps}
      />
    );
  }

  const showSkipButton = Boolean(fundsToDisplay.length > 0 || dashboardOrg?.id);

  return (
    <PageContainer maxWidth="sm">
      <div className={classes.header}>
        <div className={classes.titleRow}>
          <div className={classes.title}>
            <Typography variant="page-heading">Tasks</Typography>
          </div>
        </div>
        {showSkipButton ? (
          <Button
            variant="text"
            href={skipUrl}
            endIcon={<Icons.ArrowForward />}
            size="large"
          >
            Skip
          </Button>
        ) : null}
      </div>
      <TaskList tasks={tasksToDisplay} />
    </PageContainer>
  );
}
