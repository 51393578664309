import React from 'react';

import { DatePicker } from 'components/date';
import { Address } from 'components/address_v2';
import { CountryInput } from 'components/country';
import { format, isValid } from 'date-fns';
import { CountriesInput } from './countries';
import { DiligenceV2SSN } from '../ssn';

export function IndividualDetails({
  dob,
  dobError,
  exposeNationality,
  nationality,
  nationalityError,
  ssn,
  ssnError,
  useSsn,
  residentialAddress,
  residentialAddressError,
  handleChange,
  disableFlip,
  taxIDLabel = 'Other tax ID',
  exposeCitizenship,
  citizenshipError,
  countriesOfCitizenship,
  countryLabel,
}) {
  return (
    <>
      <DatePicker
        fullWidth
        date={dob}
        setDate={(e) => {
          if (isValid(e)) {
            const dateStr = format(e, 'MM/dd/yyyy');
            handleChange('dob', dateStr);
          } else {
            // otherwise it will be an invalid Date object but we need to
            // retain that object to continue displaying partially valid
            // data and so that we ultimately send a null to the backend
            // to get a custom error response
            handleChange('dob', e);
          }
        }}
        label="Date of birth"
        error={!!dobError}
        helperText={dobError}
        data-test="diligence_v2_dob"
      />

      {exposeCitizenship ? (
        <CountriesInput
          frontendLabel="Country of citizenship"
          countries={countriesOfCitizenship}
          errorMsg={citizenshipError}
          helperText="Add all countries of citizenship"
          handleChange={(e, countries) => {
            handleChange('countriesOfCitizenship', countries || []);
          }}
          dataTestLabel="diligence_v2_countries_of_citizenship"
        />
      ) : null}

      {exposeNationality ? (
        <CountryInput
          frontendLabel="Nationality"
          value={nationality}
          errorMsg={nationalityError}
          handleChange={(e, country) => {
            handleChange('nationality', country?.code || '');
          }}
        />
      ) : null}

      <DiligenceV2SSN
        ssn={ssn}
        ssnError={ssnError}
        useSsn={useSsn}
        handleChange={handleChange}
        data-test="diligence_v2_ssn"
        disableFlip={disableFlip}
        taxIDLabel={taxIDLabel}
      />

      <Address
        address={residentialAddress}
        onChange={(addr) => {
          handleChange('residentialAddress', addr);
        }}
        countryLabel={countryLabel ?? 'Country'}
        errors={residentialAddressError}
        label="Residential address"
        data-test="diligence_v2_residential_address"
      />
    </>
  );
}
