import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Modal,
  UIKitSettingsProvider,
  Typography,
  useConfirm,
} from '@passthrough/uikit';

import * as api from 'services/api';
import { useToast } from 'services/toast';

export function GenericDataExportModal({
  open,
  handleClose,
  headerLabel,
  investors,
  onExport,
  generateExportEndpoint,
}) {
  const timeoutRef = useRef(null);
  const { fundId, closingId } = useParams();
  const { successToast } = useToast();
  const confirm = useConfirm();
  const [exportLoading, setExportLoading] = useState(false);
  const [exportUrl, setExportUrl] = useState(null);

  function pollForResults(exportId) {
    api
      .getLpClosingsExport({ fundId, closingId, exportId })
      .then((response) => {
        if (response.data.url) {
          setExportLoading(false);
          setExportUrl(response.data.url);
          successToast('Generated export');
        } else {
          timeoutRef.current = setTimeout(() => {
            pollForResults(exportId);
          }, 500);
        }
      });
  }

  function onSubmit(e) {
    e.preventDefault();

    setExportLoading(true);
    const lpClosingIds = investors.map((i) => i.id);

    generateExportEndpoint({
      fundId,
      closingId,
      lpClosingIds,
    }).then((response) => {
      const exportId = response.data;
      timeoutRef.current = setTimeout(() => {
        pollForResults(exportId);
      }, 500);
    });
  }

  function onCloseWithoutDownloadingExport() {
    confirm({
      title: 'Leave without report?',
      description: 'You have not downloaded your report.',
      confirmationText: 'Leave',
      size: 'xs',
    })
      .then(() => {
        handleClose();
        if (onExport) {
          onExport();
        }
      })
      .catch(() => {});
  }

  return (
    <UIKitSettingsProvider redirectAttribute="href">
      <Modal
        open={open}
        headerLabel={headerLabel}
        subheaderLabel={`${investors.length} investor${
          investors.length > 1 ? 's' : ''
        } selected`}
        onClose={() => {
          if (exportUrl || exportLoading) {
            onCloseWithoutDownloadingExport();
          } else {
            handleClose();
          }
        }}
        onExited={() => {
          setExportUrl(null);
          setExportLoading(false);
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }}
        onSubmit={onSubmit}
        primaryButtonText={exportUrl ? 'Download report' : 'Generate export'}
        primaryButtonLoading={exportLoading}
        primaryButtonEndIcon={exportUrl ? <GetAppIcon /> : null}
        primaryButtonProps={{
          href: exportUrl,
          // if the primary button is provided an href and renders as
          // an anchor tag, onSubmit wont fire when clicking it so we
          // need to pass another manual handler
          onClick: () => {
            if (exportUrl) {
              handleClose();
              if (onExport) {
                onExport();
              }
            }
          },
        }}
      >
        <Typography>Generating an export may take a few moments.</Typography>
      </Modal>
    </UIKitSettingsProvider>
  );
}
