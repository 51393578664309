import React from 'react';
import { Button, UIKitSettingsProvider, Link } from '@passthrough/uikit';

function isPdfFile(fileName) {
  try {
    return fileName.split('.').reverse()[0] === 'pdf';
  } catch {
    return false;
  }
}

export function OpenDocumentButton({ fileName, type, url, onClick }) {
  const canOpenInApp = isPdfFile(fileName);

  return (
    <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
      {canOpenInApp ? (
        <Button
          variant="secondary"
          size="small"
          onClick={() => onClick({ type, fileName, url })}
        >
          Open
        </Button>
      ) : (
        <Link variant="external" href={url}>
          Open
        </Link>
      )}
    </UIKitSettingsProvider>
  );
}
