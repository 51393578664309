import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import { Spinner } from 'components/spinner';
import * as constants from 'components/gp_selection_input/constants';
import { Modal, Alert, Button, useConfirm } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import * as urls from 'services/urls';

import { useSupport } from 'components/support';
import {
  JurisdictionSelect,
  JURISDICTIONS,
} from 'components/jurisdiction_select';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { useMe } from 'services/providers/me';
import { useFund } from 'services/providers/fund';
import { objectEquals } from 'services/utils';

export function NewJurisdictionDialog({
  fundId,
  managedDiligenceEnabled,
  open,
  onClose,
  members,
  isLoadingMembers,
}) {
  const [me] = useMe();
  const [fund] = useFund();
  const history = useHistory();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(false);

  const showOptOutOfCustomApproval = Boolean(
    me.isStaff && managedDiligenceEnabled && fund.hasCustomApprovalGroups,
  );
  // default to checked when checkbox is visible, otherwise default to false
  const initialForm = {
    optOutOfCustomApproval: showOptOutOfCustomApproval,
  };
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});

  const { successToast } = useToast();
  const { showSupport } = useSupport();

  const possibleDiligenceReviewers = managedDiligenceEnabled
    ? members?.filter((member) => member.canReviewManagedDiligence)
    : members;

  const cleanup = () => {
    setForm(initialForm);
    setErrors({});
  };

  const handleClose = () => {
    if (isEmpty(form) || objectEquals(form, initialForm)) {
      onClose();
      return;
    }
    confirm({
      title: 'Discard unsaved changes?',
      description: 'Your changes have not been saved.',
      confirmationText: 'Discard',
      destructive: true,
      size: 'xs',
    })
      .then(() => {
        onClose();
      })
      .catch(() => {});
  };

  function handleSubmit() {
    const jurisdictionDisplayName = JURISDICTIONS.find(
      (j) => j.id === form.diligenceJurisdiction,
    )?.name;
    if (!jurisdictionDisplayName) {
      setErrors({
        diligenceJurisdiction: 'This field is required.',
      });
      return;
    }
    const name = `${jurisdictionDisplayName} Diligence`;
    const submitForm = {
      ...form,
      name,
      diligenceEnabled: true,
      omitClosingDate: true,
    };

    setLoading(true);
    api
      .createClosing({ fundId, ...submitForm })
      .then((response) => {
        successToast(`Added jurisdiction: ${submitForm.name}`);
        history.push(urls.closingUrl({ fundId, closingId: response.data.id }));
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function renderContent() {
    return (
      <>
        {errors.diligenceEnabled ? (
          <Alert
            severity="error"
            action={
              <Button
                variant="text"
                external
                onClick={showSupport}
                size="small"
              >
                Contact support
              </Button>
            }
          >
            Diligence closings are not enabled for this fund.
          </Alert>
        ) : null}
        {errors.nonFieldErrors ? (
          <Alert severity="error">{errors.nonFieldErrors}</Alert>
        ) : null}

        <JurisdictionSelect
          jurisdiction={form.diligenceJurisdiction}
          jurisdictionError={errors.diligenceJurisdiction}
          onChange={(e) => {
            setForm((f) => ({
              ...f,
              diligenceJurisdiction: e.target.value,
            }));
          }}
        />
        <GPSelectionInput
          label="Diligence reviewer"
          errorMsg={errors.defaultDiligenceApproverId}
          value={form.defaultDiligenceApproverId}
          setValue={(id) => {
            setForm((f) => ({
              ...f,
              defaultDiligenceApproverId: id,
            }));
          }}
          roleLabelNum={constants.DEFAULT_DILIGENCE_APPROVER_LABEL_NUM}
          members={possibleDiligenceReviewers}
          fundId={fundId}
          includeBlankOption
          required
        />
        {showOptOutOfCustomApproval ? (
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.optOutOfCustomApproval}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      optOutOfCustomApproval: e.target.checked,
                    }));
                  }}
                />
              }
              label="Opt out of custom workflows"
            />
            <FormHelperText>
              If checked, investors in this jurisdiction will not receive custom
              approval workflows.
            </FormHelperText>
          </FormControl>
        ) : null}
      </>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      headerLabel="Add jurisdiction"
      size="sm"
      primaryButtonText="Add"
      onSubmit={handleSubmit}
      showCancelButton
      primaryButtonLoading={loading}
      primaryButtonProps={{
        htmlProps: {
          'data-test': 'create',
        },
      }}
      onExited={cleanup}
    >
      {isLoadingMembers ? <Spinner fullScreen /> : renderContent()}
    </Modal>
  );
}
