import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons, InternalChip } from '@passthrough/uikit';
import { useFundEdit } from 'services/providers/fund';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import DatabaseExportIcon from 'mdi-material-ui/DatabaseExportOutline';
import { useMe } from 'services/providers/me';
import { ClosingCard } from './closing_card';
import { ClosingsExport } from './modals/closings_export';
import { CLOSING_TYPES } from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  list: {
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  },
  addJurisdictionLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export function ClosingList({
  closings,
  openNewDialog,
  currency,
  closingType = CLOSING_TYPES.closings,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const [fundEdit] = useFundEdit();
  const [exportModalOpen, setExportModalOpen] = useState(false);

  return (
    <div className={classes.container}>
      {fundEdit ? (
        <div className={classes.actionButtons}>
          {closingType === CLOSING_TYPES.closings ? (
            <>
              <Button
                variant="secondary"
                startIcon={<DatabaseExportIcon />}
                onClick={() => {
                  setExportModalOpen(true);
                }}
              >
                Export
              </Button>
              <Button
                onClick={openNewDialog}
                variant="primary"
                startIcon={<Icons.Add />}
              >
                Create closing
              </Button>
            </>
          ) : null}
          {closingType === CLOSING_TYPES.diligence && me.isStaff ? (
            <Button
              onClick={openNewDialog}
              variant="primary"
              startIcon={<Icons.Add />}
            >
              <span className={classes.addJurisdictionLabel}>
                Add jurisdiction
                <InternalChip />
              </span>
            </Button>
          ) : null}
        </div>
      ) : null}
      <Paper
        component={List}
        elevation={2}
        disablePadding
        className={classes.list}
        aria-label="Closing list"
      >
        {closings.map((closing) => (
          <ClosingCard key={closing.id} closing={closing} currency={currency} />
        ))}
      </Paper>
      <ClosingsExport
        open={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        closings={closings}
      />
    </div>
  );
}
