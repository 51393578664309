import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Prompt } from 'react-router-dom';
import { Alert } from 'components/alert';

import { useSignContext } from 'services/providers/sign';
import { useConfirm } from '@passthrough/uikit';
import { Button } from 'components/button';
import FountainPenTipIcon from 'mdi-material-ui/FountainPenTip';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { QesInstructions } from './qes_instructions';
import { DocumentList } from './document_list';
import { RequestReviewButton } from './request_review_button';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  completionBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  completeBtn: {
    marginTop: theme.spacing(2),
  },
  icon: {
    fontSize: theme.typography.pxToRem(50),
    alignSelf: 'center',
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(10),
  },
  alertError: {
    marginTop: theme.spacing(3),
  },
  requestReviewButton: {
    marginTop: theme.spacing(2),
  },
}));

function AltFirstSignerDisplay({
  needsRequestReview,
  offlineUploadsComponent,
  errorMessage,
  onRequestReview,
  isLoading,
  onComplete,
  allSigned,
  allSignedOffline,
  showRequestReview,
  isOfflineSigningEnabled,
}) {
  const classes = useStyles();

  const [
    exposeAltFirstSignerOfflineUploads,
    setExposeAltFirstSignerOfflineUploads,
  ] = useState(allSignedOffline);

  return (
    <div className={classes.container}>
      <FountainPenTipIcon className={classes.icon} />

      <Typography variant="h5" align="center" gutterBottom>
        You're almost done!
      </Typography>

      <NextStepsInstructions
        needsRequestReview={needsRequestReview}
        noSignatures
        inAuthorizedSignerFlow={!exposeAltFirstSignerOfflineUploads}
      />

      {errorMessage ? (
        <Alert severity="error" className={classes.alertError}>
          {errorMessage}
        </Alert>
      ) : null}

      {needsRequestReview ? (
        <RequestReviewButton
          className={classes.requestReviewButton}
          onRequestReview={onRequestReview}
          isLoading={isLoading}
        />
      ) : (
        <>
          {exposeAltFirstSignerOfflineUploads ? offlineUploadsComponent : null}

          <div className={classes.completionBtnContainer}>
            {exposeAltFirstSignerOfflineUploads ? null : (
              <Button
                data-test="next-req"
                variant="contained"
                color="primary"
                size="xl"
                className={classes.completeBtn}
                onClick={onComplete}
                loading={isLoading}
              >
                Confirm & submit
              </Button>
            )}

            {!exposeAltFirstSignerOfflineUploads && isOfflineSigningEnabled ? (
              <Button
                variant="text"
                size="medium"
                disabled={isLoading}
                onClick={() => {
                  setExposeAltFirstSignerOfflineUploads(true);
                }}
              >
                Upload signed copy instead
              </Button>
            ) : null}

            {!allSigned && exposeAltFirstSignerOfflineUploads ? (
              <Button
                variant="text"
                size="medium"
                disabled={isLoading}
                onClick={() => {
                  setExposeAltFirstSignerOfflineUploads(false);
                }}
              >
                Send for signature instead
              </Button>
            ) : null}

            {showRequestReview ? (
              <Button
                variant="text"
                size="medium"
                onClick={onRequestReview}
                loading={isLoading}
              >
                Request a review before signing
              </Button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

function NextStepsInstructions({
  needsRequestReview,
  noSignatures,
  inAuthorizedSignerFlow = false,
}) {
  if (needsRequestReview) {
    return (
      <>
        <Typography variant="h6" align="center">
          You’ve completed all the required fields. The next step is to submit
          for legal review.{' '}
        </Typography>
        <Typography variant="h6" align="center">
          After review you'll be notified when the documents are ready{' '}
          {noSignatures ? '' : ' for signature'}.
        </Typography>
      </>
    );
  }

  if (inAuthorizedSignerFlow) {
    return (
      <Typography variant="h6" align="center">
        Next, confirm and submit the questionnaire. We'll notify the authorized
        signer to sign the documents.
      </Typography>
    );
  }

  return (
    <Typography variant="h6" align="center">
      You’ve completed all the required fields. Next,{' '}
      {noSignatures
        ? 'confirm and submit the questionnaire'
        : 'confirm and sign the documents'}
      .
    </Typography>
  );
}

export function SignQuestion({
  lpDoc,
  lpDiligence,
  onAnswer,
  nav,
  enableLpRequestedReview,
  setHasLpRequestedReview,
  requireLpRequestReview,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [loadingId, setLoadingId] = useState(null);
  const [signingIds, setSigningIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const {
    lpClosingComplete,
    getLpDoc,
    signaturePage,
    lpClosingRequestReview,
    uploadSingleFile,
    onQuestionnaireFinish,
  } = useQuestionnaire();
  const { openSigningDialog } = useSignContext();
  const { lpClosingId } = nav.getCurrentSection();
  const question = nav.getCurrentQuestion();
  const { steps } = nav;
  const currentClosing = lpClosingId === lpDoc.id ? lpDoc : lpDiligence;

  const docsThatNeedSignatures = currentClosing.docs.filter(
    (doc) => doc.requireSignature,
  );
  const noSignatures = docsThatNeedSignatures.length === 0;

  const readyToSign = nav.readyToSign();
  const allSigned = docsThatNeedSignatures.every((doc) => doc.signed);
  const allFullySigned = allSigned && signingIds.length === 0;
  const allSignedOffline =
    docsThatNeedSignatures.length > 0 &&
    docsThatNeedSignatures.every((doc) => doc.signed && doc.file?.fileId);

  const enableRequestReview = enableLpRequestedReview && !allSigned;

  const showRequestReview =
    enableRequestReview && !currentClosing.isDocusignQesEnabled;
  const needsRequestReview =
    !noSignatures &&
    !currentClosing.hasLpRequestedReview &&
    requireLpRequestReview;

  function unsavedChangesAlert() {
    // If you're on the signature page, we don't want you to leave
    // until you've signed.
    if (readyToSign) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }

  useEffect(unsavedChangesAlert, [readyToSign]);

  function onRequestReview() {
    confirm({
      title: 'Request review?',
      description:
        `This will notify ${currentClosing.fundName} to review your submission. ` +
        "You won't be able to make further edits until their review is complete.",
    })
      .then(() => {
        setIsLoading(true);
        lpClosingRequestReview(lpClosingId)
          .then(() => {
            setHasLpRequestedReview(true);
            onAnswer({ isCompleted: true });
            setErrorMessage('');
            onQuestionnaireFinish({ action: 'SUBMITTED' });
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response?.status === 400) {
              setErrorMessage(error.response.data.detail);
            }
            setIsLoading(false);
          });
      })
      .catch(() => {});
  }

  function onSigned(doc) {
    // set the current one to true
    const newDocs = currentClosing.docs.map((d) => (d.id === doc.id ? doc : d));
    onAnswer({ docs: newDocs });

    const newAllSigned = newDocs
      .filter((d) => d.requireSignature)
      .every((d) => d.signed);
    return newAllSigned;
  }

  function onComplete() {
    setIsLoading(true);

    lpClosingComplete(lpClosingId)
      .then(() => {
        onAnswer({ isCompleted: true });

        setErrorMessage('');

        let actionType = 'SUBMITTED';
        if (currentClosing.canSign && !noSignatures) {
          actionType = 'SIGNED';
        }

        onQuestionnaireFinish({ action: actionType });
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrorMessage(error.response.data.detail);
        }
        setIsLoading(false);
      });
  }

  function onClose() {
    setLoadingId(null);
  }

  function pollLpDoc(lpDocId, shouldComplete) {
    getLpDoc(lpClosingId, lpDocId).then((response) => {
      if (response.data.signed) {
        setSigningIds((ids) => ids.filter((id) => id !== lpDocId));

        if (shouldComplete) {
          // To avoid people signing and not submitting their documents,
          // we auto submit when they've signed the last document electronically.
          onAnswer({
            isCompleted: true,
          });

          onQuestionnaireFinish({ action: 'SIGNED' });
        }
      } else {
        setTimeout(() => {
          pollLpDoc(lpDocId, shouldComplete);
        }, 2000); // every 2 seconds
      }
    });
  }

  function onSignedElectronically(doc) {
    setSigningIds((ids) => [...ids, doc.id]);
    const newAllSigned = onSigned(doc);
    pollLpDoc(doc.id, newAllSigned);
  }

  function getSignaturePage(doc) {
    setLoadingId(doc.id);

    signaturePage(lpClosingId, doc.id)
      .then((response) =>
        openSigningDialog({
          docId: doc.id,
          url: response.data.iframe,
          onSign: () => {
            onSignedElectronically({ ...doc, signed: true });
          },
          onClose: () => {
            onClose();
          },
          isDocusignQesEnabled: currentClosing.isDocusignQesEnabled,
        }),
      )
      .catch((e) => {
        if (e?.response?.status === 409) {
          setErrorMessage(e.response.data.detail);
          setLoadingId(null);
        }
      });
  }

  const isNotConfirmed = !steps
    .filter((step, index) => index < nav.getSubdocConfirmationIndex())
    .every((step) => step.isConfirmed);
  let notReadyToSignText = 'There is still some missing information';
  if (isNotConfirmed) {
    notReadyToSignText = 'There are still sections to be confirmed';
  }

  if (!readyToSign) {
    return (
      <div className={classes.container}>
        <FountainPenTipIcon className={classes.icon} />
        <Typography variant="h5" align="center" gutterBottom>
          You're almost done!
        </Typography>
        <Typography variant="h6" align="center">
          {notReadyToSignText}
        </Typography>
        <Button
          data-test="next-req"
          variant="contained"
          color="primary"
          size="xl"
          className={classes.buttons}
          onClick={() => {
            nav.toNextRequired();
          }}
        >
          Next required
        </Button>
      </div>
    );
  }

  if (!currentClosing.canSign) {
    return (
      <AltFirstSignerDisplay
        needsRequestReview={needsRequestReview}
        errorMessage={errorMessage}
        onRequestReview={onRequestReview}
        isLoading={isLoading}
        onComplete={onComplete}
        allSigned={allSigned}
        allSignedOffline={allSignedOffline}
        showRequestReview={showRequestReview}
        isOfflineSigningEnabled={currentClosing.isOfflineSigningEnabled}
        offlineUploadsComponent={
          <DocumentList
            lpClosingId={lpClosingId}
            docs={docsThatNeedSignatures}
            loadingId={loadingId}
            signingIds={signingIds}
            getSignaturePage={getSignaturePage}
            allFullySigned={allFullySigned}
            allSignedOffline={allSignedOffline}
            isLoading={isLoading}
            isOfflineSigningEnabled={currentClosing.isOfflineSigningEnabled}
            forceOfflineSigning
            onComplete={onComplete}
            onSigned={onSigned}
            uploadSingleFile={uploadSingleFile}
            isCompleted={question.isCompleted}
          />
        }
      />
    );
  }

  return (
    <div className={classes.container}>
      <Prompt message="You have not signed and submitted all of your documents. Are you sure you want to leave?" />
      <FountainPenTipIcon className={classes.icon} />
      <Typography variant="h5" align="center" gutterBottom>
        You're almost done!
      </Typography>
      {currentClosing.isDocusignQesEnabled ? (
        <QesInstructions
          fundName={currentClosing.fundName}
          onRequestReview={onRequestReview}
          enableRequestReview={enableRequestReview}
        />
      ) : (
        <NextStepsInstructions
          needsRequestReview={needsRequestReview}
          noSignatures={noSignatures}
        />
      )}
      {errorMessage ? (
        <Alert severity="error" className={classes.alertError}>
          {errorMessage}
        </Alert>
      ) : null}
      <DocumentList
        lpClosingId={lpClosingId}
        docs={docsThatNeedSignatures}
        loadingId={loadingId}
        signingIds={signingIds}
        getSignaturePage={getSignaturePage}
        allFullySigned={allFullySigned}
        isLoading={isLoading}
        isOfflineSigningEnabled={currentClosing.isOfflineSigningEnabled}
        forceOfflineSigning={nav?.forceOfflineSigning}
        showRequestReview={showRequestReview}
        onRequestReview={onRequestReview}
        onComplete={onComplete}
        onSigned={onSigned}
        uploadSingleFile={uploadSingleFile}
        needsRequestReview={needsRequestReview}
        isCompleted={question.isCompleted}
        allSignedOffline={allSignedOffline}
      />
    </div>
  );
}
