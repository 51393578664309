import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, Icons } from '@passthrough/uikit';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { Button } from 'components/button';
import { LPFileUpload, getEmptyFile } from 'components/file_upload';
import { SignedText } from './signed_text';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  docName: {
    fontWeight: 500,
  },
  printSpacer: {
    flexGrow: 1,
  },
  uploadedContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

const TRUNCATE_CHARS = 40;

function openFile(url) {
  window.open(url, '_blank');
}

// Truncate a long filename with ellipses, but leave the extension
function truncate(filename, length) {
  if (!filename) {
    return undefined;
  }

  if (filename.length <= length) {
    return filename;
  }

  const extension = filename.split('.').pop();
  const truncated = filename.slice(0, length - extension.length - 1);
  return `${truncated}….${extension}`;
}

export function OnlineRow({ doc, buttonText, loading, disabled, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <Typography className={classes.docName}>{doc.name}</Typography>
      {doc.signed ? (
        <SignedText text={buttonText} />
      ) : (
        <Button
          data-test="sign"
          color="primary"
          variant="contained"
          loading={loading}
          disabled={disabled}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export function PrintRow({
  lpClosingId,
  doc,
  onSigned,
  buttonText,
  uploadSingleFile,
}) {
  const classes = useStyles();

  const initialFile = doc.file || getEmptyFile();
  const [file, setFile] = useState(initialFile);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { getPdf, lpClosingUploadSignedDoc, maxFileSize } = useQuestionnaire();

  const menuAnchorRef = useRef(null);
  const menuAnchorEl = menuAnchorRef.current;
  const menuId = `menu-${doc.id}`;

  useEffect(() => {
    setFile(initialFile);
  }, [initialFile.fileId]);

  useEffect(() => {
    // Update loading state after the lp is re-fetched
    setLoading(false);
  }, [doc.file?.fileId]);

  function handleOpenOriginal() {
    setIsDownloading(true);

    getPdf(doc).then((res) => {
      const { url, filledUrl } = res.data;
      const filledUrlForSigning = `${filledUrl}?include_all=false`;
      const urlToOpen = doc.fillable ? filledUrlForSigning : url;
      setIsDownloading(false);
      openFile(urlToOpen);
    });
  }

  function handleOpenSignedCopy() {
    openFile(file.fileUrl);
  }

  function handleFileChange(newFile) {
    setErrorMsg('');
    setFile(newFile);
    setLoading(true);

    lpClosingUploadSignedDoc(doc.id, lpClosingId, newFile.fileId)
      .then(() => {
        // We're not updating the loading state here because
        // we want to wait for the lp to be re-fetched to avoid
        // a flash of the "Signed electronically" text when deleting
        if (newFile.fileId) {
          onSigned({ ...doc, signed: true, file: newFile });
        } else {
          onSigned({ ...doc, signed: false, file: null });
        }
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          const { fileId } = error.response.data;
          setErrorMsg(
            `Failed to upload file with id ${fileId}. ` +
              'Contact support at support@passthrough.com',
          );
        }
        setLoading(false);
      });
  }

  function handleDelete() {
    handleFileChange(getEmptyFile());
  }

  const signedElectronically = !file.fileId && doc.signed;
  const showAfterUpload = (
    <div className={classes.uploadedContainer}>
      <SignedText text="Uploaded" />
      <IconButton
        ref={menuAnchorRef}
        aria-describedby={menuId}
        onClick={(event) => {
          event.stopPropagation();
          setMenuOpen((prevMenuOpen) => !prevMenuOpen);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={menuId}
        open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuOpen(false)}
        align="end"
      >
        <MenuItem
          onClick={handleOpenOriginal}
          text="Open unsigned copy"
          icon={<Icons.OpenInNewOutlined color="primary" />}
        />
        <MenuItem
          onClick={handleOpenSignedCopy}
          text="Open signed copy"
          subtext={truncate(file.fileName, TRUNCATE_CHARS)}
          icon={<Icons.OpenInNewOutlined color="primary" />}
        />
        <MenuItem
          onClick={handleDelete}
          text="Remove signed copy"
          subtext={truncate(file.fileName, TRUNCATE_CHARS)}
          icon={<Icons.DeleteOutlined color="primary" />}
        />
      </Menu>
    </div>
  );

  return (
    <div className={classes.row}>
      <Typography className={classes.docName}>{doc.name}</Typography>
      <span className={classes.printSpacer} />
      {signedElectronically || file.fileId ? null : (
        <Button
          variant="text"
          data-test="sign"
          onClick={() => {
            handleOpenOriginal(doc);
          }}
          loading={isDownloading}
        >
          Download
        </Button>
      )}

      {!loading && signedElectronically ? (
        <SignedText text="Signed electronically" />
      ) : (
        <div className={file.fileId ? undefined : classes.rowMargin}>
          <LPFileUpload
            file={file}
            onChange={handleFileChange}
            text={buttonText}
            variant="contained"
            loading={loading}
            showAfterUpload={showAfterUpload}
            uploadSingleFile={uploadSingleFile}
            accept={doc.isSubdoc ? 'application/pdf' : undefined}
            maxFileSize={maxFileSize}
            useBoldLabel
          />
        </div>
      )}
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
    </div>
  );
}
