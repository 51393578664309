import React from 'react';
import { useUploadFiles } from 'services/file/hooks';

import { getEmptyFile } from 'components/file_upload';
import { FileUpload } from '@passthrough/uikit';

export const DndSingleFileUpload = ({
  file,
  onChange,
  url,
  acceptedFileTypes = '',
  helperText = '',
  ...extraInputProps
}) => {
  const uploadFiles = useUploadFiles(url);

  function onDelete() {
    onChange(getEmptyFile());
  }

  return (
    <FileUpload
      files={file}
      onAccept={onChange}
      onDelete={onDelete}
      uploadFiles={uploadFiles}
      extraInputProps={extraInputProps}
      helperText={helperText}
      acceptedFileTypes={acceptedFileTypes}
    />
  );
};
