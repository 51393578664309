import React, { useState, useEffect } from 'react';
import { CurrencyTextField } from 'components/currency_text_field/index';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function DollarsQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  frontendLabel,
  QuestionPromptComponent,
}) {
  const initialDollars = answer || '';
  const [dollars, setDollars] = useState(initialDollars);
  const [errorMsg, setErrorMsg] = useState('');
  const { useCurrency } = useQuestionnaire();
  const { symbol, code } = useCurrency();
  const hasInput = Boolean(dollars);

  const currencyDisplay = `${code} ${symbol}`;

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: dollars,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(e, v) {
    // autocomplete will modify the target directly, bypassing v.
    // so we parse the value of the target as a backup.
    const targetValue = parseFloat(e.target.value.replace(/,/g, ''));
    const value = v || targetValue;
    setSaving(UNSAVED);
    setDollars(value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setDollars(initialDollars);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <CurrencyTextField
          id="dollars"
          currencySymbol={currencyDisplay}
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={dollars}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={Number.isNaN(dollars) || dollars === ''}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
