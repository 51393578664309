import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useToast } from 'services/toast';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as api from 'services/api';

import { Typography, Modal } from '@passthrough/uikit';

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    margin: theme.spacing(1),
  },
  emailPreferenceCheckboxes: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
}));

export function UpdateMemberDialog({
  memberId,
  name,
  level: currentLevel,
  diligenceEnabled,
  fundId,
  open,
  onClose,
  onChange,
  groupId: currentGroupId,
  groupChoices,
  currEnableTransactionEmailsSetting,
  currEnableDigestEmailsSetting,
}) {
  const classes = useStyles();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [level, setLevel] = useState(currentLevel || '');
  const [groupId, setGroupId] = useState(currentGroupId || '');

  const [enableTransactionalEmails, setEnableTransactionalEmails] = useState(
    currEnableTransactionEmailsSetting || false,
  );
  const [enableDigestEmails, setEnableDigestEmails] = useState(
    currEnableDigestEmailsSetting || false,
  );

  const changed =
    level !== currentLevel ||
    groupId !== currentGroupId ||
    enableDigestEmails !== currEnableDigestEmailsSetting ||
    enableTransactionalEmails !== currEnableTransactionEmailsSetting;
  const { toast } = useToast();

  function resetLocalData() {
    setErrors({});
    setLevel(currentLevel);
    setGroupId(currentGroupId);
    setEnableTransactionalEmails(currEnableTransactionEmailsSetting);
    setEnableDigestEmails(currEnableDigestEmailsSetting);
  }

  const hasCustomApproval = groupChoices.length > 0;

  function handleSubmit() {
    setLoading(true);
    api
      .updateMember({
        memberId,
        level,
        fundId,
        groupId: hasCustomApproval ? groupId : null,
        transactionEmailsEnabled: enableTransactionalEmails,
        digestEmailsEnabled: enableDigestEmails,
      })
      .then(() => {
        onChange();
        onClose();

        toast(`Updated ${name}'s access.`);

        setErrors({});
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setErrors(error.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Modal
      headerLabel={`Update ${name}'s user settings`}
      open={open}
      onClose={onClose}
      onEntering={resetLocalData}
      size="xs"
      primaryButtonText="Update"
      primaryButtonLoading={loading}
      primaryButtonDisabled={!changed}
      onSubmit={handleSubmit}
      showCancelButton
    >
      {hasCustomApproval ? (
        <FormControl variant="outlined" fullWidth error={!!errors.group}>
          <InputLabel id="group-label">Group</InputLabel>
          <Select
            label="Group"
            labelId="group-label"
            name="groupId"
            value={groupId}
            onChange={(e) => setGroupId(e.target.value)}
          >
            <MenuItem key="none" value="" aria-label="None">
              None
            </MenuItem>
            {groupChoices.map((groupChoice) => (
              <MenuItem key={groupChoice.id} value={groupChoice.id}>
                {groupChoice.name}
              </MenuItem>
            ))}
          </Select>
          {errors.group ? (
            <FormHelperText>{errors.group}</FormHelperText>
          ) : null}
        </FormControl>
      ) : null}
      <FormControl
        required
        error={!!errors.level}
        component="fieldset"
        fullWidth
      >
        <FormLabel component="legend">Access level</FormLabel>
        <RadioGroup
          aria-label="access"
          name="level"
          value={level}
          onChange={(e) => setLevel(e.target.value)}
        >
          <FormControlLabel
            value="EDIT"
            control={<Radio />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body">Edit</Typography>
                <Typography variant="label">
                  Full permissions to the fund and managing access.
                </Typography>
              </div>
            }
          />
          {hasCustomApproval && (
            <FormControlLabel
              value="REVIEW"
              control={<Radio />}
              label={
                <div className={classes.radioLabel}>
                  <Typography variant="body">Review</Typography>
                  <Typography variant="label">
                    Ability to view all fund data and take assigned actions.
                  </Typography>
                </div>
              }
            />
          )}
          <FormControlLabel
            value="VIEW"
            control={<Radio />}
            label={
              <div className={classes.radioLabel}>
                <Typography variant="body">View only</Typography>
                <Typography variant="label">
                  Ability to view all fund data.
                </Typography>
              </div>
            }
          />
        </RadioGroup>
        {errors.level ? <FormHelperText>{errors.level}</FormHelperText> : null}
      </FormControl>

      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Email preferences</FormLabel>

        <div className={classes.emailPreferenceCheckboxes}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enableTransactionalEmails}
                onChange={(e) => {
                  setEnableTransactionalEmails(e.target.checked);
                }}
              />
            }
            label={
              <div>
                <Typography variant="body">Investor activity</Typography>
                <Typography variant="label">
                  Updates on investor status and comments
                </Typography>
              </div>
            }
          />

          {diligenceEnabled ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableDigestEmails}
                  onChange={(e) => {
                    setEnableDigestEmails(e.target.checked);
                  }}
                />
              }
              label={
                <div>
                  <Typography variant="body">Daily diligence report</Typography>
                  <Typography variant="label">
                    A daily report about investor risk rating updates
                  </Typography>
                </div>
              }
            />
          ) : null}
        </div>
      </FormControl>
    </Modal>
  );
}
