import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { containsLpName, cleanLPName } from 'services/utils';
import { StaffReviewFile } from 'components/staff_review_file';
import * as api from 'services/api';
import { FormHelperText } from '@material-ui/core';
import { InternalChip } from '@passthrough/uikit';

export function MultiPDFNameChecker({
  files: currentFiles,
  investorName,
  investorLegalName,
  setFiles,
  filesToCheck,
  setFilesToCheck,
  isStaff,
  entityType = 'lp',
}) {
  const { fundId } = useParams();
  const [messages, setMessages] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [manualReviewFiles, setManualReviewFiles] = useState([]);
  const [enableStaffReview, setEnableStaffReview] = useState(false);

  function formatNamesForMessage() {
    if (investorLegalName) {
      return (
        `the ${entityType} name "${cleanLPName(investorName)}"` +
        ` or the ${entityType}'s legal name "${cleanLPName(investorLegalName)}"`
      );
    }
    return `the ${entityType} name "${cleanLPName(investorName)}"`;
  }

  function alternateChecks(fileToCheck) {
    if (
      !containsLpName(fileToCheck.fileName, investorName) &&
      !containsLpName(fileToCheck.fileName, investorLegalName)
    ) {
      setFileErrors((errors) => [
        ...errors,
        {
          fileId: fileToCheck.fileId,
          message:
            `You uploaded "${fileToCheck.fileName}", which doesn't appear` +
            ` to include ${formatNamesForMessage()}` +
            ' in the file contents. To force the upload, the file name' +
            ` must include ${formatNamesForMessage()}.`,
          isStaff: true,
        },
      ]);
    } else {
      // upload file if it's not a PDF
      if (!fileToCheck.fileName.endsWith('.pdf')) {
        setFiles((files) => [...files, fileToCheck]);
        return;
      }
      setManualReviewFiles((files) => [...files, fileToCheck]);
    }
  }

  async function staffFileUploadCheck(fileToCheck) {
    // Check the file contents for either the lp name or the legal name.
    // If not found, ensure that the file name contains the lp name
    // or legal name, and do the manual file review.
    if (!fileToCheck?.fileId) {
      return;
    }

    try {
      const response = await api.fileUploadNameCheck({
        fundId,
        fileId: fileToCheck.fileId,
        lpName: investorName,
        legalName: investorLegalName,
      });
      if (response.data.passedNameCheck) {
        // Found the investor name in the file contents, so we don't
        // need to do the other checks.
        setFiles((files) => [...files, fileToCheck]);
        setFileErrors((errors) =>
          errors.filter((error) => error.fileId !== fileToCheck.fileId),
        );
        setMessages((msgs) => [
          ...msgs,
          {
            fileId: fileToCheck.fileId,
            message: `Found ${formatNamesForMessage()} in ${
              fileToCheck.fileName
            } contents. Skipping manual review.`,
          },
        ]);
      } else {
        alternateChecks(fileToCheck);
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // The request timed out, do the alternate checks instead.
        alternateChecks(fileToCheck);
      }
      if (error.response?.status === 400) {
        setFileErrors((errors) => [
          ...errors,
          {
            message:
              `The file "${fileToCheck.fileName}" couldn't be read.` +
              ' It may be encrypted or corrupt.',
            isStaff: true,
          },
        ]);
      }
    }
  }

  useEffect(() => {
    if (!filesToCheck.length) {
      return;
    }
    setMessages([
      {
        fileId: 'all',
        message: `Checking file contents for ${formatNamesForMessage()}...`,
      },
    ]);
    setFileErrors([]);
    Promise.allSettled(
      filesToCheck.map((fileToCheck) => staffFileUploadCheck(fileToCheck)),
    ).then(() => {
      setMessages((msgs) => msgs.filter((msg) => msg.fileId !== 'all'));
      setEnableStaffReview(true);
    });
    setFilesToCheck([]);
  });

  useEffect(() => {
    if (enableStaffReview && !manualReviewFiles.length) {
      setEnableStaffReview(false);
    }
  });

  useEffect(() => {
    setMessages((msgs) =>
      msgs.filter(
        (msg) =>
          msg.fileId === 'all' ||
          currentFiles.some((f) => f.fileId === msg.fileId),
      ),
    );
  }, [currentFiles]);

  if (!(isStaff && investorName)) {
    return null;
  }

  return (
    <>
      {messages.map((msg) => (
        <FormHelperText key={`message-${msg.fileId}`} error={false}>
          <InternalChip /> {msg.message}
        </FormHelperText>
      ))}
      {fileErrors.map((error) => (
        <FormHelperText key={`error-${error.fileId}`} error>
          <InternalChip /> {error.message}
        </FormHelperText>
      ))}
      {manualReviewFiles.length && enableStaffReview ? (
        <StaffReviewFile
          lpName={investorName}
          file={manualReviewFiles[0]} // will show one file at a time
          onAccept={(file) => {
            setFiles((files) => [...files, file]);
            setManualReviewFiles((files) =>
              files.filter((f) => f.fileId !== file.fileId),
            );
          }}
          onReject={() => {
            setManualReviewFiles((files) =>
              files.filter((f) => f.fileId !== manualReviewFiles[0].fileId),
            );
          }}
        />
      ) : null}
    </>
  );
}
