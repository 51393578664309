import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { DndSingleFileUpload } from 'components/dnd_file_upload/single_file_upload';
import { PDFNameChecker } from 'components/document_upload/pdf_name_checker';
import { getEmptyFile } from 'components/file_upload';
import { DatePicker } from 'components/date';
import { Icons, Button } from '@passthrough/uikit';
import { Divider } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { getExpirationDate, genDataTestLabel } from '../utils';

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'normal',
    lineHeight: '1.5rem',
  },
  upload: {
    display: 'block',
    margin: theme.spacing(1, 0),
  },
  dateAndDeleteButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  addDocumentButton: {
    marginTop: theme.spacing(2),
  },
}));

export const expirationDateActions = {
  INITIALIZE: 'INITIALIZE',
  ADD_ROW: 'ADD_ROW',
  DELETE_ROW: 'DELETE_ROW',
  FILE_CHANGE: 'FILE_CHANGE',
  DATE_CHANGE: 'DATE_CHANGE',
  SET_ERRORS: 'SET_ERRORS',
};

export function expirationDatesReducer(state, action) {
  switch (action.type) {
    case expirationDateActions.INITIALIZE: {
      return Object.entries(action.documents).reduce(
        (acc, [docType, files]) => {
          if (Array.isArray(files)) {
            acc[docType] = files
              .map((file) => ({
                ...getExpirationDate(file, action.initialExpirationDates),
                tempId: uuidv4(),
              }))
              .sort(
                (a, b) =>
                  new Date(a.expirationDate) - new Date(b.expirationDate),
              );
          } else {
            acc[docType] = [
              getExpirationDate(files, action.initialExpirationDates),
            ];
          }
          return acc;
        },
        {},
      );
    }
    case expirationDateActions.ADD_ROW: {
      return {
        ...state,
        [action.key]: [
          ...state[action.key],
          {
            file: null,
            expirationDate: null,
            fileError: null,
            dateError: null,
            tempId: uuidv4(),
          },
        ],
      };
    }
    case expirationDateActions.DELETE_ROW: {
      return {
        ...state,
        [action.key]: state[action.key].filter(
          (_, index) => index !== action.index,
        ),
      };
    }
    case expirationDateActions.FILE_CHANGE: {
      return {
        ...state,
        [action.key]: state[action.key].map((date, index) =>
          index === action.index
            ? { ...date, file: action.newFile.fileId, expirationDate: null }
            : date,
        ),
      };
    }
    case expirationDateActions.DATE_CHANGE: {
      return {
        ...state,
        [action.key]: state[action.key].map((date, index) =>
          index === action.index
            ? { ...date, expirationDate: action.newDate }
            : date,
        ),
      };
    }

    case expirationDateActions.SET_ERRORS: {
      return action.expirationDateErrors;
    }

    default:
      throw new Error('Invalid expiration date action type');
  }
}

function SingleFileWithDate({
  file,
  expirationDate,
  fileError,
  nameCheckError,
  setNameCheckError,
  dateError,
  onChangeDate,
  onChangeFile,
  deleteRow,
  investorName,
  showDivider,
  isStaff = false,
  fileUploadUrl,
  fileDataTestLabel,
  enableNameChecks = false,
  showDeleteButton,
  ...extraInputProps
}) {
  const classes = useStyles();
  const [fileToCheck, setFileToCheck] = React.useState(getEmptyFile());

  const handleChange = (newFile) => {
    if (enableNameChecks && isStaff && newFile?.fileName) {
      setFileToCheck((oldFile) => ({ ...oldFile, ...newFile }));
    } else {
      onChangeFile({ ...file, ...newFile });
    }
    setNameCheckError(null);
  };

  return (
    <>
      <DndSingleFileUpload
        file={file}
        onChange={handleChange}
        url={fileUploadUrl}
        {...extraInputProps}
        data-test={`diligence_v2_${fileDataTestLabel}_file_input`}
      />
      {fileError ? <FormHelperText error>{fileError}</FormHelperText> : null}
      {nameCheckError?.message ? (
        <FormHelperText>{nameCheckError.message}</FormHelperText>
      ) : null}

      {isStaff && enableNameChecks ? (
        <PDFNameChecker
          investorName={investorName}
          investorLegalName={null}
          file={file}
          setFile={(newFile) => {
            onChangeFile({ ...file, ...newFile });
          }}
          fileToCheck={fileToCheck}
          setFileToCheck={setFileToCheck}
          setFileError={setNameCheckError}
          isStaff={isStaff}
          alreadyCheckedPdf={!!file?.fileId}
          entityType="node"
        />
      ) : null}

      <div className={classes.dateAndDeleteButtonContainer}>
        <DatePicker
          label="Expiration date"
          date={expirationDate}
          setDate={onChangeDate}
        />

        {showDeleteButton ? (
          <Button
            aria-label="Delete document"
            variant="icon"
            size="large"
            onClick={deleteRow}
          >
            <Icons.Delete />
          </Button>
        ) : null}
      </div>

      {showDivider && <Divider className={classes.divider} />}
    </>
  );
}

export function DiligenceFileInputWithExpirationDate({
  files,
  fileErrors,
  expirationDates,
  expirationDatesDispatch,
  docType,
  fileUploadUrl,
  onChange,
  formLabel,
  helpText = '',
  investorName,
  allowMultipleFiles = false,
  isStaff = false,
  enableNameChecks = false,
  ...extraInputProps
}) {
  const classes = useStyles();
  const fileDataTestLabel = genDataTestLabel(formLabel);
  const [nameCheckErrors, setNameCheckErrors] = React.useState(
    new Array(files.length).fill(null),
  );

  const handleDateChange = (key, index, newDate) => {
    expirationDatesDispatch({
      type: expirationDateActions.DATE_CHANGE,
      newDate,
      key,
      index,
    });
  };

  const handleFileChange = (key, index, newFile) => {
    const newFiles = [...files];
    newFiles[index] = newFile;
    onChange(allowMultipleFiles ? newFiles : newFiles[0]);
    expirationDatesDispatch({
      type: expirationDateActions.FILE_CHANGE,
      newFile,
      key,
      index,
    });
  };

  const handleAddRow = (key) => {
    onChange([...files, getEmptyFile()]);
    expirationDatesDispatch({
      type: expirationDateActions.ADD_ROW,
      key,
    });
  };

  const handleDeleteRow = (key, index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    onChange(allowMultipleFiles ? newFiles : getEmptyFile());
    expirationDatesDispatch({
      type: expirationDateActions.DELETE_ROW,
      key,
      index,
    });
  };

  return (
    <FormControl
      className={classes.upload}
      fullWidth
      error={!!fileErrors || nameCheckErrors.some((error) => error?.message)}
    >
      <FormLabel className={classes.label} component="legend">
        {formLabel}
      </FormLabel>

      <FormHelperText className={classes.helperText}>{helpText}</FormHelperText>
      {files.map((file, index) => (
        <SingleFileWithDate
          key={expirationDates[docType]?.[index]?.tempId}
          file={file}
          expirationDate={
            expirationDates[docType]?.[index]?.expirationDate || null
          }
          fileError={expirationDates[docType]?.[index]?.fileError}
          dateError={expirationDates[docType]?.[index]?.dateError}
          onChangeDate={(newDate) => handleDateChange(docType, index, newDate)}
          onChangeFile={(newFile) => handleFileChange(docType, index, newFile)}
          deleteRow={() => handleDeleteRow(docType, index)}
          investorName={investorName}
          isStaff={isStaff}
          fileUploadUrl={fileUploadUrl}
          fileDataTestLabel={`${fileDataTestLabel}_${index}`}
          enableNameChecks={enableNameChecks}
          showDivider={index < files.length - 1}
          showDeleteButton={allowMultipleFiles && files.length > 1}
          nameCheckError={nameCheckErrors[index]}
          setNameCheckError={(error) => {
            const newErrors = [...nameCheckErrors];
            newErrors[index] = error;
            setNameCheckErrors(newErrors);
          }}
          {...extraInputProps}
        />
      ))}

      {allowMultipleFiles ? (
        <div className={classes.addDocumentButton}>
          <Button
            variant="secondary"
            label="Add document"
            onClick={() => handleAddRow(docType)}
          >
            <Icons.Add />
            Add document
          </Button>
        </div>
      ) : null}

      {fileErrors ? (
        <FormHelperText className={classes.errorText}>
          {fileErrors}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
