import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';

import { useQuestionnaire } from 'services/providers/questionnaire';
import { Button } from 'components/button';
import { CommentDisplay } from './comment_display';

const useStyles = makeStyles((theme) => ({
  threadDisplay: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  addCommentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  submitCommentButton: {
    marginTop: theme.spacing(2),
    alignSelf: 'end',
  },
  oldComment: {
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function ThreadDisplay({
  mostRecentComment,
  oldComments,
  showAllComments,
  questionId,
  isDiligenceQuestion,
  sendLPComment,
}) {
  const [draftCommentText, setDraftCommentText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();
  const { useSupport, lpCanComment } = useQuestionnaire();
  const { setHasDraftComment } = useSupport();

  useEffect(() => {
    setHasDraftComment(Boolean(draftCommentText));

    if (draftCommentText) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
      setHasDraftComment(false);
    };
  }, [Boolean(draftCommentText)]);

  function submitNote() {
    setIsLoading(true);
    setHasError(false);

    sendLPComment({
      label: questionId,
      lpComment: draftCommentText,
      isDiligence: isDiligenceQuestion,
    })
      .then(() => {
        setDraftCommentText('');
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
  }

  return (
    <Paper variant="outlined" className={classes.threadDisplay}>
      <Collapse in={showAllComments} mountOnEnter unmountOnExit>
        {oldComments.map((comment) => (
          <CommentDisplay
            key={comment.id}
            isLPComment={comment.isLpComment}
            comment={comment.comment}
            timeStr={comment.commentTime}
            userName={comment.user.name}
            fundName={comment.fundName}
            className={classes.oldComment}
          />
        ))}
      </Collapse>

      {mostRecentComment ? (
        <CommentDisplay
          isLPComment={mostRecentComment.isLpComment}
          comment={mostRecentComment.comment}
          timeStr={mostRecentComment.commentTime}
          userName={mostRecentComment.user.name}
          fundName={mostRecentComment.fundName}
        />
      ) : null}

      <Collapse in={showAllComments && lpCanComment} mountOnEnter unmountOnExit>
        <div className={classes.addCommentContainer}>
          <TextField
            multiline
            autoFocus
            minRows={4}
            maxRows={12}
            value={draftCommentText}
            onChange={(e) => {
              setDraftCommentText(e.target.value);
            }}
            variant="outlined"
            fullWidth
            label="Add comment"
          />

          <Button
            variant="contained"
            loading={isLoading}
            disabled={!draftCommentText}
            className={classes.submitCommentButton}
            onClick={() => {
              submitNote();
            }}
          >
            {mostRecentComment ? 'Reply' : 'Comment'}
          </Button>

          {hasError ? (
            <div className={classes.errorText}>
              There was an error submitting your comment.
            </div>
          ) : null}
        </div>
      </Collapse>
    </Paper>
  );
}
