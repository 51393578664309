import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function EmailQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialEmail = answer || '';
  const [email, setEmail] = useState(initialEmail);
  const [errorMsg, setErrorMsg] = useState('');
  const hasInput = Boolean(email);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: email, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setEmail(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setEmail(initialEmail);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="email"
          type="email"
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={email}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!email}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
