/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FileUploadOutline from 'mdi-material-ui/FileUploadOutline';
import { Spinner } from 'components/spinner';
import { Stepper } from 'components/stepper';
import { useFeatureFlags } from 'services/providers/feature_flag';

import { Document, Page } from 'react-pdf';
import { PDF_HEIGHT, PDF_WIDTH } from 'services/pdf_dimensions';
import { Typography, Chip, Button, useConfirm } from '@passthrough/uikit';
import { useToast } from 'services/toast';
import { boxTypes, SIGNATURE_BOX_TYPES, SUBDOC_DOC_NAME } from './constants';
import { UploadDraftFileModal } from './modals/upload_draft_file_modal';
import { ManageFilesModal } from './modals/manage_files_modal';

const useStyles = makeStyles(() => ({
  topDialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogActions: {
    display: 'block',
  },
  stepper: {
    backgroundColor: 'inherit',
  },
  drawCanvas: {
    position: 'absolute',
    top: 0,
  },
  belowCanvas: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CHECKBOX_TYPES = ['output_checkbox'];
const GRID_SIZE = 15;

function roundToNearest(n, nearest) {
  const ret = Math.ceil((n + 1) / nearest) * nearest;
  if (ret === 0) return nearest;
  return ret;
}

const OTHER_BOX_BG = '#bdbdbd22';
const OTHER_BOX_LINE = '#000000';
const CURRENT_BOX_BG = '#2196f322';
const CURRENT_BOX_LINE = '#00308F';
const HIGHLIGHT_BOX_BG = '#2196f322';
const HIGHLIGHT_BOX_LINE = '#e60e0e';
const SELECTED_BOX_BG = '#FF000022';
const SELECTED_BOX_LINE = '#FF0000';
const DRAG_SELECTED_BOX_BG = '#00800022';
const DRAG_SELECTED_BOX_LINE = '#008000';
const MOVED_BOX_BG = '#00400022';
const MOVED_BOX_LINE = '#004000';

const DRAG_THRESHOLD = 5;
const SELECTION_MODE_MAKE = 'MAKE_SELECTION';
const SELECTION_MODE_MOVE = 'MOVE_SELECTION';

class BoxesMovedOutOfBoundsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'OutOfBoundsError';
  }
}

const docusignImg = new Image();
docusignImg.src = window.static?.docusign_signature_png;

export function PdfEditor({
  fileUrl,
  mode,
  dragOffset,
  onDragEnd,
  onTriggerResize,
  onTranslate,
  page,
  setPage,
  selectedBox,
  boxes,
  questions,
  activeQuestion,
  onMouseDownSelectedBox,
  onMouseUpOverBox,
  onDraftFileUploadSuccess,
  documents,
  selectDocument,
  selectedDocument,
  saveBoxPositions,
  setHighlightBoxId,
  hasUnsavedChanges,
  resetForm,
  hasUnsavedBoxPositions,
  setHasUnsavedBoxPositions,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const { errorToast } = useToast();
  const { MULTIPLE_DOCUMENTS } = useFeatureFlags();
  const [pdf, setPdf] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [forceCanvasUpdate, setForceCanvasUpdate] = useState(0);
  const updateCanvas = useCallback(() => {
    setForceCanvasUpdate((f) => f + 1);
  }, [setForceCanvasUpdate]);
  const drawCanvasRef = useRef(null);
  const keyboardAreaRef = useRef(null);
  const activeQuestionRef = useRef(activeQuestion);
  const boxIdToUpdatedPosition = useRef({});
  useEffect(() => {
    activeQuestionRef.current = activeQuestion;
  }, [activeQuestion]);
  const boxesOnPageRef = useRef([]);
  const dragSelectedBoxes = useRef([]);
  useEffect(() => {
    const otherBoxes = MULTIPLE_DOCUMENTS
      ? questions
          .filter((q) => q.id !== activeQuestion?.id)
          .map((q) =>
            (q.hellosignBoxes || []).filter(
              (b) =>
                (b.page === page || !b.page) &&
                (b.documentName || null) === selectedDocument?.name,
            ),
          )
          .flat()
      : questions
          .filter((q) => q.id !== activeQuestion?.id)
          .map((q) =>
            (q.hellosignBoxes || []).filter((b) => b.page === page || !b.page),
          )
          .flat();
    boxesOnPageRef.current = [...boxes, ...otherBoxes].filter(
      (b) => b.page === page,
    );
    // If boxesOnPageRef.current changes e.g. because a box was moved via
    // the form or moving a single box, and selecting multiple boxes, and
    // discarding form changes, we need to update the dragSelectedBoxes.
    const selectedBoxIds = new Set(dragSelectedBoxes.current.map((b) => b.id));
    dragSelectedBoxes.current = boxesOnPageRef.current.filter((b) =>
      selectedBoxIds.has(b.id),
    );
    updateCanvas();
  }, [boxes, questions, page]);
  useEffect(() => {
    if (!hasUnsavedBoxPositions) {
      boxIdToUpdatedPosition.current = {};
      dragSelectedBoxes.current = [];
    }
  }, [hasUnsavedBoxPositions]);
  const selectionMouseDownXY = useRef(null);
  const selectionMode = useRef(null);
  const selectionDragThresholdMet = useRef(false);
  const revertMovedBoxes = useCallback(() => {
    boxIdToUpdatedPosition.current = {};
    dragSelectedBoxes.current = [];
    setHasUnsavedBoxPositions(false);
  }, [setHasUnsavedBoxPositions]);
  const commitMovedBoxes = useCallback(() => {
    const succeeded = saveBoxPositions(boxIdToUpdatedPosition.current);
    if (succeeded) {
      setHasUnsavedBoxPositions(false);
    }
  }, [saveBoxPositions, setHasUnsavedBoxPositions]);

  function hasDragged(x, y) {
    return (
      selectionMode.current === SELECTION_MODE_MAKE &&
      selectionMouseDownXY.current &&
      (Math.abs(x - selectionMouseDownXY.current.x) > DRAG_THRESHOLD ||
        Math.abs(y - selectionMouseDownXY.current.y) > DRAG_THRESHOLD)
    );
  }

  function getBoxPosition(box) {
    if (boxIdToUpdatedPosition.current[box.id]) {
      return {
        x: boxIdToUpdatedPosition.current[box.id].x,
        y: boxIdToUpdatedPosition.current[box.id].y,
      };
    }
    return {
      x: box.x,
      y: box.y,
    };
  }

  function initDrawingCanvas() {
    const drawCanvas = drawCanvasRef.current;
    const ctx = drawCanvas.getContext('2d');
    const rect = {
      startX: selectedBox?.x,
      startY: selectedBox?.y,
      w: selectedBox?.w,
      h: selectedBox?.h,
    };
    let drag = mode === 'MODE_MOVE';

    function getMousePos(e) {
      const bound = drawCanvas.getBoundingClientRect();
      return {
        x: Math.round(
          ((e.clientX - bound.left) / (bound.right - bound.left)) *
            drawCanvas.width,
        ),
        y: Math.round(
          ((e.clientY - bound.top) / (bound.bottom - bound.top)) *
            drawCanvas.height,
        ),
      };
    }

    function selectionAndBoxOverlap(mousePos, b) {
      if (!mousePos) return false;
      if (!selectionMode.current === SELECTION_MODE_MAKE) return false;
      if (!selectionMouseDownXY.current) return false;
      const { x: selectionStartX, y: selectionStartY } =
        selectionMouseDownXY.current;
      const { x: selectionEndX, y: selectionEndY } = mousePos;
      const selectionCoords = {
        left: Math.min(selectionStartX, selectionEndX),
        right: Math.max(selectionStartX, selectionEndX),
        top: Math.min(selectionStartY, selectionEndY),
        bottom: Math.max(selectionStartY, selectionEndY),
      };
      const { x, y } = getBoxPosition(b);
      return (
        x < selectionCoords.right &&
        x + b.w > selectionCoords.left &&
        y < selectionCoords.bottom &&
        y + b.h > selectionCoords.top
      );
    }

    function findBoxForMousePos(e) {
      const mouse = getMousePos(e);
      return boxesOnPageRef.current.find((box) => {
        const { x, y } = getBoxPosition(box);
        const path = new Path2D();
        path.rect(x, y, box.w, box.h);
        return ctx.isPointInPath(path, mouse.x, mouse.y);
      });
    }

    function getTextForBox(b) {
      const qId = b.id.split('-')[0];
      const question = questions.find((q) => q.id === qId);
      if (question) {
        return question.text;
      }
      return '';
    }

    function drawBoxText(b) {
      const { x, y } = getBoxPosition(b);
      if (SIGNATURE_BOX_TYPES.includes(b.type)) {
        ctx.fillText(b.type, x + 2, y + 8);
        ctx.drawImage(docusignImg, x, y - 18, 120, 50);
      } else if (CHECKBOX_TYPES.includes(b.type)) {
        ctx.fillText('✔', x + 2, y + 10);
      } else if (b.type === boxTypes.CUSTOMTEXTBOX) {
        ctx.fillText(getTextForBox(b), x + 2, y + 4);
      } else {
        const field = b.field ? ` ${b.field}` : '';
        ctx.fillText(`${b.label}${field} ${b.type}`, x + 2, y + 4);
      }

      if (b.deps?.length > 0) {
        ctx.fillText('?', x + 2, y - 8);
      }
    }

    function drawBoxes(currentMousePos) {
      ctx.font = '11px Helvetica';
      ctx.textBaseline = 'middle';
      function setStylesForBox(b) {
        ctx.setLineDash([]);
        if (selectionAndBoxOverlap(currentMousePos, b)) {
          ctx.strokeStyle = DRAG_SELECTED_BOX_LINE;
          ctx.fillStyle = DRAG_SELECTED_BOX_BG;
        } else if (selectedBox?.id === b.id) {
          ctx.strokeStyle = HIGHLIGHT_BOX_LINE;
          ctx.fillStyle = HIGHLIGHT_BOX_BG;
        } else if (dragSelectedBoxes.current.some((sb) => sb.id === b.id)) {
          ctx.setLineDash([6]);
          ctx.strokeStyle = DRAG_SELECTED_BOX_LINE;
          ctx.fillStyle = DRAG_SELECTED_BOX_BG;
        } else if (boxIdToUpdatedPosition.current[b.id]) {
          ctx.strokeStyle = MOVED_BOX_LINE;
          ctx.fillStyle = MOVED_BOX_BG;
        } else if (b.label === activeQuestionRef.current?.id) {
          ctx.strokeStyle = CURRENT_BOX_LINE;
          ctx.fillStyle = CURRENT_BOX_BG;
        } else {
          ctx.strokeStyle = OTHER_BOX_LINE;
          ctx.fillStyle = OTHER_BOX_BG;
        }
      }
      function setStylesForText(b) {
        if (selectionAndBoxOverlap(currentMousePos, b)) {
          ctx.fillStyle = DRAG_SELECTED_BOX_LINE;
        } else if (selectedBox?.id === b.id) {
          ctx.fillStyle = HIGHLIGHT_BOX_LINE;
        } else if (dragSelectedBoxes.current.some((sb) => sb.id === b.id)) {
          ctx.fillStyle = DRAG_SELECTED_BOX_LINE;
        } else if (boxIdToUpdatedPosition.current[b.id]) {
          ctx.fillStyle = MOVED_BOX_LINE;
        } else if (b.label === activeQuestionRef.current?.id) {
          ctx.fillStyle = CURRENT_BOX_LINE;
        } else {
          ctx.fillStyle = OTHER_BOX_LINE;
        }
      }
      boxesOnPageRef.current.forEach((b) => {
        ctx.setLineDash([]);
        setStylesForBox(b);
        const { x, y } = getBoxPosition(b);
        ctx.fillRect(x, y, b.w, b.h);
        ctx.strokeRect(x, y, b.w, b.h);
        setStylesForText(b);
        drawBoxText(b);
      });
    }

    function drawMovedSelectionBoxes(currentMousePos) {
      dragSelectedBoxes.current.forEach((b) => {
        ctx.strokeStyle = DRAG_SELECTED_BOX_LINE;
        ctx.fillStyle = DRAG_SELECTED_BOX_BG;
        ctx.setLineDash([6]);
        const dx = currentMousePos.x - selectionMouseDownXY.current.x;
        const dy = currentMousePos.y - selectionMouseDownXY.current.y;
        const { x, y } = getBoxPosition(b);
        ctx.fillRect(x + dx, y + dy, b.w, b.h);
        ctx.strokeRect(x + dx, y + dy, b.w, b.h);
        ctx.fillStyle = DRAG_SELECTED_BOX_LINE;
        if (CHECKBOX_TYPES.includes(b.type)) {
          ctx.fillText('✔', x + dx + 2, y + dy + 10);
        } else {
          ctx.fillText(`${b.label} ${b.type}`, x + dx + 2, y + dy + 4);
        }
      });
    }

    function draw() {
      ctx.strokeStyle = SELECTED_BOX_LINE;
      ctx.fillStyle = SELECTED_BOX_BG;
      ctx.setLineDash([6]);
      ctx.fillRect(rect.startX, rect.startY, rect.w, rect.h);
      ctx.strokeRect(rect.startX, rect.startY, rect.w, rect.h);
      ctx.fillStyle = SELECTED_BOX_LINE;

      const boxType = selectedBox?.type;
      if (CHECKBOX_TYPES.includes(boxType)) {
        ctx.fillText('✔', rect.startX + 2, rect.startY + 10);
      } else {
        ctx.fillText(boxType || '', rect.startX + 2, rect.startY + 4);
      }
    }

    function drawSelectionBox(currentMousePos) {
      ctx.strokeStyle = '#808080';
      ctx.fillStyle = '#80808022';
      ctx.setLineDash([6]);
      const startX = selectionMouseDownXY.current.x;
      const startY = selectionMouseDownXY.current.y;
      const { x: endX, y: endY } = currentMousePos;
      ctx.fillRect(startX, startY, endX - startX, endY - startY);
      ctx.strokeRect(startX, startY, endX - startX, endY - startY);
    }

    function normalizeRect() {
      // Fix rectangles draw backwards or upside-down
      if (rect.w < 0) {
        rect.startX += rect.w;
        rect.w = Math.abs(rect.w);
      }
      if (rect.h < 0) {
        rect.startY += rect.h;
        rect.h = Math.abs(rect.h);
      }
    }

    function mouseDown(e) {
      // We need to modify the local variables rect, drag and dragOffset
      // even though they will be updated in the re-render, because of
      // the mix of react + ref.
      const { x, y } = getMousePos(e);
      let resetSelectedBox = true;
      let resetDragSelectedBoxes = true;
      selectionMouseDownXY.current = null;
      selectionMode.current = null;
      if (mode === 'MODE_RESIZE') {
        rect.startX = x;
        rect.startY = y;
        rect.w = GRID_SIZE;
        rect.h = GRID_SIZE;
        drag = true;
        resetSelectedBox = false;
      } else if (mode === 'MODE_NORMAL') {
        const box = findBoxForMousePos(e);
        if (box && box.id === selectedBox?.id) {
          // eslint-disable-next-line no-param-reassign
          dragOffset.x = x - box.x;
          // eslint-disable-next-line no-param-reassign
          dragOffset.y = y - box.y;
          onMouseDownSelectedBox(box, dragOffset);
          rect.startX = x;
          rect.startY = y;
          rect.w = box.w;
          rect.h = box.h;
          drag = true;
          resetSelectedBox = false;
        } else if (
          box &&
          dragSelectedBoxes.current.some((sb) => sb.id === box.id)
        ) {
          resetDragSelectedBoxes = false;
          selectionMouseDownXY.current = { x, y };
          selectionMode.current = SELECTION_MODE_MOVE;
        } else {
          selectionMouseDownXY.current = { x, y };
          selectionMode.current = SELECTION_MODE_MAKE;
        }
      }
      if (resetSelectedBox) {
        setHighlightBoxId(null);
      }
      if (resetDragSelectedBoxes) {
        dragSelectedBoxes.current = [];
      }
    }

    function mouseUp(e) {
      const { x, y } = getMousePos(e);
      const dx = x - selectionMouseDownXY.current?.x;
      const dy = y - selectionMouseDownXY.current?.y;
      function doSelectionModeMove() {
        const newBoxIdToUpdatedPosition = { ...boxIdToUpdatedPosition.current };
        try {
          dragSelectedBoxes.current.forEach((b) => {
            const { x: boxX, y: boxY } = getBoxPosition(b);
            newBoxIdToUpdatedPosition[b.id] = {
              x: boxX + dx,
              y: boxY + dy,
              currentBox: b,
            };
            if (
              boxX + dx < 0 ||
              boxY + dy < 0 ||
              boxX + b.w + dx > PDF_WIDTH ||
              boxY + b.h + dy > PDF_HEIGHT
            ) {
              throw new BoxesMovedOutOfBoundsError();
            }
          });
          boxIdToUpdatedPosition.current = newBoxIdToUpdatedPosition;
          setHasUnsavedBoxPositions(true);
        } catch (exception) {
          if (exception instanceof BoxesMovedOutOfBoundsError) {
            errorToast('Some boxes moved out of bounds');
          }
        }
      }
      drag = false;
      let box;
      if (selectionMode.current === SELECTION_MODE_MAKE) {
        if (!selectionDragThresholdMet.current) {
          box = findBoxForMousePos(e);
          onMouseUpOverBox(box);
        } else {
          const selectedBoxes = boxesOnPageRef.current.filter((b) =>
            selectionAndBoxOverlap(getMousePos(e), b),
          );
          if (selectedBoxes.length > 0 && hasUnsavedChanges) {
            confirm({
              description: 'Discard unsaved form changes?',
              destructive: true,
            })
              .then(() => {
                dragSelectedBoxes.current = selectedBoxes;
                resetForm();
              })
              .catch(() => {
                dragSelectedBoxes.current = [];
              });
          } else {
            dragSelectedBoxes.current = selectedBoxes;
          }
        }
      } else if (selectionMode.current === SELECTION_MODE_MOVE) {
        // This if statement probably can't be true since the form should be
        // reset when drag selection is made, but it's here just in case.
        if (hasUnsavedChanges) {
          confirm({
            description: 'Discard unsaved form changes?',
            destructive: true,
          }).then(() => {
            doSelectionModeMove();
            resetForm();
          });
        } else {
          doSelectionModeMove();
        }
      } else {
        dragSelectedBoxes.current = [];
      }
      selectionMouseDownXY.current = null;
      selectionMode.current = null;
      selectionDragThresholdMet.current = false;
      // eslint-disable-next-line no-param-reassign
      selectedBox = box;
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      drawBoxes();
      normalizeRect();
      onDragEnd({ ...rect, page });
    }

    function mouseMove(e) {
      // Update cursor based on mode and box
      if (mode === 'MODE_RESIZE') {
        drawCanvas.style.cursor = 'crosshair';
      } else if (mode === 'MODE_MOVE') {
        drawCanvas.style.cursor = 'move';
      } else if (findBoxForMousePos(e)) {
        drawCanvas.style.cursor = 'pointer';
      } else {
        drawCanvas.style.cursor = 'default';
      }

      const { x, y } = getMousePos(e);
      if (selectionMode.current === SELECTION_MODE_MAKE) {
        if (
          selectionMouseDownXY.current &&
          !selectionDragThresholdMet.current
        ) {
          if (hasDragged(x, y)) {
            selectionDragThresholdMet.current = true;
          }
        }
        if (selectionDragThresholdMet.current) {
          ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
          drawSelectionBox({ x, y });
          drawBoxes({ x, y });
        }
      }
      if (selectionMode.current === SELECTION_MODE_MOVE) {
        ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
        drawMovedSelectionBoxes({ x, y });
        drawBoxes();
      }
      if (drag) {
        if (mode === 'MODE_RESIZE') {
          rect.w = roundToNearest(x - rect.startX, GRID_SIZE);
          rect.h = roundToNearest(y - rect.startY, GRID_SIZE);
          ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
          draw();
          drawBoxes();
        } else if (mode === 'MODE_MOVE') {
          rect.startX = Math.floor(x - dragOffset.x);
          rect.startY = Math.floor(y - dragOffset.y);
          ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
          draw();
          drawBoxes();
        }
      }
    }

    drawBoxes();

    drawCanvas.addEventListener('mousedown', mouseDown, true);
    drawCanvas.addEventListener('mouseup', mouseUp, true);
    drawCanvas.addEventListener('mousemove', mouseMove, true);

    function cleanup() {
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      drawCanvas.removeEventListener('mousedown', mouseDown, true);
      drawCanvas.removeEventListener('mouseup', mouseUp, true);
      drawCanvas.removeEventListener('mousemove', mouseMove, true);
    }

    return cleanup;
  }

  useEffect(initDrawingCanvas, [
    activeQuestion,
    mode,
    selectedBox?.id,
    questions.map((q) => q.text).join('/'),
    saveBoxPositions,
    hasUnsavedBoxPositions,
    forceCanvasUpdate,
  ]);

  function translateDragSelectedBoxes(dx, dy) {
    if (!dragSelectedBoxes.current.length) return;
    const newBoxIdToUpdatedPosition = { ...boxIdToUpdatedPosition.current };
    try {
      dragSelectedBoxes.current.forEach((b) => {
        const { x: boxX, y: boxY } = getBoxPosition(b);
        newBoxIdToUpdatedPosition[b.id] = {
          x: boxX + dx,
          y: boxY + dy,
          currentBox: b,
        };
        if (
          boxX + dx < 0 ||
          boxY + dy < 0 ||
          boxX + b.w + dx > PDF_WIDTH ||
          boxY + b.h + dy > PDF_HEIGHT
        ) {
          throw new BoxesMovedOutOfBoundsError();
        }
      });
      boxIdToUpdatedPosition.current = newBoxIdToUpdatedPosition;
      setHasUnsavedBoxPositions(true);
      updateCanvas();
    } catch (exception) {
      if (exception instanceof BoxesMovedOutOfBoundsError) {
        errorToast('Some boxes moved out of bounds');
      }
    }
  }

  function initKeyboard() {
    function keyDown(e) {
      switch (e.code) {
        case 'ArrowLeft':
          onTranslate({ x: -1, y: 0 });
          translateDragSelectedBoxes(-1, 0);
          return;
        case 'ArrowRight':
          onTranslate({ x: 1, y: 0 });
          translateDragSelectedBoxes(1, 0);
          return;
        case 'ArrowUp':
          onTranslate({ x: 0, y: -1 });
          translateDragSelectedBoxes(0, -1);
          return;
        case 'ArrowDown':
          onTranslate({ x: 0, y: 1 });
          translateDragSelectedBoxes(0, 1);
          break;
        case 'KeyR':
          if (selectedBox?.id) {
            onTriggerResize();
          }
          break;
        case 'Escape':
          if (dragSelectedBoxes.current.length) {
            dragSelectedBoxes.current = [];
            updateCanvas();
          }
          break;
        default:
      }
    }

    const keyboardArea = keyboardAreaRef.current;
    keyboardArea.addEventListener('keydown', keyDown, true);

    return () => {
      keyboardArea.removeEventListener('keydown', keyDown, true);
    };
  }

  useEffect(initKeyboard, [onTranslate]);

  return (
    <>
      {MULTIPLE_DOCUMENTS ? (
        <ManageFilesModal
          showModal={showFileUploadModal}
          onUpload={onDraftFileUploadSuccess}
          documents={documents}
          onClose={() => {
            setShowFileUploadModal(false);
          }}
        />
      ) : (
        <UploadDraftFileModal
          showModal={showFileUploadModal}
          onUpload={onDraftFileUploadSuccess}
          onClose={() => {
            setShowFileUploadModal(false);
          }}
        />
      )}
      <div>
        <div className={classes.dialogActions}>
          <IconButton
            onClick={() => {
              setZoom((z) => z + 0.1);
            }}
            aria-label="zoom in"
          >
            <ZoomInIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setZoom((z) => z - 0.1);
            }}
            aria-label="zoom out"
          >
            <ZoomOutIcon />
          </IconButton>

          <IconButton
            onClick={() => {
              setShowFileUploadModal(true);
            }}
          >
            <FileUploadOutline />
          </IconButton>

          {MULTIPLE_DOCUMENTS && documents?.length ? (
            <Select
              labelId="version-label"
              value={selectedDocument?.name || SUBDOC_DOC_NAME}
              label="Version"
              size="small"
              onChange={(e) =>
                selectDocument(
                  e.target.value === SUBDOC_DOC_NAME ? null : e.target.value,
                )
              }
            >
              {documents.map((doc) => (
                <MenuItem key={doc.name} value={doc.name || SUBDOC_DOC_NAME}>
                  {doc.name || SUBDOC_DOC_NAME}
                </MenuItem>
              ))}
            </Select>
          ) : null}

          {Boolean(pdf && pdf.numPages) && (
            <Stepper page={page} setPage={setPage} numPages={pdf.numPages} />
          )}
        </div>
        <div
          ref={keyboardAreaRef}
          tabIndex="0"
          style={{
            height: `${PDF_HEIGHT}px`,
            width: `${PDF_WIDTH}px`,
            transform: 'scale(1)',
            zoom,
          }}
        >
          <Document
            file={MULTIPLE_DOCUMENTS ? selectedDocument?.fileUrl : fileUrl}
            onLoadSuccess={(p) => {
              setPdf(p);
            }}
            loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
          >
            <Page
              pageNumber={page}
              loading={<Spinner height={PDF_HEIGHT} width={PDF_WIDTH} />}
              height={PDF_HEIGHT}
              width={PDF_WIDTH}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
          <canvas
            className={classes.drawCanvas}
            ref={drawCanvasRef}
            height={`${PDF_HEIGHT}px`}
            width={`${PDF_WIDTH}px`}
          />
        </div>
        <div className={classes.belowCanvas}>
          {selectedBox?.id || dragSelectedBoxes.current.length ? (
            <Typography>
              <Chip variant="neutral" label="Arrow keys" size="small" />: move
              box
              {selectedBox?.id ? (
                <>
                  {' · '}
                  <Chip variant="neutral" label="R" size="small" />: re-draw box
                </>
              ) : null}
            </Typography>
          ) : null}
          {hasUnsavedBoxPositions ? (
            <>
              <Button
                variant="secondary"
                destructive
                onClick={revertMovedBoxes}
              >
                Revert box positions
              </Button>
              <Button variant="primary" onClick={commitMovedBoxes}>
                Save box positions
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
