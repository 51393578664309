/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function TextQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  frontendLabel,
  multiline,
  QuestionPromptComponent,
}) {
  const initialText = answer || '';
  const [text, setText] = useState(initialText);
  const [errorMsg, setErrorMsg] = useState('');
  const hasInput = Boolean(text);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: text, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setText(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setText(initialText);
    setErrorMsg('');
  }, [label]);

  return (
    <form>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id={multiline ? 'text-long' : 'text'}
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={text}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          fullWidth
          multiline={multiline}
          rows={multiline ? 4 : ''}
        />
        <QuestionStepper
          disabled={!text}
          handleSubmit={handleSubmit}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}

export function LongTextQuestion({ ...props }) {
  return <TextQuestion {...props} multiline />;
}
