import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { COUNTRIES } from 'services/countries';
import { CheckBox, CheckBoxOutlineBlank, Clear } from '@material-ui/icons';
import { Chip } from '@passthrough/uikit';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export function CountriesInput({
  frontendLabel,
  countries,
  handleChange,
  errorMsg,
  autoFocus = false,
  hideLabels = false,
  helperText,
  dataTestLabel,
  ...params
}) {
  function findCountryByCode(code) {
    let country;
    country = COUNTRIES.find((c) => c.code === code);
    if (!country) {
      country = { label: code, code };
    }
    return country;
  }

  const [value, setValue] = React.useState(countries?.map(findCountryByCode));

  function getOptionLabel(option) {
    let country;

    if (typeof option === 'string') {
      country = COUNTRIES.find((c) => c.code === option);
      if (!country) {
        country = { label: option, code: option };
      }
    } else {
      country = option;
    }

    if (country) {
      return country.label;
    }

    return '';
  }

  return (
    <>
      <Autocomplete
        {...params}
        multiple
        options={COUNTRIES}
        autoHighlight
        selectOnFocus
        disableCloseOnSelect
        value={value || []}
        onChange={(event, newValue) => {
          setValue(newValue);
          handleChange(
            event,
            newValue.map((country) => country.code),
          );
        }}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(option, val) =>
          option.code === val.code || option.label === val.label
        }
        renderOption={(option, { selected }) => (
          <>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label} {hideLabels ? null : `(${option.code})`}
          </>
        )}
        renderTags={(selectedValues, getTagProps) =>
          selectedValues.map((option, index) => (
            <Chip
              variant="neutral"
              key={option.code}
              label={option.label}
              deleteIcon={<Clear />}
              {...getTagProps({ index })}
              sx={{ margin: 0.5 }}
            />
          ))
        }
        filterOptions={(options, inputParams) => {
          const filtered = options.filter(
            (option) =>
              option.label
                .toLowerCase()
                .includes(inputParams.inputValue?.toLowerCase()) ||
              option.code
                .toLowerCase()
                .includes(inputParams.inputValue?.toLowerCase()),
          );
          return filtered;
        }}
        renderInput={(otherParams) => (
          <TextField
            {...otherParams}
            label={frontendLabel || 'Countries'}
            variant="outlined"
            autoFocus={autoFocus}
            error={!!errorMsg}
            helperText={errorMsg || helperText}
            inputProps={{
              ...otherParams.inputProps,
              autoComplete: 'chrome-off',
              // avoiding id to thwart chrome autofill
              'data-test': dataTestLabel,
            }}
          />
        )}
      />
    </>
  );
}
