import React from 'react';
import List from '@material-ui/core/List';

import { EmptyState } from 'components/empty_v2';
import { InvestorSearchResult } from './InvestorSearchResult';

export function SearchResults({
  searchInput,
  hasSearched,
  results,
  selectedItemIndex,
  clearSearch,
  onInvestorClick,
}) {
  if (!hasSearched) {
    return null;
  }

  if (results.length === 0) {
    return (
      <EmptyState
        title={`No results for "${searchInput}"`}
        ctaPermission
        ctaOnClick={() => {
          clearSearch();
        }}
        ctaText="Clear search"
      />
    );
  }

  return (
    <List>
      {results.map((result, index) => (
        <InvestorSearchResult
          key={result.lpClosingId}
          lpClosing={result}
          onClick={() => {
            onInvestorClick(result);
          }}
          searchString={searchInput}
          isSelected={index === selectedItemIndex}
        />
      ))}
    </List>
  );
}
