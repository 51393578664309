import React, { useState } from 'react';

import { Alert, Modal, Typography } from '@passthrough/uikit';
import { TextField } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import MessageIcon from '@material-ui/icons/Message';

import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { MODAL_SEND_REMINDER_EMAIL } from './constants';

export function SendReminderEmailModal({
  selectedInvestment: investment,
  selectedModal,
  setSelectedInvestment,
  setSelectedModal,
}) {
  const [message, setMessage] = useState('');
  const [bannerError, setBannerError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { successToast } = useToast();
  const open =
    !!investment && selectedModal === MODAL_SEND_REMINDER_EMAIL && !isClosing;
  const {
    signer: { name: signerName, email: signerEmail },
  } = investment ?? { signer: { name: '', email: '' } };
  const signerDisplayName = signerName
    ? `${signerName} (${signerEmail})`
    : signerEmail;
  const clearErrors = () => {
    setBannerError('');
    setMessageError('');
  };
  const onSubmit = () => {
    clearErrors();
    setLoading(true);
    api
      .investmentsSendReminder({
        lpClosingId: investment.id,
        message,
        email: signerEmail,
      })
      .then(() => {
        successToast(`Sent reminder to ${signerDisplayName}`);
        setIsClosing(true);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setBannerError(error.response.data.detail);
          setMessageError(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onClose = () => setIsClosing(true);
  const onEntering = () => {
    setMessage('');
    clearErrors();
    setLoading(false);
    setIsClosing(false);
  };
  const onExited = () => {
    setSelectedInvestment(null);
    setSelectedModal(null);
    setMessage('');
    clearErrors();
    setLoading(false);
    setIsClosing(false);
  };
  return (
    <Modal
      open={open}
      headerLabel="Send reminder"
      onClose={onClose}
      onEntering={onEntering}
      onExited={onExited}
      primaryButtonText="Send"
      primaryButtonLoading={loading}
      onSubmit={onSubmit}
    >
      {bannerError ? <Alert severity="error">{bannerError}</Alert> : null}
      <KeyValuePairs>
        <KeyValuePair icon={<EmailIcon />}>
          <Typography>{signerDisplayName} will be notified.</Typography>
        </KeyValuePair>
        <KeyValuePair input icon={<MessageIcon />}>
          <TextField
            autofocus
            fullWidth
            variant="outlined"
            label="Include a message (optional)"
            multiline
            minRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={!!messageError}
            helperText={
              messageError || 'This message will appear in the reminder email'
            }
          />
        </KeyValuePair>
      </KeyValuePairs>
    </Modal>
  );
}
