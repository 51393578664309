import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import { Button } from 'components/button';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';
import { TextWithContextLinks } from '../text_with_context_links';

const useStyles = makeStyles((theme) => ({
  answer: {
    margin: theme.spacing(3),
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '350px', // same as <Question tall>
    textAlign: 'left',
  },
  selectAllButtonContainer: {
    display: 'flex',
  },
  label: {
    padding: theme.spacing(2),
    // 100% - padding of this - negative margin left
    width: 'calc(100% - 32px + 11px)',
  },
  actionArea: {
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
    },
  },
  compact: {
    minHeight: 'auto',
  },
  mobileChoiceLink: {
    padding: '10px',
    margin: '-10px',
  },
}));

export function MultiSelectQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  choices,
  allowSelectAll,
  QuestionStepper,
  QuestionPromptComponent,
  compact,
  links,
  pdfBoxes,
}) {
  const classes = useStyles();
  const initialSelections = answer || [];

  const [selections, setSelections] = useState(initialSelections);
  const [errorMsg, setErrorMsg] = useState('');
  const hasInput = Boolean(selections.length);

  function handleSubmit(e) {
    e.preventDefault();

    return updateLpDoc({
      label,
      answer: selections,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(e, value, exclusive) {
    setSaving(UNSAVED);
    setSelections((oldSelections) => {
      if (oldSelections.includes(value)) {
        return oldSelections.filter((s) => s !== value);
      }
      let newSelections;
      if (exclusive) {
        newSelections = [];
      } else {
        newSelections = oldSelections.filter(
          (s) => !choices.find((c) => c.text === s).exclusive,
        );
      }
      return [...newSelections, value];
    });
  }

  function handleSelectAll() {
    setSaving(UNSAVED);
    setSelections((oldSelections) => {
      if (oldSelections.length === 0) {
        return choices.filter((c) => !c.exclusive).map((c) => c.text);
      }
      return [];
    });
  }

  // Reset the selections when changing questions
  useEffect(() => {
    setSelections(initialSelections);
    setErrorMsg('');
  }, [label]);

  const selectAllButtonText = 'Select all';
  const clearButtonText = 'Clear';

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent short />
      <Answers tall={!compact}>
        {allowSelectAll ? (
          <div className={classes.selectAllButtonContainer}>
            <Button
              variant="text"
              onClick={() => {
                handleSelectAll();
              }}
              color="primary"
              data-test="select-all"
            >
              {selections.length === 0 ? selectAllButtonText : clearButtonText}
            </Button>
          </div>
        ) : null}
        <div
          className={clsx(classes.selectContainer, {
            [classes.compact]: compact,
          })}
        >
          <FormControl
            component="fieldset"
            variant="outlined"
            error={!!errorMsg}
            fullWidth
          >
            <FormGroup>
              {choices.map((c) => (
                <CardActionArea className={classes.actionArea} key={c.text}>
                  <Paper variant="outlined">
                    <FormControlLabel
                      key={c.text}
                      className={classes.label}
                      control={
                        <Checkbox
                          checked={selections.includes(c.text)}
                          onChange={(e) => {
                            handleChange(e, c.text, c.exclusive);
                          }}
                          name={c.text}
                        />
                      }
                      label={
                        <TextWithContextLinks
                          text={c.text}
                          choiceId={c.id}
                          allLinksForQuestion={links}
                          allPdfBoxesForQuestion={pdfBoxes}
                        />
                      }
                    />
                  </Paper>
                </CardActionArea>
              ))}
            </FormGroup>
            <FormHelperText>{errorMsg}</FormHelperText>
          </FormControl>
        </div>
        <QuestionStepper
          disabled={selections.length === 0}
          handleSubmit={handleSubmit}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
