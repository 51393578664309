import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useMe } from 'services/providers/me';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { DiligenceFileInput } from '../../components/file';
import { DiligenceMultiFileInput } from '../../components/multifile';
import { SubsectionDisplay } from '../subsection_display';
import { OptOut } from '../opt_out';
import { isDocumentsSectionComplete } from '../../utils';
import {
  MULTI_FILE_JURISDICTIONS,
  DOCUMENT_TYPES_WITH_EXPIRATION_DATES,
} from '../../constants';
import { DiligenceFileInputWithExpirationDate } from '../../components/file_with_expiration_date';

const useStyles = makeStyles((theme) => ({
  documentSubsectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: theme.spacing(2),
  },
  documentsContainer: {
    width: '100%',
  },
}));

export function DiligenceDocumentInputs({
  docFields,
  form,
  formErrors,
  onChange,
  fileUploadUrl,
  investorName,
  enableNameChecks,
  jurisdiction,
  expirationDates,
  expirationDatesDispatch,
}) {
  const classes = useStyles();
  const [{ isStaff }] = useMe();
  const { EXPIRATION_DATES } = useFeatureFlags();

  return (
    <div className={classes.documentsContainer}>
      {docFields.map((document) => {
        const updatedLabel = document.optional
          ? `(Optional) ${document.label}`
          : document.label;
        const filesArray = Array.isArray(form?.[document.key])
          ? form?.[document.key]
          : [form?.[document.key]];

        if (
          DOCUMENT_TYPES_WITH_EXPIRATION_DATES.includes(document.key) &&
          expirationDates &&
          EXPIRATION_DATES
        ) {
          return (
            <DiligenceFileInputWithExpirationDate
              investorName={investorName}
              key={document.key}
              docType={document.key}
              fileUploadUrl={fileUploadUrl}
              files={filesArray}
              allowMultipleFiles={MULTI_FILE_JURISDICTIONS.includes(
                jurisdiction,
              )}
              fileErrors={formErrors?.[document.key]}
              onChange={(newFiles) => onChange(document.key, newFiles)}
              formLabel={updatedLabel}
              helpText={document.helpText}
              isStaff={isStaff}
              enableNameChecks={enableNameChecks}
              expirationDates={expirationDates}
              expirationDatesDispatch={expirationDatesDispatch}
            />
          );
        }

        return MULTI_FILE_JURISDICTIONS.includes(jurisdiction) ? (
          <DiligenceMultiFileInput
            investorName={investorName}
            key={document.key}
            fileUploadUrl={fileUploadUrl}
            files={
              Array.isArray(form?.[document.key]) ? form?.[document.key] : []
            }
            fileErrors={formErrors?.[document.key]}
            onChange={(newFiles) => {
              onChange(document.key, newFiles);
            }}
            formLabel={updatedLabel}
            helpText={document.helpText}
            isStaff={isStaff}
            enableNameChecks={enableNameChecks}
          />
        ) : (
          <DiligenceFileInput
            investorName={investorName}
            key={document.key}
            fileUploadUrl={fileUploadUrl}
            file={form?.[document.key]}
            fileError={formErrors?.[document.key]}
            onChange={(newFile) => onChange(document.key, newFile)}
            formLabel={updatedLabel}
            helpText={document.helpText}
            isStaff={isStaff}
            enableNameChecks={enableNameChecks}
          />
        );
      })}
    </div>
  );
}

export function DocumentsSubsection({
  lpClosingId,
  fundName,
  form,
  hideOptOut,
  formErrors,
  handleChange,
  docFields,
  reuseDataChecked,
  expanded,
  onClick,
  statusIcon,
  jurisdiction,
}) {
  const classes = useStyles();

  if (docFields.length === 0 || reuseDataChecked) {
    return null;
  }
  const hasAllDocsData = isDocumentsSectionComplete(form, docFields);

  return (
    <SubsectionDisplay
      label="Documents"
      expanded={expanded}
      onClick={onClick}
      statusIcon={statusIcon}
    >
      <div className={classes.documentSubsectionContainer}>
        <DiligenceDocumentInputs
          docFields={docFields}
          form={form}
          formErrors={formErrors}
          onChange={handleChange}
          jurisdiction={jurisdiction}
          fileUploadUrl={`/api/lpclosing/${lpClosingId}/file/upload/`}
        />

        {hideOptOut ? null : (
          <OptOut
            nodeName={form.name}
            fundName={fundName}
            optOut={form.optOut}
            handleChange={handleChange}
            shouldDisable={hasAllDocsData}
          />
        )}
      </div>
    </SubsectionDisplay>
  );
}
