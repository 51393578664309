/* eslint-disable react/jsx-props-no-spreading, react/no-children-prop */
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Typography from '@material-ui/core/Typography';
import { Alert, useConfirm } from '@passthrough/uikit';

import { Button } from 'components/button';
import { SAVING, UNSAVED } from './saved';
import { getNextIncompleteSubsection, goToSubsection } from './diligence/utils';
import {
  SUBSECTION_DETAILS,
  SUBSECTION_DOCUMENTS,
  SUBSECTION_OWNERS,
  TYPE_INDIVIDUAL,
} from './diligence/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'inherit',
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  confirmButton: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cancellationButton: {
    marginBottom: theme.spacing(2),
  },
  optOutModalDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  warningText: {
    // Needed to override for the SDK dark theme
    // otherwise the text will be too pale
    color: theme.palette.primary.black,
  },
}));

export function DiligenceQuestionStepper({
  jurisdiction,
  currNodeName,
  handleSubmit,
  resetMissingDataErrors = () => {},
  saving,
  navRef,
  children,
  onClick,
  detailsExpanded,
  ownersExpanded,
  documentsExpanded,
  goToDetails,
  goToDocuments,
  goToOwners,
  isNested,
  rootHasChildren,
  documentsOptedOut,
  nodeRepeatsNameInChild,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const nav = navRef.current;
  const { steps } = nav;

  const hasInput = Boolean(currNodeName);
  const disabled = !hasInput;
  const fundName = nav?.lpDoc?.fundName;
  const question = steps[nav.outerStep].questions[nav.innerStep];

  const changed = saving === UNSAVED;

  const shouldShowUnchangedAnswerModal =
    question.needsConfirmation && !changed && !question.isCompleted;
  const shouldShowDocsOptOutModal = documentsOptedOut && documentsExpanded;
  const shouldShowSameParentChildNameModal =
    nodeRepeatsNameInChild && ownersExpanded;

  const currentSubSection = useMemo(() => {
    if (detailsExpanded) {
      return SUBSECTION_DETAILS;
    }
    if (documentsExpanded) {
      return SUBSECTION_DOCUMENTS;
    }
    if (ownersExpanded) {
      return SUBSECTION_OWNERS;
    }
    return '';
  }, [detailsExpanded, documentsExpanded, ownersExpanded]);

  function ContinueButton({ ...props }) {
    return (
      <Button
        id="continue"
        data-test="diligence_v2_continue"
        size="xl"
        fullWidth
        type="submit"
        variant="contained"
        loading={saving === SAVING}
        children={children || 'Continue'}
        {...props}
      />
    );
  }

  const onBackClick = () => {
    if (changed) {
      confirm({
        title: 'Discard unsaved changes?',
        description: 'Your changes have not been saved.',
        destructive: true,
        confirmationText: 'Discard',
      })
        .then(() => {
          nav.diligenceDecrement();
        })
        .catch(() => {});
    } else {
      nav.diligenceDecrement();
    }
  };

  const handleNextClick = (e) => {
    handleSubmit(e).then((returnVal) => {
      if (!(returnVal instanceof Error)) {
        const updatedQuestion =
          navRef.current.steps[nav.outerStep].questions[nav.innerStep];
        const showRootIndividualUI =
          !isNested && updatedQuestion.answer.type === TYPE_INDIVIDUAL;
        const nextSubsection = updatedQuestion.answer.idToReuse
          ? null
          : getNextIncompleteSubsection(
              updatedQuestion.answer,
              currentSubSection,
              jurisdiction,
              !isNested,
            );
        if (nextSubsection && !showRootIndividualUI) {
          goToSubsection(
            nextSubsection,
            goToDetails,
            goToDocuments,
            goToOwners,
          );
        } else {
          navRef.current.toNextRequired();
          return resetMissingDataErrors(updatedQuestion.label);
        }
      }

      return null;
    });
  };

  const onNextClick = (e) => {
    e.preventDefault();

    if (shouldShowDocsOptOutModal) {
      confirm({
        title: 'Continue without providing requested documents?',
        confirmationText: 'Continue',
        description: (
          <div className={classes.optOutModalDescriptionContainer}>
            <Alert severity="warning">
              <Typography className={classes.warningText}>
                Not all of the requested documents are provided
              </Typography>
            </Alert>
            {fundName} may still require these documents later.
          </div>
        ),
        primaryButtonProps: {
          'data-test': 'diligence_v2_opt_out_confirm',
        },
      })
        .then(() => {
          handleNextClick(e);
        })
        .catch(() => {});
    } else if (shouldShowSameParentChildNameModal) {
      confirm({
        title: 'Are you sure?',
        description: `You have entered ${currNodeName} as an owner of ${currNodeName}. Are you sure that this is correct?`,
        confirmationText: 'Yes',
      })
        .then(() => {
          handleNextClick(e);
        })
        .catch(() => {});
    } else if (shouldShowUnchangedAnswerModal) {
      confirm({
        title: 'Continue without changes?',
        description: `${fundName} added a new comment to the thread.
            Do you want to continue without changing your answer?`,
        confirmationText: 'Continue',
        confirmationButtonProps: {
          'data-test': 'confirm-submission-of-unchanged-answer',
          variant: 'contained',
          className: classes.confirmButton,
        },
        cancellationButtonProps: {
          className: classes.cancellationButton,
        },
        destructive: true,
      })
        .then(() => {
          handleNextClick(e);
        })
        .catch(() => {});
    } else {
      handleNextClick(e);
    }
  };

  const disabledOptionalQuestion = hasInput ? disabled : false;
  const disableButton = question.isRequired
    ? disabled
    : disabledOptionalQuestion;

  const shouldShowPrevButton = nav.innerStep > 0 || nav.outerStep > 0;
  const shouldHideBackButton =
    (!isNested && rootHasChildren) || !shouldShowPrevButton;

  return (
    <div className={classes.root}>
      <div key="back">
        <Button
          name="back"
          style={{ visibility: shouldHideBackButton ? 'hidden' : '' }}
          variant="text"
          color="default"
          size="xl"
          fullWidth
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBackClick}
        >
          Back
        </Button>
      </div>
      <div className={classes.rightSide}>
        <ContinueButton
          disabled={disableButton}
          onClick={onClick || onNextClick}
        />
      </div>
    </div>
  );
}
