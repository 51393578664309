import React, { useState } from 'react';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { STATUS_INFO } from 'components/status/constants';
import { Alert, Modal, Typography } from '@passthrough/uikit';
import * as api from 'services/api';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { useParams } from 'react-router-dom';
import { useToast } from 'services/toast';
import { getSingleErrorFromResponse } from 'services/utils';

export function FixStatusModal({ open, investor, handleClose, onChange }) {
  const { fundId, closingId } = useParams();
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { correctedStatus, correctedStatusNotifiesLpSteps } = investor;
  const { label: correctedLabel } = STATUS_INFO[correctedStatus] || {};
  const onSubmit = () => {
    setSubmitting(true);
    setErrorMessage('');
    api
      .fixLpClosingStatus({
        fundId,
        closingId,
        lpClosingId: investor.id,
        correctedStatus,
        correctedStatusNotifiesLpSteps,
      })
      .then(() => {
        toast('Investor status updated');
        onChange();
        handleClose();
      })
      .catch((error) => {
        const message = getSingleErrorFromResponse(error.response);
        setSubmitting(false);
        setErrorMessage(message);
      });
  };
  const onExited = () => {
    setSubmitting(false);
    setErrorMessage('');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onExited={onExited}
      headerLabel="Update status"
      onSubmit={onSubmit}
      primaryButtonText="Confirm"
      primaryButtonLoading={submitting}
      showCancelButton
    >
      <KeyValuePairs>
        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
        <KeyValuePair icon={<WarningIcon />}>
          Investor status will be changed to{' '}
          <Typography bodyComponentOverride="span" fontWeight={700}>
            {correctedLabel}
          </Typography>
          .
        </KeyValuePair>
        <KeyValuePair icon={<EmailIcon />}>
          Investor{' '}
          <Typography bodyComponentOverride="span" fontWeight={700}>
            {investor.correctedStatusNotifiesLpSteps.length
              ? 'will'
              : 'will not'}
          </Typography>{' '}
          be notified.
        </KeyValuePair>
      </KeyValuePairs>
    </Modal>
  );
}
