import React, { useState } from 'react';
import {
  Modal,
  Button,
  UIKitSettingsProvider,
  Typography,
} from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { KeyValuePair } from 'components/key_value';
import { useToast } from 'services/toast';
import * as api from 'services/api';
import { useDiscardConfirmation } from 'services/discard';

export function SendReminderDialog({ open, onChange, handleClose, investors }) {
  const { fundId, closingId } = useParams();
  const { successToast } = useToast();
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isSending, setIsSending] = useState(false);

  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: handleClose,
    needConfirmation: Boolean(message),
  });

  const count = investors.length;
  const plural = count > 1 ? 's' : '';
  const investorString = `${count} investor${plural}`;

  function onSubmit(e) {
    e.preventDefault();

    setIsSending(true);
    const lpClosingIds = investors.map((i) => i.id);

    api
      .sendReminderClosingDocs({
        fundId,
        closingId,
        lpClosingIds,
        message,
      })
      .then(() => {
        successToast(`Sent reminder${plural} to ${count} investor${plural}`);
        if (onChange) {
          onChange();
        }
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setMessageError(error.response.data.message);
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  }

  return (
    <Modal
      headerLabel="Send reminder"
      subheaderLabel={`${investorString} selected`}
      open={open}
      onClose={closeWithConfirmation}
      onSubmit={onSubmit}
      showCancelButton
      primaryButtonText="Send"
      primaryButtonLoading={isSending}
      onEntering={() => {
        setMessageError('');
        setMessage('');
        setIsSending(false);
      }}
      onExited={() => {
        setMessageError('');
        setMessage('');
        setIsSending(false);
      }}
      tertiaryButton={
        <UIKitSettingsProvider redirectAttribute="href">
          <Button
            variant="text"
            endIcon={<OpenInNewIcon color="primary" />}
            size="small"
            external
            href="https://support.passthrough.com/en_us/how-to-send-investor-reminder-emails-B1IVHq429"
          >
            Learn more
          </Button>
        </UIKitSettingsProvider>
      }
    >
      <Typography>The investor{plural} will be notified by email.</Typography>

      <KeyValuePair input icon={<MessageIcon />} disableGutters dense>
        <TextField
          id="message-to-investor"
          label="Custom message (optional)"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          variant="outlined"
          multiline
          fullWidth
          error={!!messageError}
          helperText={
            <Typography variant="label" size="small">
              {messageError ||
                'This message will appear in the email to investors.'}
            </Typography>
          }
          minRows={4}
        />
      </KeyValuePair>
    </Modal>
  );
}
