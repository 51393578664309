import {
  reducerActionTypes,
  matchStatuses,
  DATA_KEYS_USED_IN_SEARCHES,
} from './constants';
import { isMatchRead } from './utils';

function findNextUnevaluatedMatchId(potentialMatches, prevSelectedId) {
  const nextMatch = potentialMatches?.find(
    (matchData) => matchData.matchStatus === matchStatuses.NO_CHOICE,
  );

  // if no more unevaluated matches, keep prior ID selected
  return nextMatch?.id || prevSelectedId;
}

function mapStatusToOrderingNum(status) {
  switch (status) {
    case matchStatuses.NO_CHOICE:
      return 0;
    case matchStatuses.TRUE_MATCH:
      return 1;
    case matchStatuses.NOT_SURE:
      return 2;
    case matchStatuses.NOT_TRUE_MATCH:
      return 3;
    default:
      return -1;
  }
}

export function sortMatches(potentialMatches) {
  return potentialMatches.sort((matchA, matchB) => {
    const isARead = isMatchRead(matchA.matchStatus);
    const isBRead = isMatchRead(matchB.matchStatus);

    // unread first
    if (isARead && !isBRead) {
      return -1;
    }

    const statusA = mapStatusToOrderingNum(matchA.matchStatus);
    const statusB = mapStatusToOrderingNum(matchB.matchStatus);
    const statusSortVal = statusA - statusB;

    // if A is more relevant it will have a higher score than B,
    // and a negative value corresponds with A appearing first
    const relevanceScoreSortVal = (matchB.score || 0) - (matchA.score || 0);

    const nameSortVal = matchA.name.localeCompare(matchB.name);

    // if a preceding sort val is 0, the subsequent determines
    // the sort order and so on
    return statusSortVal || relevanceScoreSortVal || nameSortVal;
  });
}

function updateMatchData(potentialMatches, tgtId, updatedFieldsData) {
  const updatedMatches = potentialMatches.map((matchData) => {
    if (matchData.id !== tgtId) {
      return matchData;
    }

    return {
      ...matchData,
      ...updatedFieldsData,
    };
  });

  return sortMatches(updatedMatches);
}

export function groupMatchFieldsByKey(fields) {
  // ComplyAdvantage often returns match data with duplicate keys
  // which would otherwise clog the ui if we did not aggregate values
  // with duplicate keys
  if (!fields) {
    return {};
  }

  const fieldAnswersByKey = fields.reduce((acc, currField) => {
    const key = currField.name;
    const val = currField.value;

    if (key in acc) {
      acc[key].add(val);
    } else {
      acc[key] = new Set([val]);
    }
    return acc;
  }, {});

  Object.keys(fieldAnswersByKey).forEach((key) => {
    fieldAnswersByKey[key] = Array.from(fieldAnswersByKey[key]);
  });

  return fieldAnswersByKey;
}

function initializeMatchData(potentialMatches) {
  if (!potentialMatches) {
    return {};
  }

  const modifiedPotentialMatches = potentialMatches.map((matchData) => {
    const fieldAnswersByKey = groupMatchFieldsByKey(matchData?.fields);

    return { ...matchData, fields: fieldAnswersByKey };
  });

  const sortedPotentialMatches = sortMatches(modifiedPotentialMatches);

  return { potentialMatches: sortedPotentialMatches };
}

function getPriorSearchData(priorAnswer) {
  if (!priorAnswer) {
    return {};
  }

  const priorDataEntries = Object.entries(priorAnswer);
  const priorSearchedDataEntries = priorDataEntries.filter(([key]) =>
    DATA_KEYS_USED_IN_SEARCHES.includes(key),
  );
  return Object.fromEntries(priorSearchedDataEntries);
}

export function nodeDataReducer(state, action) {
  switch (action.type) {
    case reducerActionTypes.INITIALIZE_DATA: {
      const initializedData = initializeMatchData(action?.matchData);

      const firstMatchId = initializedData.potentialMatches?.[0]?.id || -1;
      const initialSelectedId = findNextUnevaluatedMatchId(
        initializedData?.potentialMatches,
        firstMatchId,
      );

      return {
        ...state,
        ...initializedData,
        supplementaryFiles: action.supplementaryFiles,
        searchedData: getPriorSearchData(action.priorAnswer),
        priorAnswer: action.priorAnswer,
        questionId: action.questionId,
        isApproved: action.nodeApproval,
        draftComment: action.draftComment,
        hasUnresolvedInternalNote: action.hasUnresolvedInternalNote,
        selectedMatchId: initialSelectedId,
        adverseMediaEnabled: action.adverseMediaEnabled,
        expirationDates: action.expirationDates,
      };
    }
    case reducerActionTypes.UPDATE_MATCH_STATUS: {
      const updatedMatchData = updateMatchData(
        state.potentialMatches,
        action.matchId,
        {
          matchStatus: action.newMatchStatus,
          explanations: action.newExplanations,
          lastReviewedAt:
            action.newMatchStatus === matchStatuses.NO_CHOICE
              ? null
              : Date.now(),
        },
      );

      return {
        ...state,
        potentialMatches: updatedMatchData,
        isChanged: true,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    }
    case reducerActionTypes.MARK_AS_REVIEWED: {
      const updatedMatchData = updateMatchData(
        state.potentialMatches,
        action.matchId,
        {
          lastReviewedAt: Date.now(),
        },
      );

      return {
        ...state,
        potentialMatches: updatedMatchData,
        isChanged: true,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    }
    case reducerActionTypes.AUTO_SELECT_NEXT_ID: {
      const idToEnqueue = findNextUnevaluatedMatchId(
        state.potentialMatches,
        state.selectedMatchId,
      );

      if (idToEnqueue === state.selectedMatchId) {
        // NOTE: auto selection after the last node will return that
        // same last node, so don't trigger the animation
        return { ...state };
      }

      return {
        ...state,
        queuedMatchId: idToEnqueue,
        showMatchStatus: false,
      };
    }
    case reducerActionTypes.APPROVE_NODE:
      return {
        ...state,
        isApproved: true,
        isChanged: true,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    case reducerActionTypes.UNAPPROVE_NODE:
      return {
        ...state,
        isApproved: false,
        isChanged: true,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    case reducerActionTypes.ADD_NOTE_TO_INVESTOR:
      return {
        ...state,
        draftComment: action.newNoteToInvestor,
        isChanged: true,
      };
    case reducerActionTypes.DELETE_NOTE_TO_INVESTOR:
      return {
        ...state,
        isChanged: true,
        draftComment: '',
      };
    case reducerActionTypes.RESOLVE_THREAD:
      return {
        ...state,
        draftComment: '',
        hasUnresolvedInternalNote: false,
        isChanged: true,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    case reducerActionTypes.REOPEN_THREAD:
      return {
        ...state,
        isChanged: true,
        hasUnresolvedInternalNote: action.threadHasInternalNoteEvent,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    case reducerActionTypes.ADD_INTERNAL_NOTE:
      return {
        ...state,
        isChanged: true,
        refreshActivityEvents: state.refreshActivityEvents + 1,
        hasUnresolvedInternalNote: true,
      };
    case reducerActionTypes.SET_SELECTED_MATCH_ID:
      return {
        ...state,
        selectedMatchId: action.selectedMatchId,
      };
    case reducerActionTypes.DEQUEUE_ID:
      return {
        ...state,
        selectedMatchId: state.queuedMatchId,
        showMatchStatus: true,
        queuedMatchId: null,
      };
    case reducerActionTypes.ENQUEUE_ID:
      if (action.id === state.selectedMatchId) {
        // don't repeatedly show animation if clicking same
        // match tab
        return { ...state };
      }

      return {
        ...state,
        queuedMatchId: action.id,
      };
    case reducerActionTypes.REFRESH_ACTIVITY_EVENTS:
      return {
        ...state,
        refreshActivityEvents: state.refreshActivityEvents + 1,
      };
    case reducerActionTypes.UPDATE_NUM_UNRESOLVED_THREADS:
      return {
        ...state,
        numUnresolvedThreads: action.numUnresolvedCommentThreads,
        loadingUnresolvedThreads: false,
      };
    case reducerActionTypes.CLEAR_NUM_UNRESOLVED_THREADS:
      return {
        ...state,
        loadingUnresolvedThreads: true,
      };
    case reducerActionTypes.REFRESH_SUPPLEMENTARY_FILES:
      return {
        ...state,
        supplementaryFiles: action.newSupplementaryFiles,
      };
    case reducerActionTypes.EDIT_NODE_DETAILS:
      // as newly created nodes do not refresh the entire page, we will
      // not have searchedData already computed for them and should just inject it
      // to avoid showing out of sync errors
      return {
        ...state,
        refreshActivityEvents: state.refreshActivityEvents + 1,
        searchedData: getPriorSearchData(action.newAnswer),
        expirationDates: action.expirationDates || state.expirationDates,
      };
    default:
      throw new Error('reducer received an action type that is not supported.');
  }
}

export function getNodeDataDefaultState(
  questionId,
  initialApprovalStatus,
  draftComment,
  numUnresolvedThreads,
  hasUnresolvedInternalNote,
) {
  return {
    isApproved: initialApprovalStatus,
    // id of underlying diligence node
    id: null,
    // id of the question that the node is associated with,
    // used to ensure that when relevant effects execute
    // that they have access to up to date data
    questionId,
    potentialMatches: null,
    draftComment,
    priorAnswer: null,
    // subset of prior answer actually used in comply advantage search
    searchedData: null,
    // incrementing this counter is used to fire another query
    // for the node's lpdiligence activity events
    refreshActivityEvents: 0,
    // when moving between potential matches, we first queue the id
    // of the newly selected match to initiate the fade transition
    queuedMatchId: null,
    // a queuedMatchId then becomes the selected id once the transition
    // completes, and this value is used to determine which potential
    // match data to display
    selectedMatchId: null,
    // we only hide match status for nodes that were just evaluated
    // and are being transitioned away from due to auto select
    showMatchStatus: true,
    // used to requery the overall lpdiligence upon closing the
    // review modal if a change that would affect the ui of the
    // diligence review tab has occurred
    isChanged: false,
    // used to communicate thread resolution status between
    // different parts of the diligence review modal component hierarchy
    numUnresolvedThreads,
    // if this is true, the internal note chip will be shown in the
    // display header for the node
    hasUnresolvedInternalNote,
    // files provided by admins to convey extra diligence findings
    supplementaryFiles: [],
    adverseMediaEnabled: false,
    expirationDates: [],
  };
}
