import React from 'react';
import { Redirect } from 'react-router-dom';
import * as urls from 'services/urls';
import { useFund } from 'services/providers/fund';
import { Spinner } from 'components/spinner';

export function FundPage() {
  const [fund, isLoadingFund] = useFund();
  const initialFundPageUrl = urls.useInitialFundPageUrl(fund);

  if (isLoadingFund) {
    return <Spinner fullScreen />;
  }
  return <Redirect to={initialFundPageUrl} />;
}
