import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

import { NoteIcon } from 'pages/review_v2/note_icon';
import { NoteIconVariant } from 'pages/review_v2/constants';

import {
  lpIconEvents,
  gpIconEvents,
  internalNoteIconEvents,
  useFundNameEvents,
  QUESTIONNAIRE_EVENT_TYPES,
  DILIGENCE_EVENT_TYPES,
} from 'services/thread_utils/constants';
import { EventHeader } from './event_header';
import { QuestionnaireEventBody } from './questionnaire_event_body';
import { DiligenceEventBody } from './diligence_event_body';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(1),
    minHeight: theme.spacing(0),
  },
  eventContentContainer: {
    flexBasis: '95%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    padding: theme.spacing(0.5, 0, 2, 0),
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexBasis: '5%',
  },
  invisible: {
    display: 'none',
  },
  customDot: {
    alignSelf: 'center',
  },
  dotContainerEventIcon: {
    borderColor: 'transparent',
    padding: theme.spacing(0),
  },
  connector: {
    borderLeftStyle: 'dashed',
    minHeight: theme.spacing(3),
    color: theme.palette.divider,
    backgroundColor: theme.palette.background.default,
  },
}));

function getNoteIconVariant(eventType) {
  if (lpIconEvents.includes(eventType)) {
    return NoteIconVariant.LP;
  }

  if (gpIconEvents.includes(eventType)) {
    return NoteIconVariant.GP;
  }

  if (internalNoteIconEvents.includes(eventType)) {
    return NoteIconVariant.INTERNAL_NOTE;
  }

  return null;
}

function EventIcon({ eventType }) {
  const variant = getNoteIconVariant(eventType);

  if (variant === null) {
    return null;
  }

  return <NoteIcon variant={variant} />;
}

function getAnswerType(question, isDiligence) {
  if (question?.answerType) {
    return question.answerType;
  }

  // as we do not pass diligence question data through the diligence review
  // modal hierarchy, simply check this flag and return the known diligence
  // answer type as diligence answers all use the same ui
  if (isDiligence) {
    return 'DiligenceAnswerType.name_known';
  }

  throw new Error('No answer type can be found');
}

export function EventDisplay({
  question,
  fundName,
  event,
  jurisdiction,
  showTimelineConnector,
}) {
  const classes = useStyles();

  const shouldFlattenEvent =
    event.type === DILIGENCE_EVENT_TYPES.ANSWER_SUBMITTED ||
    event.type === DILIGENCE_EVENT_TYPES.GP_ANSWER_SUBMITTED ||
    event.type === QUESTIONNAIRE_EVENT_TYPES.ANSWER_SUBMITTED ||
    event.type === QUESTIONNAIRE_EVENT_TYPES.GP_ANSWER_SUBMITTED;

  const isDiligence = Boolean(jurisdiction);
  const EventDisplayClass = isDiligence
    ? DiligenceEventBody
    : QuestionnaireEventBody;
  const useEventIcon =
    lpIconEvents.includes(event.type) ||
    gpIconEvents.includes(event.type) ||
    internalNoteIconEvents.includes(event.type);

  return (
    <TimelineItem className={classes.root}>
      <TimelineOppositeContent className={classes.invisible} />

      <TimelineSeparator className={classes.iconContainer}>
        <TimelineDot
          variant="outlined"
          className={clsx(classes.customDot, {
            [classes.dotContainerEventIcon]: useEventIcon,
          })}
        >
          <EventIcon eventType={event.type} />
        </TimelineDot>

        {showTimelineConnector ? (
          <TimelineConnector className={classes.connector} />
        ) : null}
      </TimelineSeparator>

      <TimelineContent className={classes.eventContentContainer}>
        <EventHeader
          event={event}
          answerType={getAnswerType(question, isDiligence)}
          nameVal={
            useFundNameEvents.includes(event.type)
              ? fundName
              : event?.user?.name
          }
          time={isDiligence ? event.isoEventTime : event.isoSubmissionTime}
          jurisdiction={jurisdiction}
          flattenEvent={shouldFlattenEvent}
        />

        {!shouldFlattenEvent ? (
          <EventDisplayClass question={question} event={event} />
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
}
