import React, { useEffect } from 'react';

import { LinkButton } from 'components/button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { getId } from './utils';
import { ReviewChange } from './review_change';
import { ReviewJSONDiff } from './review_json_diff';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const ReviewChanges = ({
  questionnaire,
  diff,
  keepTagsConfirmed,
  setKeepTagsConfirmed,
  fileChanged,
  additionalFiles,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState({});
  const [questionOrderExpanded, setQuestionOrderExpanded] =
    React.useState(false);

  useEffect(() => {
    const expandedMap = {};
    diff.created.forEach((q) => {
      const id = getId(q);
      expandedMap[id] = false;
    });
    diff.deleted.forEach((q) => {
      const id = getId(q);
      expandedMap[id] = false;
    });
    diff.updated.forEach((q) => {
      const id = getId(q[1]);
      expandedMap[id] = keepTagsConfirmed[id] !== undefined;
    });
    setExpanded(expandedMap);
  }, []);

  return (
    <>
      {fileChanged?.hasChanged ? (
        <ReviewChange
          id="subdoc"
          title="Subscription agreement updated."
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <LinkButton url={fileChanged.oldUrl}>Download old PDF</LinkButton>
          <LinkButton
            url={fileChanged.newUrl}
            className={classes.downloadButton}
          >
            Download new PDF
          </LinkButton>
        </ReviewChange>
      ) : null}

      {additionalFiles?.hasChanged ? (
        <ReviewChange
          id="additional-files"
          title="ADDITIONAL FILES CHANGED"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          {additionalFiles.added.length > 0 ? (
            <Typography>
              <strong>Added:</strong> {additionalFiles.added.join(', ')}
            </Typography>
          ) : null}
          {additionalFiles.updated.length > 0 ? (
            <Typography>
              <strong>Updated:</strong> {additionalFiles.updated.join(', ')}
            </Typography>
          ) : null}
          {additionalFiles.deleted.length > 0 ? (
            <Typography>
              <strong>Deleted:</strong> {additionalFiles.deleted.join(', ')}
            </Typography>
          ) : null}
        </ReviewChange>
      ) : null}

      {diff.questionOrder ? (
        <ReviewChange
          id="question-order"
          title="QUESTION ORDER CHANGED"
          expanded={questionOrderExpanded}
          setExpanded={setQuestionOrderExpanded}
        >
          <ReviewJSONDiff
            newObj={diff.questionOrder.newOrder}
            oldObj={diff.questionOrder.oldOrder}
          />
        </ReviewChange>
      ) : null}

      {diff.created.map((q) => {
        const id = getId(q);
        return (
          <ReviewChange
            id={id}
            key={id}
            title={`${id} CREATED`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <ReviewJSONDiff newObj={q} oldObj={{}} />
          </ReviewChange>
        );
      })}

      {diff.updated.map((q) => {
        const id = getId(q[1]);
        const questionTags = questionnaire.questionToTagsAndType[id]?.tags;
        const questionType = questionnaire.questionToTagsAndType[id]?.type;
        const allFields = questionnaire.questionToTagsAndType[id]?.fields;

        return (
          <ReviewChange
            key={id}
            id={id}
            title={`${id} UPDATED`}
            expanded={expanded}
            setExpanded={setExpanded}
            questionType={questionType}
            questionTags={questionTags}
            allFields={allFields}
            origQuestion={q[0]}
            newQuestion={q[1]}
            keepTagsConfirmed={keepTagsConfirmed}
            setKeepTagsConfirmed={setKeepTagsConfirmed}
          >
            <ReviewJSONDiff newObj={q[1]} oldObj={q[0]} />
          </ReviewChange>
        );
      })}

      {diff.deleted.map((q) => {
        const id = getId(q);
        return (
          <ReviewChange
            id={id}
            key={id}
            title={`${id} DELETED`}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <ReviewJSONDiff newObj={{}} oldObj={q} />
          </ReviewChange>
        );
      })}
    </>
  );
};
