import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { componentForQuestion } from 'components/lp_doc/component_for_question';
import { Question } from 'components/lp_doc/question';
import { QuestionStepper } from 'components/lp_doc/question_stepper';
import { Navigator } from 'components/lp_doc/navigator';
import { SAVED, SAVING } from 'components/lp_doc/saved';
import * as api from 'services/api';
import { useToast } from 'services/toast';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  basePaper: {
    padding: theme.spacing(4),
    minHeight: '500px',
    border: 0,
    borderRadius: 0,
    alignItems: 'center',
  },
  changeHeader: {
    margin: 0,
    padding: theme.spacing(1, 3),
    backgroundColor: '#f3f3f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerText: {
    fontWeight: '500',
  },
  outerContainer: {
    position: 'relative',
  },
}));

function MockNavRef() {
  const nav = new Navigator({
    lpDoc: {
      sections: [],
      docs: [],
    },
    navState: { innerStep: 0, outerStep: 0 },
    setNavState: () => {},
    setSelectedQuestionId: () => {},
  });
  return { current: nav };
}

export function OCRQuestionEditor({ question, doc, onChange }) {
  const classes = useStyles();
  const [saving, setSaving] = useState(SAVED);
  const { toast } = useToast();

  const { fundId, closingId } = useParams();

  const { answer, answerType, isRequired, label, frontendLabel, choices } =
    question;
  const Component = componentForQuestion({ answerType });
  const questionPrefixText = !isRequired ? '(Optional) ' : null;
  const QuestionPromptComponent = ({
    short,
    addPrefixText = true,
    children,
  }) => (
    <Question
      links={[]}
      prefixText={addPrefixText ? questionPrefixText : null}
      short={short}
      question={question.question}
    >
      {children}
    </Question>
  );

  const QuestionStepperComponent = ({ ...props }) => (
    <QuestionStepper
      navRef={MockNavRef()}
      saving={saving}
      setShowNavWarning={() => {}}
      hasInput={false}
      buttonText="Save"
      forceEnabled
      {...props}
    />
  );

  const updateLpDoc = (questionInfo) => {
    setSaving(SAVING);
    return api
      .submitAnswerFromGP({
        ...questionInfo,
        lpDocId: doc.id,
        fundId,
        closingId,
      })
      .then(() => {
        toast('Answer saved');
        onChange();
        setSaving(SAVED);
      });
  };

  return (
    <Paper className={classes.root} variant="outlined">
      <div className={classes.changeHeader}>
        <Typography className={classes.headerText}>Edit answer</Typography>
      </div>
      <div className={classes.outerContainer}>
        <Paper className={classes.basePaper} variant="outlined">
          <Component
            choices={choices?.map((c) => ({
              text: c.text,
              exclusive: c.exclusive,
            }))}
            question={question.question}
            links={[]}
            frontendLabel={frontendLabel}
            label={label}
            QuestionStepper={QuestionStepperComponent}
            setSaving={setSaving}
            updateLpDoc={updateLpDoc}
            onAnswer={() => {}}
            lpDoc={doc}
            answer={answer}
            QuestionPromptComponent={QuestionPromptComponent}
          />
        </Paper>
      </div>
    </Paper>
  );
}
