import { useHistory, matchPath } from 'react-router-dom';
import { useFeatureFlags } from './providers/feature_flag';

export const SIGNIN_URL = '/signin(/magic)?/:userId?';
export const signinUrl = ({ userId }) => `/signin/${userId || ''}`;

export const PRIVACY_POLICY_URL = 'https://passthrough.com/privacy';
export const TERMS_URL = 'https://passthrough.com/terms';
export const COOKIE_POLICY_URL = 'https://passthrough.com/cookie-policy';

export const DASH_URL = '/';

export const QUESTION_TAGS_URL = '/staff/tagging-center/';
export const SELECT_STAFF_ORG = '/staff/select-staff-org/';
export const DOCS_REDIRECT_URL = '/auth/docs/redirect/';
export const UIKIT_URL = '/staff/uikit/';
export const DILIGENCE_HUB_URL = '/staff/diligence/';

export const EXTERNAL_SIGNUP_URL = '/external/:slug/';
export const getExternalSignupUrl = ({ slug }) => `/external/${slug}/`;

export const FUNDS_URL = '/fund/';

export const INVESTMENTS_URL = '/investments/';

export const USER_SETTINGS_URL = '/user/settings/';

export const MANAGE_PROFILES_URL = '/manage_profile/';
export const MANAGE_PROFILE_URL = '/manage_profile/:profileId/';
export const manageProfile = ({ profileId }) =>
  `${DASH_URL}manage_profile/${profileId}/`;

// Not navigable to in the app, but used by staff to see all data in a profile
export const PROFILE_SAVED_DATA_URL = '/manage_profile/:profileId/saved_data/';

export const ORGANIZATION_URL = '/organization/:organizationId/';
export const organizationUrl = ({ organizationId }) =>
  `${DASH_URL}organization/${organizationId}/`;

export const ORGANIZATION_FUNDS_URL = '/organization/:organizationId/funds/';
export const organizationFundsUrl = ({ organizationId }) =>
  `${organizationUrl({ organizationId })}funds/`;

export const ORGANIZATION_ACCESS_URL = '/organization/:organizationId/access/';
export const organizationAccessUrl = ({ organizationId }) =>
  `${organizationUrl({ organizationId })}access/`;

export const ORGANIZATION_INTEGRATION_URL =
  '/organization/:organizationId/integrations/';

export const ORGANIZATION_SETTINGS_URL =
  '/organization/:organizationId/settings/';
export const organizationSettingsUrl = ({ organizationId }) =>
  `${organizationUrl({ organizationId })}settings/`;

export const FUND_URL = '/fund/:fundId/';
export const fundUrl = ({ fundId }) => `${DASH_URL}fund/${fundId}/`;

export const FUND_SETTINGS_URL = '/fund/:fundId/settings/';
export const fundSettingsUrl = ({ fundId }) =>
  `${fundUrl({ fundId })}settings/`;

export const ONBOARDING_V2_URL =
  '/fund/:fundId/questionnaires/:questionnaireId/draft/';

export const onboardingV2Url = ({ fundId, questionnaireId }) =>
  `${fundUrl({ fundId })}questionnaires/${questionnaireId}/draft/`;

export const OFFERING_URL = '/fund/:fundId/offering/';
export const offeringUrl = ({ fundId }) => `${fundUrl({ fundId })}offering/`;

export const SIDE_LETTERS_URL = '/fund/:fundId/sideletters/';
export const sideLettersUrl = ({ fundId }) =>
  `${fundUrl({ fundId })}sideletters/`;

export const CLOSINGS_URL = '/fund/:fundId/closings/';
export const closingsUrl = ({ fundId }) => `${fundUrl({ fundId })}closings/`;

export const DILIGENCES_URL = '/fund/:fundId/diligence/';
export const diligenceUrl = ({ fundId }) => `${fundUrl({ fundId })}diligence/`;

export const CLOSING_URL = '/fund/:fundId/closings/:closingId/';
export const closingUrl = ({ fundId, closingId }) =>
  `${fundUrl({ fundId })}closings/${closingId}/`;

export const CLOSING_SETTINGS_URL =
  '/fund/:fundId/closings/:closingId/settings/';
export const closingSettingsUrl = ({ fundId, closingId }) =>
  `${fundUrl({ fundId })}closings/${closingId}/settings/`;

export const INVESTOR_URL =
  '/fund/:fundId/closings/:closingId/investors/:investorId/';
export const investorUrl = ({ fundId, closingId, investorId }) =>
  `${fundUrl({ fundId })}closings/${closingId}/investors/${investorId}/`;

export const FUND_STAFF_TOOLS_URL = '/fund/:fundId/onboarding/';
export const fundStaffToolsUrl = ({ fundId }) =>
  `${fundUrl({ fundId })}onboarding/`;

export const FUND_CUSTOM_APPROVALS_URL = '/fund/:fundId/custom_approvals/';
export const fundCustomApprovalsUrl = ({ fundId }) =>
  `${fundUrl({ fundId })}custom_approvals/`;

export const REVIEW_URL =
  '/fund/:fundId/closings/:closingId/review/:lpClosingId/';
export const reviewUrl = ({ fundId, closingId, lpClosingId }) =>
  `${closingUrl({ fundId, closingId })}review/${lpClosingId}/`;

export const REVIEW_QUESTIONNAIRE_URL =
  '/fund/:fundId/closings/:closingId/review/:lpClosingId/questionnaire/';
export const reviewQuestionnaireUrl = ({
  fundId,
  closingId,
  lpClosingId,
  tab = 'questionnaire',
}) => `${closingUrl({ fundId, closingId })}review/${lpClosingId}/${tab}/`;

export const REVIEW_DILIGENCE_URL =
  '/fund/:fundId/closings/:closingId/review/:lpClosingId/diligence/';
export const reviewDiligenceUrl = ({ fundId, closingId, lpClosingId }) =>
  `${closingUrl({ fundId, closingId })}review/${lpClosingId}/diligence/`;

export const diligenceAccessUrl = ({ fundId, closingId, lpClosingId }) =>
  `/staff/api${closingUrl({
    fundId,
    closingId,
  })}review/${lpClosingId}/diligence-access/`;

export const REVIEW_DOCUMENTS_URL =
  '/fund/:fundId/closings/:closingId/review/:lpClosingId/documents/';
export const reviewDocumentsUrl = ({ fundId, closingId, lpClosingId }) =>
  `${closingUrl({ fundId, closingId })}review/${lpClosingId}/documents/`;

export const REVIEW_HISTORY_URL =
  '/fund/:fundId/closings/:closingId/review/:lpClosingId/history/';
export const reviewHistoryUrl = ({ fundId, closingId, lpClosingId }) =>
  `${closingUrl({ fundId, closingId })}review/${lpClosingId}/history/`;

export const COUNTERSIGN_URL = '/fund/:fundId/closings/:closingId/countersign/';
export const countersignUrl = ({ fundId, closingId }) =>
  `${closingUrl({ fundId, closingId })}countersign/`;

export const ACCESS_URL = '/fund/:fundId/access/';
export const accessUrl = ({ fundId }) => `${fundUrl({ fundId })}access/`;

export const LP_DOC_URL = '/document/:lpDocumentId/';
export const lpDocUrl = ({ lpDocumentId }) =>
  `${DASH_URL}document/${lpDocumentId}/`;

export const SECOND_SIGNER_URL = '/sign/:lpClosingId/';
export const secondSignerUrl = ({ lpClosingId }) =>
  `${DASH_URL}sign/${lpClosingId}/`;

export const FORGOT_PASSWORD_URL = '/forgot/';

/** Can be used outside of the fund <Route>, unlike useParams */
export function useFundId() {
  const history = useHistory();

  const match = matchPath(history.location.pathname, { path: FUND_URL });

  // match can be null
  return match && match.params.fundId;
}

/** Can be used outside of the fund <Route>, unlike useParams */
export function useOrganizationId() {
  const history = useHistory();

  const match = matchPath(history.location.pathname, {
    path: ORGANIZATION_URL,
  });

  // match can be null
  return match && match.params.organizationId;
}

export function useClosingId() {
  const history = useHistory();

  const match = matchPath(history.location.pathname, { path: CLOSING_URL });

  // match can be null
  return match && match.params.closingId;
}

export function useProfileId() {
  const history = useHistory();

  const match = matchPath(history.location.pathname, {
    path: MANAGE_PROFILE_URL,
  });

  // match can be null
  return match && match.params.profileId;
}

function getInitialFundPageUrl(fund) {
  if (fund?.hasSubdocs) {
    return closingsUrl({ fundId: fund.id });
  }
  if (fund?.diligenceEnabled) {
    return diligenceUrl({ fundId: fund.id });
  }
  return offeringUrl({ fundId: fund.id });
}

export function useInitialFundPageUrl(fund) {
  const { HIDE_CLOSINGS_TAB } = useFeatureFlags();
  if (fund) {
    if (HIDE_CLOSINGS_TAB) {
      return getInitialFundPageUrl(fund);
    }
    return closingsUrl({ fundId: fund.id });
  }
  // Fallback in case the fund endpoint returned a 400+ status
  return '/';
}

// Valid paths that can be redirected to after signing in
const ALL_VALID_PATHS = [
  DASH_URL,
  QUESTION_TAGS_URL,
  SELECT_STAFF_ORG,
  DOCS_REDIRECT_URL,
  UIKIT_URL,
  DILIGENCE_HUB_URL,
  FUNDS_URL,
  USER_SETTINGS_URL,
  MANAGE_PROFILES_URL,
  MANAGE_PROFILE_URL,
  PROFILE_SAVED_DATA_URL,
  ORGANIZATION_URL,
  ORGANIZATION_FUNDS_URL,
  ORGANIZATION_ACCESS_URL,
  ORGANIZATION_INTEGRATION_URL,
  ORGANIZATION_SETTINGS_URL,
  FUND_URL,
  FUND_SETTINGS_URL,
  ONBOARDING_V2_URL,
  OFFERING_URL,
  SIDE_LETTERS_URL,
  CLOSINGS_URL,
  DILIGENCES_URL,
  CLOSING_URL,
  CLOSING_SETTINGS_URL,
  INVESTOR_URL,
  FUND_STAFF_TOOLS_URL,
  FUND_CUSTOM_APPROVALS_URL,
  REVIEW_URL,
  REVIEW_QUESTIONNAIRE_URL,
  REVIEW_DILIGENCE_URL,
  REVIEW_DOCUMENTS_URL,
  REVIEW_HISTORY_URL,
  COUNTERSIGN_URL,
  ACCESS_URL,
  LP_DOC_URL,
  SECOND_SIGNER_URL,
];

function urlMatches(validUrl, url) {
  return (
    matchPath(url, {
      path: validUrl,
      exact: true,
      strict: true,
    }) !== null
  );
}

export function validatePath(path) {
  // Validate that path is a safe URL path
  if (
    !path.startsWith('/') ||
    // eslint-disable-next-line no-script-url
    path.includes('javascript:') ||
    path.includes('<') ||
    path.includes('>')
  ) {
    return '/';
  }
  // Check path against an allowlist of safe urls to avoid
  // a Client-Side URL Redirect vulnerability
  if (!ALL_VALID_PATHS.some((validPath) => urlMatches(validPath, path))) {
    return '/';
  }
  return path;
}
