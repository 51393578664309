import React, { useState } from 'react';

import { Alert, Modal, Typography } from '@passthrough/uikit';

import * as api from 'services/api';
import * as urls from 'services/urls';

import { useRegionalAction } from 'services/regional';
import { MODAL_MAKE_CHANGES_TO_SUBMISSION } from './constants';

export function MakeChangesToSubmissionModal({
  selectedInvestment: investment,
  selectedModal,
  setSelectedInvestment,
  setSelectedModal,
}) {
  const [bannerError, setBannerError] = useState('');
  const [loading, setLoading] = useState(false);
  const action = useRegionalAction();
  const open =
    !!investment && selectedModal === MODAL_MAKE_CHANGES_TO_SUBMISSION;
  const clearErrors = () => {
    setBannerError('');
  };
  const onSubmit = () => {
    clearErrors();
    setLoading(true);
    api
      .investmentsMakeChangesToSubmission({
        lpClosingId: investment.id,
      })
      .then(() => {
        const url = urls.lpDocUrl({ lpDocumentId: investment.id });
        const { baseUrl } = investment;
        action(baseUrl, url);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setBannerError(error.response.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onClose = () => {
    setSelectedInvestment(null);
    setSelectedModal(null);
  };
  const onEntering = () => {
    clearErrors();
    setLoading(false);
  };
  const onExited = () => {
    setSelectedInvestment(null);
    setSelectedModal(null);
    clearErrors();
  };
  return (
    <Modal
      open={open}
      headerLabel="Make changes to submission"
      onClose={onClose}
      onEntering={onEntering}
      onExited={onExited}
      primaryButtonText="Start"
      primaryButtonLoading={loading}
      showCancelButton
      onSubmit={onSubmit}
    >
      {bannerError ? <Alert severity="error">{bannerError}</Alert> : null}
      <Alert severity="warning">
        This will pause the signature process until resubmitted.
      </Alert>
      <Typography>
        You will be able to view and edit the submission. If you make any
        changes all signers will need to sign again.
      </Typography>
    </Modal>
  );
}
