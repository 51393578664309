import React, { useState } from 'react';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { PasswordForm } from './password_form';

export function SignInEnterPasswordStep({
  email,
  errorComponent,
  onConfirm,
  onSwitch,
  nextUrl,
}) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setPasswordError('');

    api
      .loginWithPassword({ email, password, nextUrl })
      .then(() => {
        setLoading(false);
        onConfirm();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          setPasswordError(
            error.response.data.password ||
              getSingleErrorFromResponse(error.response),
          );
        } else if (error.response?.status === 401) {
          setPasswordError('Incorrect email or password.');
        } else if (error.response?.status === 429) {
          setPasswordError(
            'Too many sign in attempts. Please wait a few minutes before trying again.',
          );
        } else {
          setPasswordError(getSingleErrorFromResponse(error.response));
        }
      });
  }

  return (
    <>
      {errorComponent}
      <PasswordForm
        email={email}
        onSwitch={onSwitch}
        onSubmit={handleSubmit}
        password={password}
        setPassword={setPassword}
        passwordError={passwordError}
        loading={loading}
      />
    </>
  );
}
