import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import { DatePicker } from 'components/date';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function DateQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialDate = answer ? new Date(answer) : null;
  const [date, setDate] = useState(initialDate);
  const [errorMsg, setErrorMsg] = useState('');

  const [isValidDate, setIsValidDate] = useState(false);
  const [formattedDate, setFormattedDate] = useState(null);
  const hasInput = Boolean(date);

  useEffect(() => {
    let dateFmt;
    try {
      dateFmt = format(date, 'MM/dd/yyyy');
      setIsValidDate(true);
    } catch (err) {
      dateFmt = '';
      setIsValidDate(false);
    }
    setFormattedDate(dateFmt);
  }, [date]);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: formattedDate,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(value) {
    setSaving(UNSAVED);
    setDate(value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setDate(initialDate);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <DatePicker
          autoFocus
          fullWidth
          date={date}
          setDate={handleChange}
          label={frontendLabel}
          error={!!errorMsg}
          helperText={errorMsg}
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!isValidDate}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
