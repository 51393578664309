import React from 'react';
import parse from 'autosuggest-highlight/parse';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@passthrough/uikit';
import { findTrigramMatches } from './trigram_utils';

const useStyles = makeStyles((theme) => ({
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    background: theme.palette.primary.light,
  },
}));

export function TrigramMatchingText({
  searchString,
  targetString,
  includeTypography = true,
  size = 'medium',
}) {
  const classes = useStyles();

  const matches = findTrigramMatches(searchString, targetString);
  const combinedMatchingParts = parse(targetString, matches);
  const highlightSpans = (
    <>
      {combinedMatchingParts.map((item, i) => {
        const uniqueKey = `${item.text}-${i}`;
        return (
          <span
            className={item.highlight ? classes.highlight : null}
            key={uniqueKey}
          >
            {item.text}
          </span>
        );
      })}
    </>
  );

  return includeTypography ? (
    <Typography component="p" size={size}>
      {highlightSpans}
    </Typography>
  ) : (
    highlightSpans
  );
}
