import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@passthrough/uikit';
import { useFund, useFundEdit } from 'services/providers/fund';
import { useWhiteLabelConfig } from 'services/providers/theme';

import { useMe } from 'services/providers/me';
import { GeneralSettings } from './general';
import { InvitationSettings } from './invitations';
import { QuestionnaireSettings } from './questionnaire';
import { DiligenceSettings } from './diligence';
import { DeleteButton } from './delete_button';

import { GeneralSettingsModal } from './modals/general_settings';
import { InvitationSettingsModal } from './modals/invitation_settings';
import { QuestionnaireSettingsModal } from './modals/questionnaire_settings';
import { DiligenceSettingsModal } from './modals/diligence_settings';
import * as constants from './constants';
import { CustomWorkflowsSettings } from './custom_workflows';
import { CustomWorkflowsSettingsModal } from './modals/custom_approval_settings';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
}));

const GENERAL_SETTINGS_MODAL = 'general_settings';
const INVITATION_SETTINGS_MODAL = 'invitation_settings';
const QUESTIONNAIRE_SETTINGS_MODAL = 'questionnaire_settings';
const DILIGENCE_SETTINGS_MODAL = 'diligence_settings';
const CUSTOM_APPROVALS_SETTINGS_MODAL = 'custom_approvals_settings';

function getSlug(url) {
  // Currently the sign-up link doesn't have a trailing slash,
  // this function will still work if we add the trailing slash.
  const match = url.match(/\/([^/]+)\/?$/);
  return match ? match[1] : constants.GENERATE_NEW_LINK;
}

export function PageBody({
  closing,
  members,
  fundId,
  fundName,
  errors,
  setErrors,
  errorMsg,
  isUpdateLoading,
  updateSettings,
  modalName,
  setModalName,
}) {
  const classes = useStyles();
  const [me] = useMe();
  const [fund] = useFund();
  const [fundEdit] = useFundEdit();
  const { productName } = useWhiteLabelConfig();

  const {
    readOnly,
    numTotal,

    // General/Invitations
    name,
    closingDate,
    defaultInvitationText,
    externalSignupLink,
    externalSignupText,
    subdocCompleteText,

    // Questionnaire
    subscriptionDocumentFileUrl,
    subscriptionDocumentName,
    numberOfCountersigners,
    defaultCountersignerId,
    defaultCountersigner2Id,
    defaultCountersigner3Id,
    downloadable,
    disableOfflineSigning,

    subdocsUsingThisAsSupplementalMaterials,

    // Diligence
    diligenceEnabled,
    diligenceJurisdiction,
    defaultDiligenceApproverId,

    // custom approvals
    optOutOfCustomApproval,
  } = closing;

  const investorAccess = externalSignupLink
    ? constants.SIGNUP_LINK
    : constants.INVITE_ONLY;
  const externalSignupSlug = externalSignupLink
    ? getSlug(externalSignupLink)
    : constants.GENERATE_NEW_LINK;

  const countersignersAreOptional =
    investorAccess === constants.INVITE_ONLY &&
    subdocsUsingThisAsSupplementalMaterials?.length === 0;

  const hasSubdocQuestionnaire = Boolean(
    subscriptionDocumentName && subscriptionDocumentFileUrl,
  );

  const useDefaultExternalSignupText = !externalSignupText;
  const defaultExternalSignupText =
    `${fundName} is using ${productName} to manage their subscription ` +
    "documents and investor questionnaire. You'll be asked a series of " +
    'questions to collect investor information and determine eligibility.';

  return (
    <>
      <div className={classes.container}>
        {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}
        {readOnly ? (
          <Alert severity="warning">
            This closing is read-only and cannot be updated. Please contact
            support@passthrough.com if you need to make edits.
          </Alert>
        ) : null}

        <GeneralSettings
          name={name}
          closingDate={closingDate}
          hasSubdocQuestionnaire={hasSubdocQuestionnaire}
          diligenceEnabled={diligenceEnabled}
          subdocCompleteText={subdocCompleteText}
          onEdit={() => setModalName(GENERAL_SETTINGS_MODAL)}
          readOnly={readOnly}
        />
        <InvitationSettings
          defaultInvitationText={defaultInvitationText}
          investorAccess={investorAccess}
          externalSignupLink={externalSignupLink}
          externalSignupText={externalSignupText}
          defaultExternalSignupText={defaultExternalSignupText}
          onEdit={() => setModalName(INVITATION_SETTINGS_MODAL)}
          readOnly={readOnly}
        />
        <QuestionnaireSettings
          members={members}
          hasSubdocQuestionnaire={hasSubdocQuestionnaire}
          subscriptionDocumentFileUrl={subscriptionDocumentFileUrl}
          subscriptionDocumentName={subscriptionDocumentName}
          numberOfCountersigners={numberOfCountersigners}
          defaultCountersignerId={defaultCountersignerId}
          defaultCountersigner2Id={defaultCountersigner2Id}
          defaultCountersigner3Id={defaultCountersigner3Id}
          downloadable={downloadable}
          disableOfflineSigning={disableOfflineSigning}
          countersignersAreOptional={countersignersAreOptional}
          onEdit={() => setModalName(QUESTIONNAIRE_SETTINGS_MODAL)}
          readOnly={readOnly}
        />
        <DiligenceSettings
          members={members}
          diligenceEnabled={diligenceEnabled}
          diligenceJurisdiction={diligenceJurisdiction}
          defaultDiligenceApproverId={defaultDiligenceApproverId}
          onEdit={() => setModalName(DILIGENCE_SETTINGS_MODAL)}
          readOnly={readOnly}
        />
        {me?.isStaff && fund?.hasCustomApprovalGroups ? (
          <CustomWorkflowsSettings
            onEdit={() => setModalName(CUSTOM_APPROVALS_SETTINGS_MODAL)}
            optOutOfCustomApproval={optOutOfCustomApproval}
            readOnly={readOnly}
          />
        ) : null}
        <DeleteButton
          hidden={readOnly || !fundEdit}
          closingName={name}
          canDeleteClosing={numTotal === 0}
          hasExternalSignupLink={Boolean(externalSignupLink)}
        />
      </div>

      <GeneralSettingsModal
        open={modalName === GENERAL_SETTINGS_MODAL}
        isDiligenceEnabled={diligenceEnabled}
        hasSubdoc={hasSubdocQuestionnaire}
        initialState={{
          name,
          closingDate,
          omitClosingDate: !closingDate,
          subdocCompleteText,
        }}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={isUpdateLoading}
        onClose={() => setModalName(null)}
      />
      <InvitationSettingsModal
        open={modalName === INVITATION_SETTINGS_MODAL}
        closingName={name}
        defaultExternalSignupText={defaultExternalSignupText}
        initialState={{
          defaultInvitationText,
          investorAccess,
          externalSignupSlug,
          externalSignupText,
          useDefaultExternalSignupText,
        }}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={isUpdateLoading}
        onClose={() => setModalName(null)}
      />
      <QuestionnaireSettingsModal
        open={modalName === QUESTIONNAIRE_SETTINGS_MODAL}
        subscriptionDocumentFileUrl={subscriptionDocumentFileUrl}
        subscriptionDocumentName={subscriptionDocumentName}
        numberOfCountersigners={numberOfCountersigners}
        countersignersAreOptional={countersignersAreOptional}
        members={members}
        fundId={fundId}
        initialState={{
          defaultCountersignerId,
          defaultCountersigner2Id,
          defaultCountersigner3Id,
          downloadable,
          disableOfflineSigning,
        }}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={isUpdateLoading}
        onClose={() => setModalName(null)}
      />
      <DiligenceSettingsModal
        open={modalName === DILIGENCE_SETTINGS_MODAL}
        diligenceJurisdiction={diligenceJurisdiction}
        members={members}
        fundId={fundId}
        initialState={{
          defaultDiligenceApproverId,
        }}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={isUpdateLoading}
        onClose={() => setModalName(null)}
      />
      <CustomWorkflowsSettingsModal
        open={modalName === CUSTOM_APPROVALS_SETTINGS_MODAL}
        initialState={{
          optOutOfCustomApproval,
        }}
        errors={errors}
        setErrors={setErrors}
        updateSettings={updateSettings}
        loading={isUpdateLoading}
        onClose={() => setModalName(null)}
      />
    </>
  );
}
