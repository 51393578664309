import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Analytics from 'react-router-ga';
import * as Sentry from '@sentry/react';
import { ConfirmProvider } from '@passthrough/uikit';
import { Button } from 'components/button';
import { SupportProvider } from 'components/support';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SignIn } from 'pages/signin_v2';
import { ExploreForm } from 'pages/explore/form';
import { Dashboard } from 'pages/dashboard';
import { ExternalSignup } from 'pages/external_signup_v2';
import { ForgotPassword } from 'pages/forgot_password';
import { FundsProvider } from 'services/providers/funds';
import { useToast, SnackbarCloseButton } from 'services/toast';
import { ErrorBoundary } from 'services/error_boundary';
import { pdfjs } from 'react-pdf';
import * as urls from 'services/urls';

import { ThemeProvider, useWhiteLabelConfig } from 'services/providers/theme';
import { FundErrorDialog } from 'components/fund_external_access/error_dialog';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  autoSessionTracking: true,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
  enabled: process.env.SENTRY_ENABLED,
  integrations: [...Sentry.defaultIntegrations, new Sentry.BrowserTracing()],
  replaysSessionSampleRate: process.env.SENTRY_SESSION_REPLAY_ENABLED
    ? 1.0
    : undefined,
  beforeSend(event) {
    if (
      event.exception?.values[0].value === 'Request failed with status code 403'
    ) {
      return null;
    }
    return event;
  },
});

pdfjs.GlobalWorkerOptions.workerSrc = window.PDF_WORKER_SRC;

function AxiosProvider({ children, setPollTasks }) {
  const { successToast, errorToast, toast, dismiss } = useToast();
  const [toastKey, setToastKey] = useState(null);
  const [showFundErrorDialog, setShowFundErrorDialog] = useState(false);
  const { productName } = useWhiteLabelConfig();

  function networkError(oldToastKey) {
    if (oldToastKey !== null) {
      // Do nothing. There's already an error.
      return oldToastKey;
    }

    const newToastKey = errorToast('Connection lost', {
      persist: true,
      action: () => (
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </Button>
      ),
    });

    return newToastKey;
  }

  function addInterceptors() {
    const errorInterceptor = axios.interceptors.response.use(
      // if anything succeeds, dismiss the "connection lost" error.
      (response) => {
        if (toastKey) {
          dismiss(toastKey);
          successToast('Back online!', { preventDuplicate: true });
          setToastKey(null);
        } else if (
          response.headers['bundle-version'] !== window.BUNDLE_VERSION
        ) {
          toast(`A new version of ${productName} is available.`, {
            persist: true,
            preventDuplicate: true,
            action: () => (
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            ),
          });
        }
        setPollTasks(true);

        return response;
      },
      (error) => {
        // We don't want to show the errorToast automatically for the tasks endpoint because
        // it is called every 15 seconds and sometimes we get a connection error from postgres
        if (
          error?.response?.config?.url === '/api/tasks/' &&
          error.response.status === 500
        ) {
          return Promise.reject(error);
        }

        if (!error.response || error.response.status === 503) {
          // Network error or the site is down.
          setToastKey(networkError);
          return Promise.reject(error);
        }

        if (error.response && error.response.status) {
          if (error.response.status >= 500) {
            // TODO: I'd prefer if all uncaught errors could trigger this,
            // instead of just all 500 errors.
            // That will probably require rewriting all apis to use use a common
            // catch, which first runs the provided code for that endpoint,
            // then the common code for all endpoints.
            errorToast(
              `An unexpected error occurred. Please refresh and try again.
              If the problem persists, contact support at support@passthrough.com.`,
              {
                persist: true,
                preventDuplicate: true,
                action: () => (
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh
                  </Button>
                ),
              },
            );
          }

          if (error.response.status === 423) {
            // We return a 423 if the fund that the API is accessing is locked.
            setShowFundErrorDialog(true);
          }
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(errorInterceptor);
    };
  }

  useEffect(addInterceptors, [toastKey]);

  return (
    <>
      {children}
      <FundErrorDialog
        open={showFundErrorDialog}
        handleClose={() => setShowFundErrorDialog(false)}
      />
    </>
  );
}

function BasicProviders({ children, setPollTasks }) {
  return (
    <ThemeProvider>
      <ConfirmProvider>
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
        >
          <SupportProvider>
            <ErrorBoundary>
              <Router>
                <AxiosProvider setPollTasks={setPollTasks}>
                  {children}
                </AxiosProvider>
              </Router>
            </ErrorBoundary>
          </SupportProvider>
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

function LoggedInProviders({ children }) {
  return <FundsProvider>{children}</FundsProvider>;
}

// https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f
const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

export function App() {
  const [signinError, setSigninError] = useState(null);
  const [signinFlashMessage, setSigninFlashMessage] = useState(null);
  const pollTasks = useRef(true);

  const setPollTasks = (value) => {
    pollTasks.current = value;
  };

  return (
    <BasicProviders setPollTasks={setPollTasks}>
      <ConditionalWrap
        condition={!!window.GA_TRACKING_ID}
        wrap={(children) => (
          <Analytics id={window.GA_TRACKING_ID}>{children}</Analytics>
        )}
      >
        <Switch>
          <Route path={urls.SIGNIN_URL}>
            {window.EXPLORE_MODE ? (
              <ExploreForm />
            ) : (
              <SignIn
                signinError={signinError}
                flashMessage={signinFlashMessage}
                clearFlashMessage={() => setSigninFlashMessage(null)}
              />
            )}
          </Route>
          <Route path={urls.EXTERNAL_SIGNUP_URL}>
            <ExternalSignup setSigninError={setSigninError} />
          </Route>
          <Route path={urls.FORGOT_PASSWORD_URL} exact>
            <ForgotPassword
              onSuccess={() =>
                setSigninFlashMessage('Your password has been changed.')
              }
            />
          </Route>
          <Route path={urls.DASH_URL}>
            <LoggedInProviders>
              <Dashboard pollTasks={pollTasks} setPollTasks={setPollTasks} />
            </LoggedInProviders>
          </Route>
        </Switch>
      </ConditionalWrap>
    </BasicProviders>
  );
}
