import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { TableRow, TableCell } from 'components/table/index';
import { useFundEdit } from 'services/providers/fund';
import { Button, Typography, Icons } from '@passthrough/uikit';
import { OfferingDocActionsMenu } from './offering_doc_actions_menu';

const useStyles = makeStyles((theme) => ({
  rowName: {
    display: 'flex',
    alignItems: 'center',
  },
  archiveIcon: {
    marginRight: theme.spacing(1),
  },
}));

export function OfferingDocListElement({
  document,
  onDelete,
  onArchive,
  onUnarchive,
  onEdit,
  onReplace,
}) {
  const classes = useStyles();
  const [fundEdit] = useFundEdit();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow key={document.id}>
      <TableCell scope="row">
        <div className={classes.rowName}>
          {document.isArchived ? (
            <Tooltip
              title={
                <Typography variant="label">
                  This document is archived. It will not be sent to new
                  investors.
                </Typography>
              }
            >
              <Icons.ArchiveOutlined
                color="action"
                className={classes.archiveIcon}
              />
            </Tooltip>
          ) : null}
          <Typography variant="body">{document.name}</Typography>
        </div>
      </TableCell>
      <TableCell>{document.type}</TableCell>
      <TableCell>{document.createdDate}</TableCell>
      <TableCell>{document.createdBy}</TableCell>
      <TableCell actions>
        <Button
          variant="icon"
          size="large"
          onClick={() => {
            window.open(document.url, '_blank');
          }}
          aria-label="View document"
        >
          <Icons.OpenInNewOutlined />
        </Button>
        {fundEdit ? (
          <>
            <Button
              variant="icon"
              size="large"
              onClick={handleMenu}
              color="inherit"
              aria-label="More options"
            >
              <Icons.MoreVertOutlined />
            </Button>
            <OfferingDocActionsMenu
              document={document}
              anchorEl={anchorEl}
              menuOpen={menuOpen}
              handleClose={handleClose}
              onDelete={onDelete}
              onEdit={onEdit}
              onArchive={onArchive}
              onUnarchive={onUnarchive}
              onReplace={onReplace}
            />
          </>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
