import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

import { DisplayLink } from 'components/link';
import { US_STATES } from 'services/us_states';

import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function StateWithEscapeQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialStateName = answer || '';
  const [stateName, setStateName] = useState(initialStateName);
  const [errorMsg, setErrorMsg] = useState('');
  const [showSmartInput, setShowSmartInput] = useState(
    () => !answer || Boolean(US_STATES.find((c) => c.label === stateName)),
  );
  const hasInput = Boolean(stateName);

  function handleSubmit(e) {
    e.preventDefault();

    return updateLpDoc({
      label,
      answer: stateName,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(e, newValue) {
    e.preventDefault();
    e.stopPropagation();

    const value = newValue ?? e.target.value;
    setSaving(UNSAVED);

    if (!value) {
      setStateName('');
    } else if (typeof value === 'string') {
      setStateName(value);
    } else {
      setStateName(value.label);
    }
  }

  // Reset the stateName when changing questions
  useEffect(() => {
    setStateName(initialStateName);
    setErrorMsg('');
  }, [label]);

  function getOptionLabel(option) {
    let state;

    if (typeof option === 'string') {
      state = US_STATES.find((c) => c.label === option);
    } else {
      state = option;
    }

    if (state) {
      return state.label;
    }

    return '';
  }

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        {showSmartInput ? (
          <>
            <Autocomplete
              options={US_STATES}
              autoHighlight
              selectOnFocus
              value={US_STATES.find((c) => c.label === stateName) || null}
              onChange={handleChange}
              getOptionLabel={getOptionLabel}
              renderOption={(option) => <>{option.label}</>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  variant="outlined"
                  autoFocus
                  error={!!errorMsg}
                  helperText={errorMsg}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                    // avoiding id to thwart chrome autofill
                    'data-test': 'state',
                  }}
                />
              )}
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setShowSmartInput(false);
                }}
              >
                I want to provide a non-US region (city, province, etc.) ›
              </DisplayLink>
            </FormHelperText>
          </>
        ) : (
          <>
            <TextField
              autoFocus
              value={stateName}
              onChange={handleChange}
              label="Non-US city, province, or region"
              variant="outlined"
              fullWidth
              error={!!errorMsg}
              helperText={errorMsg}
              inputProps={{
                autoComplete: 'chrome-off',
                // avoiding id to thwart chrome autofill
                'data-testid': 'state_with_escape',
              }}
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setStateName('');
                  setShowSmartInput(true);
                }}
              >
                I want to provide a US state ›
              </DisplayLink>
            </FormHelperText>
          </>
        )}
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!stateName}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
