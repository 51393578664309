import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as api from 'services/api';
import { useMe } from 'services/providers/me';
import { Button } from 'components/button';
import { AllInstructions } from './all_instructions';
import { ReviewInstructionsIcon } from './review_instructions_icon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(4),
  },
  icon: {
    marginRight: theme.spacing(4),
  },
  instructionsTitle: {
    marginBottom: theme.spacing(2),
  },
  instructionBottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  instructionsContainer: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  dismissInstructionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  greyText: {
    color: theme.palette.primary.grey,
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  smallFlex: {
    flex: 1,
  },
  errorText: {
    marginLeft: theme.spacing(5),
  },
}));

export function DiligenceReviewInstructions({
  setShouldNotDisplayInstructions,
  hideInstructionsUserSetting,
  setHideInstructionsUserSetting,
  className,
}) {
  const classes = useStyles();
  const [, onChangeMe] = useMe();
  const [errorMsg, setErrorMsg] = useState('');

  const handleCheckboxClick = (e) => {
    const isChecked = e.target.checked;
    setErrorMsg('');

    api
      .saveUserDiligenceInstructionDisplaySetting({
        hideDiligenceInstructions: isChecked,
      })
      .then(() => {
        setHideInstructionsUserSetting(isChecked);
        onChangeMe();
      })
      .catch(() => {
        setErrorMsg('There was an error updating this user setting.');
      });
  };

  return (
    <div className={clsx(classes.root, className)}>
      <ReviewInstructionsIcon className={classes.icon} />

      <div>
        <>
          <Typography variant="h6" className={classes.instructionsTitle}>
            Reviewing owners
          </Typography>
          <Typography variant="body1">
            You are reviewing individuals or entities in the investor's
            ownership structure for diligence purposes. Follow these steps to
            ensure compliance:
          </Typography>
        </>

        <AllInstructions className={classes.instructionsContainer} />

        <div className={classes.dismissInstructionsContainer}>
          <div className={classes.smallFlex}>
            <div className={classes.flexBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hideInstructionsUserSetting}
                    onClick={(e) => {
                      handleCheckboxClick(e);
                    }}
                  />
                }
                label={
                  <Typography className={classes.greyText}>
                    Don't show this message again.
                  </Typography>
                }
              />
            </div>

            {errorMsg ? (
              <FormHelperText error className={classes.errorText}>
                {errorMsg}
              </FormHelperText>
            ) : null}
          </div>

          <Button
            variant="outlined"
            aria-label="dismiss_diligence_instructions"
            data-test="dismiss_diligence_instructions"
            onClick={() => {
              setShouldNotDisplayInstructions(true);
            }}
          >
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  );
}
