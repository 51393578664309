import React, { useState, useEffect, useReducer } from 'react';
import { Modal, Typography, useConfirm } from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import { isValidDate } from 'components/date';
import { objectEquals } from 'services/utils';
import { DiligenceDocumentInputs } from 'components/lp_doc/diligence/subsection_question_display/documents_subsection/index';
import {
  expirationDatesReducer,
  expirationDateActions,
} from 'components/lp_doc/diligence/components/file_with_expiration_date';
import { useFeatureFlags } from 'services/providers/feature_flag';

function getDataToSubmit(localFileAnswers) {
  const optionalFileIds = Object.keys(localFileAnswers).reduce(
    (acc, fileKey) => {
      acc[fileKey] = Array.isArray(localFileAnswers[fileKey])
        ? localFileAnswers[fileKey]
            .filter((file) => file.fileId !== null)
            .map((file) => file.fileId)
        : localFileAnswers[fileKey]?.fileId;

      return acc;
    },
    {},
  );

  const hasAllFiles = !Object.values(optionalFileIds).includes(null);
  // if a user opted out of providing documents but the GP provides
  // all of them, we need to drop ui warnings tied to the optOut key
  // that indicates missing data by setting it to false

  if (hasAllFiles) {
    return { ...optionalFileIds, optOut: false };
  }

  return optionalFileIds;
}

function filterFileIds(fileData) {
  if (!fileData) {
    return {};
  }

  return Object.keys(fileData).reduce((acc, fileKey) => {
    acc[fileKey] = Array.isArray(fileData[fileKey])
      ? fileData[fileKey]
          .filter((file) => file.fileId !== null)
          .map((file) => file.fileId)
      : fileData[fileKey].fileId;

    return acc;
  }, {});
}

export function haveDocumentsChanged(initialFileData, newFileData) {
  // google generates new signed urls every time that we
  // reload data for this page, only compare file ids
  const initialFileIds = filterFileIds(initialFileData);
  const newFileIds = filterFileIds(newFileData);

  return !objectEquals(initialFileIds, newFileIds);
}

function getCleanedDates(docAnswers, filesWithDates) {
  // It is possible that filesWithDates is not initialized yet or is directly an array from the node reducer
  if (Array.isArray(filesWithDates) || !filesWithDates) {
    return filesWithDates;
  }
  return Object.keys(filesWithDates || {}).reduce((acc, fileKey) => {
    const fileAnswers = filterFileIds(docAnswers)[fileKey] || {};
    const fileIds = Array.isArray(fileAnswers) ? fileAnswers : [fileAnswers];

    return [
      ...acc,
      ...filesWithDates?.[fileKey]
        ?.filter(
          (fileWithDate) =>
            fileWithDate?.file !== null &&
            fileIds?.includes(fileWithDate.file) &&
            fileWithDate?.expirationDate !== null,
        )
        .map((fileWithDate) => ({
          file: fileWithDate?.file,
          expirationDate: fileWithDate?.expirationDate,
        })),
    ];
  }, []);
}

function haveDocumentsOrExpirationDatesChanged(
  initialDocAnswers,
  localDocAnswers,
  initialExpirationDates,
  newExpirationDates,
) {
  if (haveDocumentsChanged(initialDocAnswers, localDocAnswers)) {
    return true;
  }
  const initialDates = initialExpirationDates.map((fileWithDate) => ({
    file: fileWithDate.file.fileId || fileWithDate.file,
    expirationDate: fileWithDate.expirationDate,
  }));
  const newDates = getCleanedDates(localDocAnswers, newExpirationDates);
  return !objectEquals(initialDates, newDates);
}

export function EditDiligenceDocsModal({
  investorName,
  initialDocumentAnswers,
  initialExpirationDates,
  docInputData,
  open,
  onClose,
  onSubmit,
  jurisdiction,
}) {
  const { fundId } = useParams();
  const [localDocAnswers, setLocalDocAnswers] = useState(
    initialDocumentAnswers,
  );
  const [localExpirationDates, expirationDatesDispatch] = useReducer(
    expirationDatesReducer,
    initialExpirationDates,
  );
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  useEffect(() => {
    if (open) {
      expirationDatesDispatch({
        type: expirationDateActions.INITIALIZE,
        initialExpirationDates,
        documents: localDocAnswers,
      });
    }
  }, [initialExpirationDates, initialDocumentAnswers, open]);
  const { EXPIRATION_DATES } = useFeatureFlags();
  const haveAnswersChanged = EXPIRATION_DATES
    ? haveDocumentsOrExpirationDatesChanged(
        initialDocumentAnswers,
        localDocAnswers,
        initialExpirationDates,
        localExpirationDates,
      )
    : haveDocumentsChanged(initialDocumentAnswers, localDocAnswers);

  function handleChange(key, newFileData) {
    setLocalDocAnswers((lda) => ({
      ...lda,
      [key]:
        typeof newFileData === 'function' ? newFileData(lda[key]) : newFileData,
    }));
  }

  function checkExpirationDateErrorsPerDocumentType(docType) {
    const expirationDateErrors = localExpirationDates[docType]?.map(
      (fileWithDate) => ({
        ...fileWithDate,
        fileError:
          fileWithDate.file === null
            ? 'Upload a file or delete this document'
            : null,
        dateError:
          fileWithDate.expirationDate !== null &&
          !isValidDate(new Date(fileWithDate.expirationDate))
            ? 'Invalid date'
            : null,
      }),
    );
    return expirationDateErrors;
  }

  function checkExpirationDateErrors() {
    let hasErrors = false;
    const expirationDateErrors = Object.keys(localExpirationDates).reduce(
      (acc, docType) => {
        const errors = checkExpirationDateErrorsPerDocumentType(docType);
        acc[docType] = errors;
        if (
          errors.length > 1 &&
          errors.some((error) => error.fileError || error.dateError)
        ) {
          hasErrors = true;
        }
        return acc;
      },
      {},
    );

    if (hasErrors) {
      expirationDatesDispatch({
        type: expirationDateActions.SET_ERRORS,
        expirationDateErrors,
      });
    }
    return hasErrors;
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!haveAnswersChanged) {
          onClose();
          return;
        }

        confirm({
          title: 'Discard unsaved changes?',
          description: 'Your changes have not been saved.',
          destructive: 'true',
          confirmationText: 'Close',
          size: 'xs',
        })
          .then(() => {
            setLocalDocAnswers(initialDocumentAnswers);
            onClose();
          })
          .catch(() => {});
      }}
      headerLabel="Manage documents"
      showCancelButton
      primaryButtonText="Save changes"
      primaryButtonDisabled={!haveAnswersChanged}
      primaryButtonLoading={loading}
      onSubmit={() => {
        setLoading(true);
        if (EXPIRATION_DATES && checkExpirationDateErrors()) {
          setLoading(false);
          return;
        }
        onSubmit(
          getDataToSubmit(localDocAnswers),
          EXPIRATION_DATES
            ? getCleanedDates(localDocAnswers, localExpirationDates)
            : [],
        ).finally(() => {
          setLoading(false);
        });
      }}
    >
      <DiligenceDocumentInputs
        docFields={docInputData}
        form={localDocAnswers}
        onChange={handleChange}
        fileUploadUrl={`/api/fund/${fundId}/file/upload/`}
        investorName={investorName}
        enableNameChecks
        jurisdiction={jurisdiction}
        expirationDates={localExpirationDates}
        expirationDatesDispatch={expirationDatesDispatch}
      />

      <Typography variant="body2">
        All uploaded documents are visible to the investor but they will not be
        notified.
      </Typography>
    </Modal>
  );
}
