import React from 'react';
import { Menu, MenuItem, Icons, Typography } from '@passthrough/uikit';
import Tooltip from '@material-ui/core/Tooltip';
import UploadIcon from 'mdi-material-ui/Upload';

export function OfferingDocActionsMenu({
  document,
  anchorEl,
  menuOpen,
  handleClose,
  onDelete,
  onArchive,
  onUnarchive,
  onEdit,
  onReplace,
}) {
  return (
    <Menu
      id="menu-offering-doc-actions"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleClose}
    >
      {!document.isArchived ? (
        <MenuItem
          onClick={() => onArchive(document)}
          text="Archive document"
          icon={<Icons.ArchiveOutlined fontSize="small" color="primary" />}
        />
      ) : (
        <MenuItem
          onClick={() => onUnarchive(document)}
          icon={<Icons.RestoreOutlined fontSize="small" color="primary" />}
          text="Restore document"
        />
      )}

      {onReplace && document.hasPdfBoxes ? (
        <Tooltip
          title={
            <Typography>
              Documents with signatures cannot be replaced.
            </Typography>
          }
          placement="left"
          arrow
        >
          <div>
            <MenuItem
              onClick={() => {}}
              icon={<UploadIcon fontSize="small" color="primary" />}
              disabled
              text="Replace"
            />
          </div>
        </Tooltip>
      ) : null}

      {onReplace && !document.hasPdfBoxes ? (
        <MenuItem
          onClick={() => onReplace(document)}
          icon={<UploadIcon fontSize="small" color="primary" />}
          text="Replace"
        />
      ) : null}

      {onEdit ? (
        <MenuItem
          onClick={() => onEdit(document)}
          icon={<Icons.EditOutlined fontSize="small" color="primary" />}
          text="Rename"
        />
      ) : null}

      <MenuItem
        onClick={() => onDelete(document)}
        destructive
        icon={<Icons.DeleteOutline fontSize="small" />}
        text="Delete"
      />
    </Menu>
  );
}
