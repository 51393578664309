/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

function yearFormat(val) {
  return val.substring(0, 4);
}

function YearInput({ name, inputRef, onChange, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.formattedValue);
      }}
      allowNegative={false}
      format={yearFormat}
      placeholder="YYYY"
    />
  );
}

export function DateYearQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialDateYear = answer || '';
  const [dateYear, setDate] = useState(initialDateYear);
  const [errorMsg, setErrorMsg] = useState('');
  const isValidDate = String(dateYear).length === 4;
  const hasInput = Boolean(dateYear);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: dateYear,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setDate(e);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setDate(initialDateYear);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="dateYear"
          autoFocus
          label={frontendLabel}
          variant="outlined"
          value={dateYear}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          InputProps={{
            inputComponent: YearInput,
          }}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!isValidDate}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
