import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Icons } from '@passthrough/uikit';

import { TreeItem } from './tree_item';
import { addNameDataToReuseQuestions } from '../utils';
import { addSlices, getQuestionsAsNodes } from './node';
import { useErrorContext } from '../error_context';

const useStyles = makeStyles((theme) => ({
  treeRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    alignSelf: 'center',
    marginRight: theme.spacing(7),
  },
  list: {
    paddingLeft: (isGPSide) => (isGPSide ? theme.spacing(0) : theme.spacing(5)),
    listStyle: 'none',
  },
}));

export function DiligenceTreeNav({
  nav,
  jurisdiction,
  diligenceQuestions,
  selectedQuestionId,
  saving,
  isGPSide = false,
  hideBackButton = false,
  missingDiligenceDataErrors,
}) {
  const classes = useStyles(isGPSide);

  const formErrors = useErrorContext();

  if (!diligenceQuestions) {
    return null;
  }

  const namedDiligenceQuestions =
    addNameDataToReuseQuestions(diligenceQuestions);
  const rootNode = getQuestionsAsNodes(
    namedDiligenceQuestions[0],
    namedDiligenceQuestions,
    jurisdiction,
  );
  if (!rootNode) {
    return null;
  }

  addSlices(rootNode);

  return (
    <div className={classes.treeRoot}>
      {hideBackButton ? null : (
        <div className={classes.backButton}>
          <Button
            startIcon={<Icons.KeyboardArrowLeft />}
            variant="text"
            // clicking on back does not change the question view,
            // just changes the navigation
            onClick={() => nav.toRegularNav()}
          >
            Back
          </Button>
        </div>
      )}
      <ul className={classes.list}>
        {rootNode.getInPreOrder().map((child) => (
          <TreeItem
            key={child.question.label}
            jurisdiction={jurisdiction}
            node={child}
            diligenceQuestions={namedDiligenceQuestions}
            nav={nav}
            selectedQuestionId={selectedQuestionId}
            saving={saving}
            isGPSide={isGPSide}
            formErrors={{
              ...missingDiligenceDataErrors?.[child.question.label],
              ...formErrors?.[child.question.label],
            }}
          />
        ))}
      </ul>
    </div>
  );
}
