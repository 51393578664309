import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Table, TableCell, TableHead, TableRow } from 'components/table/index';
import { LPClosingStatusChip } from 'components/status';

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: '500',
  },
  input: {
    margin: theme.spacing(2, 0),
  },
  tableRow: {
    backgroundColor: theme.palette.primary.white,
  },
}));

const useTableCellStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  head: {
    backgroundColor: theme.palette.table.header,
  },
}));

export function InvestorReviewTable({ investors, noTopMargin }) {
  const classes = useStyles();
  const tableCellClasses = useTableCellStyles();

  return (
    <Table noTopMargin={noTopMargin}>
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell key="investor" classes={tableCellClasses}>
            Investor
          </TableCell>
          <TableCell key="status" classes={tableCellClasses}>
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      {investors.map((investor) => (
        <TableRow className={classes.tableRow} key={investor.id}>
          <TableCell key="investor" scope="row" classes={tableCellClasses}>
            {investor.lpName}
          </TableCell>
          <TableCell key="status" classes={tableCellClasses}>
            <LPClosingStatusChip lpClosing={investor} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
}
