import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import { Button } from 'components/button';
import { Alert } from 'components/alert';
import { OnlineRow, PrintRow } from './rows';
import { RequestReviewButton } from './request_review_button';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    minWidth: '600px',
    maxWidth: '800px',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  button: {
    display: 'flex',
    margin: theme.spacing(1),
  },
  helpText: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  requestReviewButton: {
    marginTop: theme.spacing(2),
  },
}));

export function DocumentList({
  lpClosingId,
  docs,
  signingIds,
  loadingId,
  getSignaturePage,
  allFullySigned,
  isLoading,
  isOfflineSigningEnabled,
  forceOfflineSigning,
  showRequestReview,
  onRequestReview,
  onComplete,
  onSigned,
  uploadSingleFile,
  needsRequestReview,
  allSignedOffline,
  isCompleted = false,
}) {
  const classes = useStyles();
  // Start in offline mode if:
  // - offline signing is forced
  // - all documents have been signed offline.
  const initialIsElectronic = !forceOfflineSigning && !allSignedOffline;
  const [isElectronic, setIsElectronic] = useState(initialIsElectronic);

  function getSignVerb(doc) {
    if (signingIds.includes(doc.id)) {
      return 'Signing';
    }
    if (doc.signed) {
      return 'Signed';
    }
    return 'Sign';
  }

  function confirmAndSubmit() {
    return isCompleted ? null : (
      <Collapse in={allFullySigned} appear={false}>
        <Button
          variant="contained"
          className={classes.button}
          data-test="complete"
          size="xl"
          disabled={!allFullySigned}
          loading={isLoading}
          onClick={onComplete}
        >
          Confirm & submit
        </Button>
      </Collapse>
    );
  }

  function printRow(doc) {
    return (
      <PrintRow
        lpClosingId={lpClosingId}
        doc={doc}
        onSigned={onSigned}
        buttonText={isElectronic ? getSignVerb(doc) : 'Upload signed copy'}
        uploadSingleFile={uploadSingleFile}
      />
    );
  }

  function onlineRow(doc) {
    return (
      <OnlineRow
        doc={doc}
        buttonText={getSignVerb(doc)}
        loading={doc.id === loadingId || signingIds.includes(doc.id)}
        disabled={!!loadingId}
        onClick={() => {
          getSignaturePage(doc);
        }}
      />
    );
  }

  function isSignedOffline(doc) {
    return doc.signed && doc.file?.fileId;
  }

  // Sanity check: is offline signing both forced and disabled?
  if (!isOfflineSigningEnabled && forceOfflineSigning) {
    return (
      <Alert severity="error" className={classes.alert}>
        Unable to sign documents. Please contact support at
        support@passthrough.com
      </Alert>
    );
  }

  if (needsRequestReview) {
    return (
      <RequestReviewButton
        className={classes.requestReviewButton}
        onRequestReview={onRequestReview}
        isLoading={isLoading}
      />
    );
  }

  if (isElectronic) {
    return (
      <>
        {docs.length > 0 ? (
          <Paper variant="outlined" className={classes.paper}>
            {docs.map((doc, index) => (
              <Fragment key={doc.id}>
                {isSignedOffline(doc) ? printRow(doc) : onlineRow(doc)}
                {index < docs.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </Paper>
        ) : (
          <div className={classes.marginBottom} />
        )}

        {confirmAndSubmit()}

        {isOfflineSigningEnabled && docs.length > 0 ? (
          <Button
            variant="text"
            className={classes.button}
            data-test="print"
            size="medium"
            onClick={() => {
              setIsElectronic(false);
            }}
          >
            Print & sign instead
          </Button>
        ) : null}
        {showRequestReview ? (
          <Button
            variant="text"
            size="medium"
            onClick={onRequestReview}
            loading={isLoading}
          >
            Request a review before signing
          </Button>
        ) : null}
      </>
    );
  }

  return (
    <>
      <Typography align="center" className={classes.helpText}>
        To sign offline, upload a signed copy of the document after collecting
        all signatures, including those of any additional signers.
      </Typography>
      <Paper variant="outlined" className={classes.paper}>
        {docs.map((doc, index) => (
          <Fragment key={doc.id}>
            {printRow(doc)}
            {index < docs.length - 1 ? <Divider /> : null}
          </Fragment>
        ))}
      </Paper>

      {confirmAndSubmit()}

      {forceOfflineSigning ? null : (
        <Button
          variant="text"
          className={classes.button}
          data-test="print"
          size="medium"
          onClick={() => {
            setIsElectronic(true);
          }}
        >
          Sign electronically instead
        </Button>
      )}
      {showRequestReview ? (
        <Button
          variant="text"
          size="medium"
          onClick={onRequestReview}
          loading={isLoading}
        >
          Request a review before signing
        </Button>
      ) : null}
    </>
  );
}
