import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import { CountryInput } from 'components/country';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1, 0),
  },
  helperText: {
    marginBottom: theme.spacing(1),
    marginTop: '-4px',
  },
  label: {
    textAlign: 'left',
  },
}));

export function CrsTaxDetailsQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const classes = useStyles();

  const initialCountry = (answer && answer.country) || '';
  const initialTaxType = (answer && answer.taxType) || '';
  const initialTaxNumber = (answer && answer.taxNumber) || '';
  const initialNotApplicable = (answer && answer.notApplicable) || false;

  const [country, setCountry] = useState(initialCountry);
  const [taxType, setTaxType] = useState(initialTaxType);
  const [taxNumber, setTaxNumber] = useState(initialTaxNumber);
  const [notApplicable, setNotApplicable] = useState(initialNotApplicable);

  const [countryError, setCountryError] = useState('');
  const [taxTypeError, setTaxTypeError] = useState('');
  const [taxNumberError, setTaxNumberError] = useState('');
  const [notApplicableError, setNotApplicableError] = useState('');

  const hasInput = Boolean(country) || Boolean(taxType) || Boolean(taxNumber);

  function handleSubmit(e) {
    e.preventDefault();

    setCountryError('');
    setTaxTypeError('');
    setTaxNumberError('');
    setNotApplicableError('');

    const props = {
      country,
      taxType,
      taxNumber,
      notApplicable,
    };

    return updateLpDoc({ label, answer: props, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setCountryError(error.response.data.answer.country);
          setTaxTypeError(error.response.data.answer.taxType);
          setTaxNumberError(error.response.data.answer.taxNumber);
        }
        return error;
      },
    );
  }

  function handleChange(e, field) {
    setSaving(UNSAVED);

    if (field === 'country') {
      if (e === null) {
        setCountry('');
        return;
      }
      setCountry(e.code);
    } else if (field === 'taxType') {
      setTaxType(e.target.value);
    } else if (field === 'taxNumber') {
      setTaxNumber(e.target.value);
    } else if (field === 'notApplicable') {
      setNotApplicable(e.target.checked);
      setTaxNumber('');
      setTaxType('');
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setCountry(initialCountry);
    setTaxType(initialTaxType);
    setTaxNumber(initialTaxNumber);
    setNotApplicable(initialNotApplicable);

    setCountryError('');
    setTaxTypeError('');
    setTaxNumberError('');
    setNotApplicableError('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent short />
      <Answers tall>
        <CountryInput
          className={classes.input}
          frontendLabel="Country"
          value={country}
          handleChange={(e, value) => {
            handleChange(value, 'country');
          }}
          errorMsg={countryError}
        />

        <Collapse in={!notApplicable} appear={false}>
          <TextField
            className={classes.input}
            id="taxType"
            label="Tax reference number type"
            variant="outlined"
            value={taxType}
            error={!!taxTypeError}
            helperText={taxTypeError}
            onChange={(e) => {
              handleChange(e, 'taxType');
            }}
            fullWidth
          />

          <FormHelperText className={classes.helperText}>
            For example "Social Insurance Number (SIN)" in Canada.
          </FormHelperText>

          <TextField
            className={classes.input}
            id="taxNumber"
            label="Tax reference number"
            variant="outlined"
            value={taxNumber}
            error={!!taxNumberError}
            helperText={taxNumberError}
            onChange={(e) => {
              handleChange(e, 'taxNumber');
            }}
            fullWidth
          />
        </Collapse>

        <FormControl error={!!notApplicableError} className={classes.input}>
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                checked={notApplicable}
                onChange={(e) => {
                  handleChange(e, 'notApplicable');
                }}
                name="not-applicable"
              />
            }
            label="This jurisdiction does not issue or I am unable to procure a tax reference number or functional equivalent."
          />
          <FormHelperText>{notApplicableError}</FormHelperText>
        </FormControl>

        <QuestionStepper handleSubmit={handleSubmit} hasInput={hasInput} />
      </Answers>
    </form>
  );
}
