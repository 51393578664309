import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import { Typography, Icons, Chip } from '@passthrough/uikit';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import { DiffStyle } from 'components/Diff';
import { getExpirationDate } from 'components/lp_doc/diligence/utils';
import { OpenDocumentButton } from 'components/document_upload/open_document_button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  icon: {
    width: theme.spacing(4),
    minWidth: 0,
    flex: '0 0 auto',
  },

  iconMargin: {
    marginTop: '4px',
  },

  text: {
    flex: 1,
    wordBreak: 'break-word',
    margin: 0,
  },
}));

export function DocumentCardEntry({
  type,
  fileData,
  handleOpenDocument,
  lastSubmittedAnswer,
  showExpirationDate = false,
  nodeDataSettled,
  expirationDates,
}) {
  const classes = useStyles();

  const dataWasSubmitted =
    Object.keys(fileData).length > 0 || lastSubmittedAnswer;
  const name = fileData?.fileName;
  const url = fileData?.fileUrl || lastSubmittedAnswer?.fileUrl;
  const isNew =
    Boolean(lastSubmittedAnswer) &&
    lastSubmittedAnswer?.fileId !== fileData?.fileId;
  const expirationDate = showExpirationDate
    ? getExpirationDate(fileData, expirationDates)?.expirationDate || null
    : null;

  return (
    <ListItem alignItems={dataWasSubmitted ? 'flex-start' : 'center'}>
      <ListItemIcon
        className={clsx(classes.icon, {
          [classes.iconMargin]: dataWasSubmitted,
        })}
      >
        <Icons.InsertDriveFileOutlined />
      </ListItemIcon>
      <ListItemText disableTypography className={classes.text}>
        {type ? (
          <Typography
            variant="body"
            size="medium"
            color={dataWasSubmitted ? 'text.primary' : 'text.secondary'}
          >
            {type}
          </Typography>
        ) : null}

        <div>
          {lastSubmittedAnswer?.fileName &&
          lastSubmittedAnswer?.fileName !== name ? (
            <DiffStyle isDeleted thin>
              <Typography variant="label" size="small" color="text.secondary">
                {lastSubmittedAnswer?.fileName}
              </Typography>
            </DiffStyle>
          ) : null}
          {dataWasSubmitted ? (
            <Typography variant="label" size="small">
              <DiffStyle isNew={isNew}>{name}</DiffStyle>
            </Typography>
          ) : null}
        </div>
        {showExpirationDate && Boolean(name) ? (
          <div>
            {nodeDataSettled ? (
              <Typography variant="label" size="small">
                {expirationDate
                  ? `Expires ${format(new Date(expirationDate), 'dd MMM yyyy')}`
                  : 'Expiration date not set'}
              </Typography>
            ) : (
              <Skeleton variant="text" width={150} />
            )}
          </div>
        ) : null}
      </ListItemText>
      {dataWasSubmitted ? (
        <OpenDocumentButton
          fileName={name}
          type={type}
          url={url}
          onClick={handleOpenDocument}
        />
      ) : (
        <Chip variant="neutral" size="small" label="Not provided" />
      )}
    </ListItem>
  );
}
