/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { CountryInput } from 'components/country';
import { DatePicker } from 'components/date';
import {
  Address,
  getDefaultEmptyAddress,
  objectEqualsEmptyAddress,
} from 'components/address_v2';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(2, 0),
  },
  helperText: {
    marginBottom: theme.spacing(1),
    marginTop: '-4px',
  },
  removeHelper: {
    marginBottom: `-${theme.spacing(1)}px`,
  },
  removeText: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noMargin: {
    margin: 0,
    padding: 0,
  },
}));

const formatDate = (date) => {
  try {
    return format(new Date(date), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

export function ContactWithNationalityQuestion({
  updateLpDoc,
  setSaving,
  answer: initialAnswer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const classes = useStyles();
  const [answer, setAnswer] = useState(initialAnswer);
  const [errors, setErrors] = useState(null);
  const { emptyAddress } = getDefaultEmptyAddress();
  const address =
    answer?.address ||
    answer?.googleAddress ||
    answer?.manualAddress ||
    emptyAddress;
  const fmtDateOfBirth = formatDate(answer?.dateOfBirth);

  const isEmpty = Boolean(
    !answer?.firstName &&
      !answer?.lastName &&
      !answer?.dateOfBirth &&
      !answer?.placeOfBirth &&
      !answer?.nationality &&
      objectEqualsEmptyAddress(address),
  );

  function handleSubmit(e) {
    e.preventDefault();

    const submittedAnswer = {
      firstName: answer?.firstName,
      lastName: answer?.lastName,
      placeOfBirth: answer?.placeOfBirth,
      nationality: answer?.nationality,
      dateOfBirth: fmtDateOfBirth,
    };

    submittedAnswer.manualAddress = address;

    return updateLpDoc({
      label,
      answer: isEmpty ? {} : submittedAnswer,
      skipQuestion: isEmpty,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        const { manualAddress, ...restErrors } = error.response.data.answer;
        setErrors({ address: { ...manualAddress }, ...restErrors });
      }
      return error;
    });
  }

  const handleEventChange = (field) => (e) => {
    setSaving(UNSAVED);
    setErrors((err) => ({ ...err, [field]: null }));
    setAnswer((a) => ({ ...a, [field]: e.target.value }));
  };

  const handleValueChange = (field) => (value) => {
    setSaving(UNSAVED);
    setErrors((err) => ({ ...err, [field]: null }));
    setAnswer((a) => ({ ...a, [field]: value }));
  };

  const handleCountry = (e, value) => {
    setSaving(UNSAVED);
    if (value) {
      setErrors((err) => ({ ...err, nationality: null }));
      setAnswer((a) => ({ ...a, nationality: value.code }));
    } else {
      setErrors((err) => ({ ...err, nationality: null }));
      setAnswer((a) => ({ ...a, nationality: null }));
    }
  };

  // Reset the input when we change questions.
  useEffect(() => {
    setAnswer(initialAnswer);
    setErrors(null);
  }, [label]);

  return (
    <form onSubmit={handleSubmit} autoComplete="chrome-off">
      <QuestionPromptComponent short />
      <Answers tall>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <TextField
              id="firstName"
              autoFocus
              label="First name"
              variant="outlined"
              value={answer?.firstName}
              error={!!errors?.firstName}
              helperText={errors?.firstName}
              onChange={handleEventChange('firstName')}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="lastName"
              label="Last name"
              variant="outlined"
              value={answer?.lastName}
              error={!!errors?.lastName}
              helperText={errors?.lastName}
              onChange={handleEventChange('lastName')}
              fullWidth
            />
          </Grid>
        </Grid>

        <div className={classes.input}>
          <DatePicker
            fullWidth
            date={answer?.dateOfBirth || null}
            setDate={handleValueChange('dateOfBirth')}
            label="Date of birth"
            error={!!errors?.dateOfBirth}
            helperText={errors?.dateOfBirth}
            disableFuture
          />
        </div>

        <div className={classes.input}>
          <TextField
            id="placeOfBirth"
            label="Place of birth"
            variant="outlined"
            value={answer?.placeOfBirth}
            error={!!errors?.placeOfBirth}
            helperText={errors?.placeOfBirth}
            onChange={handleEventChange('placeOfBirth')}
            fullWidth
          />
        </div>

        <div className={classes.input}>
          <CountryInput
            frontendLabel="Nationality"
            value={answer?.nationality}
            handleChange={handleCountry}
            errorMsg={errors?.nationality}
          />
        </div>

        <div className={classes.input}>
          <Address
            address={address}
            onChange={handleValueChange('address')}
            errors={errors?.address}
            label="Residential address"
          />
        </div>

        <QuestionStepper handleSubmit={handleSubmit} hasInput={!isEmpty} />
      </Answers>
    </form>
  );
}
