import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { reformatTzAwareTimes } from 'services/utils';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { Button } from 'components/button';
import { ThreadDisplay } from './thread_display';
import { ThreadHeader } from './thread_header';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  invertedIcon: {
    transform: 'rotate(180deg)',
  },
}));

export function CommentThreadDisplay({
  comments,
  questionId,
  isDiligenceQuestion,
  sendLPComment,
  className,
}) {
  const classes = useStyles();
  const [showAllComments, setShowAllComments] = useState(comments.length === 0);
  const { useSupport, lpCanComment } = useQuestionnaire();
  const { setMenuComponentProps } = useSupport();

  const formattedComments = reformatTzAwareTimes(comments);
  const numComments = formattedComments.length;
  const mostRecentComment = formattedComments[formattedComments.length - 1];
  const oldComments = formattedComments.slice(0, formattedComments.length - 1);

  const showOpenButton = lpCanComment || numComments > 1;

  useEffect(() => {
    setMenuComponentProps((prevProps) => ({
      ...prevProps,
      openExistingThread: () => {
        setShowAllComments(true);
      },
    }));

    return () => {
      setMenuComponentProps((prevProps) => ({
        ...prevProps,
        openExistingThread: null,
      }));
    };
  }, []);

  return (
    <div className={className}>
      <ThreadHeader
        numComments={numComments}
        ButtonComponent={
          showOpenButton ? (
            <Button
              onClick={() => {
                setShowAllComments((prev) => !prev);
              }}
              variant="text"
              endIcon={
                <KeyboardArrowDownIcon
                  className={clsx({ [classes.invertedIcon]: showAllComments })}
                />
              }
            >
              {lpCanComment ? 'View and add comment' : 'View comments'}
            </Button>
          ) : null
        }
      />

      <ThreadDisplay
        mostRecentComment={mostRecentComment}
        oldComments={oldComments}
        showAllComments={showAllComments}
        questionId={questionId}
        isDiligenceQuestion={isDiligenceQuestion}
        sendLPComment={sendLPComment}
      />
    </div>
  );
}
