import { LOGIC_ANSWER_TYPES } from '../constants';

export function newId(questions, prefix = 'Q') {
  if (questions.length === 0) return `${prefix}1`;
  return `${prefix}${
    1 + Math.max(...questions.map((q) => Number(q.id.slice(1)) || 0))
  }`;
}

export function newSectionId(sections) {
  if (sections.length === 0) return 'SECTION1';
  return `SECTION${
    1 + Math.max(...sections.map((q) => Number(q.id.slice(7)) || 0))
  }`;
}

export function createDuplicatedQuestion(oldQ, allQuestions) {
  const oldIdPrefix = oldQ?.id?.charAt(0) || 'Q';
  const newQuestionId = newId(allQuestions, oldIdPrefix);

  return {
    ...oldQ,
    id: newQuestionId,
    // to ensure that the publishing guardrails that protect against broken
    // dependencies don't error out, update choice IDs to use new question id
    choices: (oldQ.choices || []).map((oldChoice) => {
      const originalChoiceNum = oldChoice.id.split('-')[1];
      return { ...oldChoice, id: `${newQuestionId}-${originalChoiceNum}` };
    }),
    // update box ids to use the new question id
    hellosignBoxes: (oldQ.hellosignBoxes || []).map((oldBox) => {
      const originalNumber = oldBox.id.split('-')[1];
      const choice = oldBox.choice
        ? `${newQuestionId}-${oldBox.choice.split('-')[1]}`
        : oldBox.choice;
      return {
        ...oldBox,
        id: `${newQuestionId}-${originalNumber}`,
        label: newQuestionId,
        choice,
      };
    }),
  };
}

export function getReplacedQuestions(oldQ, newQ, allQuestions) {
  // Replaces any instance of oldQ in allQuestions with newQ
  // Also considers dependencies in allQuestions and updates those
  // references to oldQ.id
  const newQuestionId = newQ.id;

  const getUpdatedDep = (d) => {
    const updatedD = { ...d };
    if (d.questionId === oldQ.id) {
      updatedD.questionId = newQuestionId;
    }

    const hasMatchingChoice = d.choice && d.choice?.split('-')[0] === oldQ.id;
    if (hasMatchingChoice) {
      const originalChoiceNum = d.choice.split('-')[1];
      updatedD.choice = `${newQuestionId}-${originalChoiceNum}`;
    }

    return updatedD;
  };

  const getUpdatedDeps = (dependencies) =>
    dependencies.map((d) => {
      if (d.children) {
        return {
          ...d,
          children: getUpdatedDeps(d.children),
        };
      }
      return getUpdatedDep(d);
    });

  const getUpdatedHellosignBoxes = (boxes) =>
    boxes.map((b) => {
      if (b.deps?.length > 0) {
        return {
          ...b,
          deps: getUpdatedDeps(b.deps),
        };
      }
      return b;
    });

  const updatedQuestions = allQuestions.map((q) => {
    if (q.id === oldQ.id) {
      return newQ;
    }

    const updatedQuestion = { ...q };
    if (q.deps?.length > 0) {
      const updatedDeps = getUpdatedDeps(q.deps);
      updatedQuestion.deps = updatedDeps;
    }

    if (q.hellosignBoxes?.length > 0) {
      const updatedHellosignBoxes = getUpdatedHellosignBoxes(q.hellosignBoxes);
      updatedQuestion.hellosignBoxes = updatedHellosignBoxes;
    }

    return updatedQuestion;
  });

  return updatedQuestions;
}

/** Prepare questions to be imported, replacing ids when specified */
export const getQuestionsToImport = (
  pastedQuestions,
  questions,
  useOriginalIds = false,
) => {
  const importedQuestions = [];
  const sections = questions.filter((q) => q.isSection);
  const oldToNew = {};

  /* eslint-disable-next-line no-restricted-syntax */
  for (const item of pastedQuestions) {
    if (item.isSection) {
      const importId = useOriginalIds
        ? item.id
        : newSectionId([
            ...sections,
            ...importedQuestions.filter((it) => it.isSection),
          ]);

      importedQuestions.push({
        ...item,
        id: importId,
      });
    } else {
      let prefix = 'Q';

      if (item.isSignature) {
        prefix = 'S';
      } else if (item.isText) {
        prefix = 'T';
      }

      const importId = useOriginalIds
        ? item.id
        : newId([...questions, ...importedQuestions], prefix);

      const newItem = {
        ...item,
        id: importId,
        reviewed: false,
      };
      oldToNew[item.id] = newItem.id;
      importedQuestions.push(newItem);
    }
  }

  const excludeEmptyConditions = (deps) =>
    deps
      .filter((d) => d !== null)
      .filter((d) => !d?.children || d.children.length > 0);

  const updateMultiConditionDep = (dep) => {
    if (!dep.questionIds) {
      return null;
    }
    const newQuestionIds = dep.questionIds.map((id) => oldToNew[id]);
    if (newQuestionIds.some((id) => !id)) {
      return null;
    }
    const updatedDep = { ...dep };
    updatedDep.questionIds = newQuestionIds;
    return updatedDep;
  };

  const updateAutoFill = (updatedItem) => {
    const updatedAutoFill = { ...updatedItem.autoFill };

    if (updatedAutoFill.questionId) {
      updatedAutoFill.questionId = oldToNew[updatedAutoFill.questionId];
    }

    if (updatedAutoFill.questionIds) {
      updatedAutoFill.questionIds = updatedAutoFill.questionIds
        .map((id) => oldToNew[id])
        .filter((id) => id);
    }

    return updatedAutoFill;
  };

  const updateDeps = (deps) =>
    excludeEmptyConditions(
      deps.map((d) => {
        if (d.children) {
          return {
            ...d,
            children: updateDeps(d.children),
          };
        }

        if (d.type === 'multi_condition') {
          return updateMultiConditionDep(d);
        }

        if (!oldToNew[d.questionId]) {
          return null;
        }

        const updatedD = { ...d };

        updatedD.questionId = oldToNew[d.questionId];

        if (updatedD.choice) {
          const originalChoiceNum = d.choice.split('-')[1];
          updatedD.choice = `${updatedD.questionId}-${originalChoiceNum}`;
        }

        return updatedD;
      }),
    );

  const updateBoxes = (updatedItem) =>
    updatedItem.hellosignBoxes.map((b) => ({
      ...b,
      id: `${updatedItem.id}-${b.id.split('-')[1]}`,
      choice: b.choice ? `${updatedItem.id}-${b.choice.split('-')[1]}` : null,
      deps: updateDeps(b.deps || []),
      label: oldToNew[b.label] || b.label,
    }));

  const updateChoices = (updatedItem) =>
    updatedItem.choices.map((c) => ({
      ...c,
      id: `${updatedItem.id}-${c.id.split('-')[1]}`,
    }));

  const updateIds = (item) => {
    const updatedItem = { ...item };

    if (updatedItem.choices) {
      updatedItem.choices = updateChoices(updatedItem);
    }

    if (updatedItem.deps) {
      updatedItem.deps = updateDeps(updatedItem.deps);
    }

    if (updatedItem.hellosignBoxes) {
      updatedItem.hellosignBoxes = updateBoxes(updatedItem);
    }

    if (updatedItem.autoFill) {
      updatedItem.autoFill = updateAutoFill(updatedItem);
    }

    return updatedItem;
  };

  for (let i = 0; i < importedQuestions.length; i += 1) {
    importedQuestions[i] = updateIds(importedQuestions[i]);
  }

  return importedQuestions;
};

export const areSetsEqual = (setA, setB) =>
  setA.size === setB.size && [...setA].every((value) => setB.has(value));

export const getTagsForQuestion = (tagData) => {
  if (!tagData) {
    return [];
  }

  return Object.values(tagData.tags).flatMap((tags) =>
    tags.map((tag) => tag.tag),
  );
};

export function getRelevantQuestions(
  questions,
  questionIds,
  showThisAndFutureQuestions,
  logicTypes = LOGIC_ANSWER_TYPES,
) {
  let relevantQuestions = questions;
  if (!showThisAndFutureQuestions) {
    // Exclude current (or earliest selected if assigning logic in bulk)
    // question we're on and all questions after
    const sliceIndex = relevantQuestions.findIndex((q) =>
      questionIds.includes(q.id),
    );
    if (sliceIndex !== -1) {
      relevantQuestions = relevantQuestions.slice(0, sliceIndex);
    }
  }
  relevantQuestions = relevantQuestions.filter(
    (q) => logicTypes.includes(q.answerType) || q.sectionName,
  );
  return relevantQuestions;
}
