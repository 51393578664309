import React from 'react';
import { componentForQuestion } from 'components/lp_doc/component_for_question';
import { makeStyles } from '@material-ui/core/styles';
import { Navigator } from 'components/lp_doc/navigator';
import { QuestionStepper } from 'components/lp_doc/question_stepper';
import { Question } from 'components/lp_doc/question';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'block',
    padding: theme.spacing(1),
  },
  questionPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
    borderRadius: '16px',
    minHeight: '450px',
    width: '100%',
  },
}));

export const QuestionPreview = ({ item, noPaper }) => {
  const classes = useStyles();

  if (!item.answerType) {
    return "Can't preview";
  }

  function MockNavRef() {
    const nav = new Navigator({
      lpDoc: {
        sections: [],
        docs: [],
      },
      navState: { innerStep: 0, outerStep: 0 },
      setNavState: () => {},
      setSelectedQuestionId: () => {},
    });
    return { current: nav };
  }

  function QuestionStepperComponent({ ...props }) {
    return <QuestionStepper navRef={MockNavRef()} saving={false} {...props} />;
  }

  const Component = componentForQuestion({
    answerType: `AnswerType.${item.answerType}`,
  });

  const questionPrefixText = !item.isRequired ? '(Optional) ' : null;

  // TODO: This preview is different from what the LP sees in the
  // actual questionnaire. Ideally this would be refactored to be more
  // consistent with the real workflow instead of needing to hack the logic
  // to make look the same here.
  const pdfBoxes = item.hellosignBoxes.map((box) => {
    const { choice: choiceId, ...rest } = box;
    return { choiceId, ...rest };
  });
  const QuestionPromptComponent = ({
    short,
    addPrefixText = true,
    children,
  }) => (
    <Question
      links={item.links || []}
      prefixText={addPrefixText ? questionPrefixText : null}
      short={short}
      question={item.question}
      pdfBoxes={pdfBoxes}
    >
      {children}
    </Question>
  );

  if (noPaper) {
    return (
      <Component
        choices={item.choices.map((c) => ({
          id: c.id,
          text: c.text,
          exclusive: c.exclusive,
        }))}
        allowSelectAll={item.allowSelectAll}
        question={item.question}
        links={item.links}
        frontendLabel={item.frontendLabel}
        label={item.id}
        pdfBoxes={pdfBoxes}
        QuestionStepper={QuestionStepperComponent}
        setSaving={() => {}}
        updateLpDoc={() => new Promise((resolve) => resolve())}
        onAnswer={() => {}}
        lpDoc={{}}
        QuestionPromptComponent={QuestionPromptComponent}
      />
    );
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <Paper className={classes.questionPaper} variant="outlined">
          <Component
            choices={item.choices.map((c) => ({
              id: c.id,
              text: c.text,
              exclusive: c.exclusive,
            }))}
            allowSelectAll={item.allowSelectAll}
            question={item.question}
            links={item.links}
            frontendLabel={item.frontendLabel}
            label={item.id}
            pdfBoxes={pdfBoxes}
            QuestionStepper={QuestionStepperComponent}
            setSaving={() => {}}
            updateLpDoc={() => new Promise((resolve) => resolve())}
            onAnswer={() => {}}
            lpDoc={{}}
            QuestionPromptComponent={QuestionPromptComponent}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
