import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { Icons } from '@passthrough/uikit';
import { KeyValuePair } from 'components/key_value';

export function NameForm({
  firstName,
  setFirstName,
  firstNameError,
  lastName,
  setLastName,
  lastNameError,
}) {
  return (
    <KeyValuePair disableGutters icon={<Icons.PersonOutline />}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="firstName"
            type="text"
            fullWidth
            label="First name"
            autoComplete="fname"
            variant="outlined"
            value={firstName}
            error={!!firstNameError}
            helperText={firstNameError}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="lastName"
            type="text"
            fullWidth
            label="Last name"
            autoComplete="lname"
            variant="outlined"
            value={lastName}
            error={!!lastNameError}
            helperText={lastNameError}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </KeyValuePair>
  );
}
