import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DisplayLink } from 'components/link';
import { TaxIDInput } from 'components/taxid';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

export function TaxIDWithEscapeQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  frontendLabel,
  QuestionPromptComponent,
}) {
  const initialTaxId = answer || '';
  const [taxId, setTaxId] = useState(initialTaxId);
  const [errorMsg, setErrorMsg] = useState('');

  const isValid = !!taxId.match(/^\d\d-\d\d\d\d\d\d\d$/);

  const [showSmartInput, setShowSmartInput] = useState(!taxId || isValid);
  const hasInput = Boolean(taxId);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: taxId, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  function handleChange(e) {
    setSaving(UNSAVED);
    setTaxId(e.target.value);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setTaxId(initialTaxId);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        {showSmartInput ? (
          <>
            <TextField
              id="taxId"
              autoFocus
              label={frontendLabel || 'EIN'}
              variant="outlined"
              value={taxId}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={handleChange}
              InputProps={{
                inputComponent: TaxIDInput,
              }}
              fullWidth
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setShowSmartInput(false);
                }}
              >
                I have an SSN or other kind of Tax ID ›
              </DisplayLink>
            </FormHelperText>
          </>
        ) : (
          <>
            <TextField
              id="taxId"
              autoFocus
              label="Other Tax ID"
              variant="outlined"
              value={taxId}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={handleChange}
              fullWidth
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setShowSmartInput(true);
                }}
              >
                I have a U.S. EIN ›
              </DisplayLink>
            </FormHelperText>
          </>
        )}
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={(!isValid && showSmartInput) || (!taxId && !showSmartInput)}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
