import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { objectEquals } from 'services/utils';
import { getEmptyFile } from 'components/file_upload';
import {
  getDefaultEmptyAddress,
  isValidAddress,
  objectEqualsEmptyAddress,
} from 'components/address_v2';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleSlice3Icon from 'mdi-material-ui/CircleSlice3';
import MinusCircleIcon from 'mdi-material-ui/MinusCircle';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import {
  jurisdictions,
  TYPE_INDIVIDUAL,
  TYPE_LIMITED_PARTNERSHIP,
  TYPE_JOINT_ACCOUNT,
  TYPE_IRA,
  TYPE_PRIVATE_COMPANY,
  TYPE_LISTED_COMPANY,
  TYPE_LLC,
  TYPE_PRIVATE_TRUST,
  TYPE_PENSION_SCHEME,
  TYPE_CHARITY_OR_FOUNDATION,
  TYPE_SCHOOL,
  TYPE_S_CORP,
  TYPE_PARTNERSHIP,
  TYPE_OTHER_PRIVATE_CORPORATION,
  TYPE_SUBSIDIARY_OR_AFFILIATE,
  TYPE_SPOUSAL_TRUST,
  TYPE_OTHER_TRUST,
  TYPE_PENSION_PLAN,
  TYPE_NON_PROFIT,
  TYPE_SOLE_PROPRIETORSHIP,
  TYPE_REGULATED_FINANCIAL_INSTITUTION,
  TYPE_NOMINEE,
  TYPE_GOV_ENTITY,
  SUBSECTION_DETAILS,
  SUBSECTION_DOCUMENTS,
  SUBSECTION_OWNERS,
  genericDisplayDetailKeys,
  proofOfSourceOfFundsDocConfig,
  ownerTypes,
  JOINT_ACCOUNT_TYPES,
  TYPE_PRIVATE_CORPORATION,
  TYPE_PUBLIC_COMPANY,
  TYPE_TRUST,
  TYPE_NON_PROFIT_ENDOWMENT_OR_FOUNDATION,
  TYPE_PROOF_SOURCE_OF_FUNDS,
  TYPE_FOUNDATION,
  TYPE_PRIVATE_SCHOOL,
  TYPE_GOV_BODY,
  TYPE_INVESTMENT_FUND,
  TYPE_CLUBS,
  TYPE_PENSION_FUND,
  TYPE_FOREIGN_ENTITY,
  TYPE_SCHOOL_ENDOWMENT,
  MULTI_FILE_JURISDICTIONS,
  SOURCE_OF_WEALTH_JURISDICTIONS,
} from './constants';
import {
  getOwnerRequirementsForJurisdiction,
  getGroupedOwnerRequirementsForJurisdiction,
} from './type_to_requirements_config';

export function isOwnerType(field, answer = null) {
  if (answer) {
    return ownerTypes.includes(field) && Array.isArray(answer);
  }

  return ownerTypes.includes(field);
}

export function nodeReusesNameInItsOwners(answer, jurisdiction) {
  if (!answer.name || !answer.type) {
    return false;
  }

  const allOwnerTypes = getOwnerFields(answer.type, jurisdiction);

  return allOwnerTypes.reduce((acc, ownerType) => {
    const currOwnerAnswer = answer[ownerType.key];
    const currOwnerNames = currOwnerAnswer?.map((owner) => owner.name) || [];

    return acc || currOwnerNames.includes(answer.name);
  }, false);
}

export function addNameDataToReuseQuestions(diligenceQuestions) {
  for (let i = 0; i < diligenceQuestions.length; i += 1) {
    const question = diligenceQuestions[i];
    const answers = question?.answer;

    if (answers?.idToReuse) {
      const originalQuestion = diligenceQuestions.find(
        (q) => q.label === answers.idToReuse,
      );
      if (originalQuestion) {
        answers.name = originalQuestion?.answer?.name || '';
        answers.type = originalQuestion?.answer?.type || '';
      }
    }
  }

  return diligenceQuestions;
}

function findAllDependentNodesById(originalId, diligenceQuestions) {
  return diligenceQuestions.filter(
    (question) => question.answer?.idToReuse === originalId,
  );
}

function gatherAllDependentNodes(diligenceQuestions) {
  return diligenceQuestions.map((question) => {
    const { label } = question;

    const dependentQuestions = findAllDependentNodesById(
      label,
      diligenceQuestions,
    );
    const dependentLabels = dependentQuestions.map((q) => q.label);

    return { ...question, dependentLabels };
  });
}

function parseInitialAddress(answer) {
  const { emptyAddress } = getDefaultEmptyAddress();
  const initialAddress = {
    ...emptyAddress,
    ...(answer || {}),
  };

  return initialAddress;
}

export function getInitialUseSsnValue(jurisdiction) {
  switch (jurisdiction) {
    case jurisdictions.USA:
      return true;
    case jurisdictions.USA_V2:
      return true;
    case jurisdictions.CAYMAN:
      return false;
    case jurisdictions.CAYMAN_V2:
      return false;
    case jurisdictions.LUXEMBOURG:
      return false;
    case jurisdictions.SEI:
      return true;
    default:
      return true;
  }
}

export function buildInitialForm(
  answer,
  jurisdiction = jurisdictions.USA,
  legalName = '',
) {
  const usesMultifile = MULTI_FILE_JURISDICTIONS.includes(jurisdiction);
  // ?? is the nullish coalescing operator, which returns the right-hand side
  // operand if the left-hand side operand is null or undefined
  // used instead of || to avoid falsy values like false, 0 or '' being overwritten
  return {
    // Generic
    name: answer?.name ?? legalName ?? '',
    type: answer?.type ?? '',
    optOut: answer?.optOut ?? false,
    proofSourceOfFunds:
      answer?.proofSourceOfFunds ?? getEmptyFile(usesMultifile),
    sourceOfWealthDetails: answer?.sourceOfWealthDetails ?? '',
    sourceOfWealth: answer?.sourceOfWealth ?? [],

    idToReuse: answer?.idToReuse ?? null,

    // Individual
    dob: answer?.dob ?? null,
    ssn: answer?.ssn ?? '',
    nationality: answer?.nationality ?? '',
    useSsn: answer?.useSsn ?? getInitialUseSsnValue(jurisdiction),
    residentialAddress: parseInitialAddress(answer?.residentialAddress ?? {}),

    validId: answer?.validId ?? getEmptyFile(usesMultifile),
    proofOfAddress: answer?.proofOfAddress ?? getEmptyFile(usesMultifile),

    // Limited Partnership
    tin: answer?.tin ?? '',
    hqAddress: parseInitialAddress(answer?.hqAddress ?? {}),
    lpa: answer?.lpa ?? getEmptyFile(usesMultifile),
    cop: answer?.cop ?? getEmptyFile(usesMultifile),
    ownershipSchedule: answer?.ownershipSchedule ?? getEmptyFile(usesMultifile),
    partners: answer?.partners ?? [],
    gps: answer?.gps ?? [],

    // Joint Account
    sob: answer?.sob ?? getEmptyFile(usesMultifile),
    holders: answer?.holders ?? [],
    beneficiaries: answer?.beneficiaries ?? [],

    // IRA
    amlLetter: answer?.amlLetter ?? getEmptyFile(usesMultifile),

    // Private Company
    // tin already given
    // hqAddress already given
    coi: answer?.coi ?? getEmptyFile(usesMultifile),
    aoa: answer?.aoa ?? getEmptyFile(usesMultifile),
    registerOfDirectors:
      answer?.registerOfDirectors ?? getEmptyFile(usesMultifile),
    registerOfShareholders:
      answer?.registerOfShareholders ?? getEmptyFile(usesMultifile),
    directors: answer?.directors ?? [],
    beneficialOwners: answer?.beneficialOwners ?? [],

    // Listed Company
    // tin already given
    // hqAddress already given
    evidenceOfListing: answer?.evidenceOfListing ?? getEmptyFile(usesMultifile),
    auditedFinancials: answer?.auditedFinancials ?? getEmptyFile(usesMultifile),
    mostRecent10k: answer?.mostRecent10k ?? getEmptyFile(usesMultifile),
    letterFromExternalAuditor:
      answer?.letterFromExternalAuditor ?? getEmptyFile(usesMultifile),
    orgChart: answer?.orgChart ?? getEmptyFile(usesMultifile),
    ultimateBeneficialOwner: answer?.ultimateBeneficialOwner ?? [],
    ticker: answer?.ticker ?? '',

    // LLC
    // tin already given
    // hqAddress already given
    cof: answer?.cof ?? getEmptyFile(usesMultifile),

    operatingAgreement:
      answer?.operatingAgreement ?? getEmptyFile(usesMultifile),
    dom: answer?.dom ?? getEmptyFile(usesMultifile),
    // orgChart already given
    managingMembers: answer?.managingMembers ?? [],
    members: answer?.members ?? [],

    // Private Trust
    // tin already given
    trustType: answer?.trustType ?? null,
    trustDeed: answer?.trustDeed ?? getEmptyFile(usesMultifile),
    certificateOfTrust:
      answer?.certificateOfTrust ?? getEmptyFile(usesMultifile),
    scheduleOfTrustees:
      answer?.scheduleOfTrustees ?? getEmptyFile(usesMultifile),
    trustees: answer?.trustees ?? [],
    grantors: answer?.grantors ?? [],
    // beneficiaries already defined

    // Pension Scheme
    schemeAdminDetails: answer?.schemeAdminDetails ?? '',
    amlEntityDetails: answer?.amlEntityDetails ?? '',
    formationDocument: answer?.formationDocument ?? getEmptyFile(usesMultifile),
    // trustees already defined
    signatories: answer?.signatories ?? [],

    // Charity or Foundation
    // tin already given
    // hqAddress already given
    // formationDocument
    copoc: answer?.copoc ?? getEmptyFile(usesMultifile),
    coas: answer?.coas ?? getEmptyFile(usesMultifile),
    // auditedFinancials
    // letterFromExternalAuditor already defined
    // amlLetter already defined
    donorsOver10k: answer?.donorsOver10k ?? getEmptyFile(usesMultifile),
    // trustees already defined
    // directors already defined
    // signatories already defined
    // beneficiaries already defined
    recipients: answer?.recipients ?? [],

    // School
    // tin already given
    // hqAddress already given
    // formationDocument already defined
    confirmationOfOwnership:
      answer?.confirmationOfOwnership ?? getEmptyFile(usesMultifile),
    detailsOfMainOfficials:
      answer?.detailsOfMainOfficials ?? getEmptyFile(usesMultifile),
    scheduleOfMajorDonors:
      answer?.scheduleOfMajorDonors ?? getEmptyFile(usesMultifile),
    // orgChart already defined
    officials: answer?.officials ?? [],
    // beneficiaries already defined

    // S-corp
    // tin already given
    // hqAddress already given
    // coi already defined
    offeringMemo: answer?.offeringMemo ?? getEmptyFile(usesMultifile),
    // directors already defined
    // beneficialOwners already defined
    // registerOfDirectors already defined
    // registerOfShareholders already defined
    // auditedFinancials already defined
    // aoa already defined
    lps: answer?.lps ?? [],
    certCor: answer?.certCor ?? getEmptyFile(usesMultifile),
    certAfs: answer?.certAfs ?? getEmptyFile(usesMultifile),
    certLpa: answer?.certLpa ?? getEmptyFile(usesMultifile),
    certStructureChart:
      answer?.certStructureChart ?? getEmptyFile(usesMultifile),
    certShareholderRegistry:
      answer?.certShareholderRegistry ?? getEmptyFile(usesMultifile),
    certDirectorRegister:
      answer?.certDirectorRegister ?? getEmptyFile(usesMultifile),
    certAsl: answer?.certAsl ?? getEmptyFile(usesMultifile),
    certCoi: answer?.certCoi ?? getEmptyFile(usesMultifile),
    certAoa: answer?.certAoa ?? getEmptyFile(usesMultifile),
    certAoi: answer?.certAoi ?? getEmptyFile(usesMultifile),
    certCof: answer?.certCof ?? getEmptyFile(usesMultifile),
    certPartnershipAgreement:
      answer?.certPartnershipAgreement ?? getEmptyFile(usesMultifile),
    certTrustDeed: answer?.certTrustDeed ?? getEmptyFile(usesMultifile),
    certTrustAgreement:
      answer?.certTrustAgreement ?? getEmptyFile(usesMultifile),
    certAmlLetter: answer?.certAmlLetter ?? getEmptyFile(usesMultifile),
    certRegisterOfDirectors:
      answer?.certRegisterOfDirectors ?? getEmptyFile(usesMultifile),
    certRegisterOfShareholders:
      answer?.certRegisterOfShareholders ?? getEmptyFile(usesMultifile),
    certOrgChart: answer?.certOrgChart ?? getEmptyFile(usesMultifile),
    certOperatingAgreement:
      answer?.certOperatingAgreement ?? getEmptyFile(usesMultifile),
    certDom: answer?.certDom ?? getEmptyFile(usesMultifile),
    certScheduleOfTrustees:
      answer?.certScheduleOfTrustees ?? getEmptyFile(usesMultifile),
    certFormationDocument:
      answer?.certFormationDocument ?? getEmptyFile(usesMultifile),
    certScheduleOfMajorDonors:
      answer?.certScheduleOfMajorDonors ?? getEmptyFile(usesMultifile),
    certSob: answer?.certSob ?? getEmptyFile(usesMultifile),
    certCertificateOfTrust:
      answer?.certCertificateOfTrust ?? getEmptyFile(usesMultifile),
    certDonorsOver10k: answer?.certDonorsOver10k ?? getEmptyFile(usesMultifile),

    // USA Partnership
    // tin already given
    // hqAddress already given
    cor: answer?.cor ?? getEmptyFile(usesMultifile),
    partnershipAgreement:
      answer?.partnershipAgreement ?? getEmptyFile(usesMultifile),
    structureChart: answer?.structureChart ?? getEmptyFile(usesMultifile),
    asl: answer?.asl ?? getEmptyFile(usesMultifile),
    // partners already defined
    // beneficialOwners already defined
    // signatories already defined
    nonMinorBeneficiaries: answer?.nonMinorBeneficiaries ?? [],
    principals: answer?.principals ?? [],
    thirdPartyAdmin: answer?.thirdPartyAdmin ?? [],
    soleProprietor: answer?.soleProprietor ?? [],

    authorizedSignatories: answer?.authorizedSignatories ?? [],

    partnershipAgreementOrCopyOfBusinessLicenses:
      answer?.partnershipAgreementOrCopyOfBusinessLicenses ??
      getEmptyFile(usesMultifile),
    participantStatementReportOrQuarterlyAcct:
      answer?.participantStatementReportOrQuarterlyAcct ??
      getEmptyFile(usesMultifile),
    coiAoaOrCopyOfBusinessLicences:
      answer?.coiAoaOrCopyOfBusinessLicences ?? getEmptyFile(usesMultifile),
    aooOrOperatingAgreement:
      answer?.aooOrOperatingAgreement ?? getEmptyFile(usesMultifile),
    trustCertificateOrAgreement:
      answer?.trustCertificateOrAgreement ?? getEmptyFile(usesMultifile),
    irsLetterCofOrAoa: answer?.irsLetterCofOrAoa ?? getEmptyFile(usesMultifile),
    tinEin: answer?.tinEin ?? '',
    directorsSignatoriesControllingPersons:
      answer?.directorsSignatoriesControllingPersons ?? [],
    listAccountOwners: answer?.listAccountOwners ?? getEmptyFile(usesMultifile),
    participantStatementReport:
      answer?.participantStatementReport ?? getEmptyFile(usesMultifile),
    secFiling: answer?.secFiling ?? getEmptyFile(usesMultifile),
    trustAgreement: answer?.trustAgreement ?? getEmptyFile(usesMultifile),
    certificateOfIncumbency:
      answer?.certificateOfIncumbency ?? getEmptyFile(usesMultifile),
    statementOfFinancialCrimesCompliance:
      answer?.statementOfFinancialCrimesCompliance ??
      getEmptyFile(usesMultifile),
    pensionPlanDocument:
      answer?.pensionPlanDocument ?? getEmptyFile(usesMultifile),
    irsLetter: answer?.irsLetter ?? getEmptyFile(usesMultifile),
    copyOfBusinessLicenses:
      answer?.copyOfBusinessLicenses ?? getEmptyFile(usesMultifile),
    irsDeterminationLetter:
      answer?.irsDeterminationLetter ?? getEmptyFile(usesMultifile),
    governingTrustDoc: answer?.governingTrustDoc ?? getEmptyFile(usesMultifile),
    regulatoryAgencyForm:
      answer?.regulatoryAgencyForm ?? getEmptyFile(usesMultifile),
    beneficialOwnersList:
      answer?.beneficialOwnersList ?? getEmptyFile(usesMultifile),
    // LUXEMBOURG
    roc: answer?.roc ?? getEmptyFile(usesMultifile),
    aar: answer?.aar ?? getEmptyFile(usesMultifile),
    donorList: answer?.donorList ?? getEmptyFile(usesMultifile),
    comfortLetter: answer?.comfortLetter ?? getEmptyFile(usesMultifile),
    prospectus: answer?.prospectus ?? getEmptyFile(usesMultifile),
    proofOfExistence: answer?.proofOfExistence ?? getEmptyFile(usesMultifile),
    pee: answer?.pee ?? getEmptyFile(usesMultifile),
    fundRegulationStatus: answer?.fundRegulationStatus ?? '',
    fundManagerRegulationStatus: answer?.fundManagerRegulationStatus ?? '',
    ima: answer?.ima ?? getEmptyFile(usesMultifile),
    latestAar: answer?.latestAar ?? getEmptyFile(usesMultifile),
    initiator: answer?.initiator ?? [],
    counterparties: answer?.counterparties ?? [],
    originator: answer?.originator ?? [],
    directorsList: answer?.directorsList ?? getEmptyFile(usesMultifile),
    membersList: answer?.membersList ?? getEmptyFile(usesMultifile),
    settlors: answer?.settlors ?? [],
    managementCompany: answer?.managementCompany ?? [],
    // USA_V3
    countriesOfCitizenship: answer?.countriesOfCitizenship ?? [],
    managersControllingPersons: answer?.managersControllingPersons ?? [],
    listedCompany: answer?.listedCompany ?? [],
    managers: answer?.managers ?? [],
    grantorsSettlors: answer?.grantorsSettlors ?? [],
    cioCommitteeMembers: answer?.cioCommitteeMembers ?? [],
    principalOfficers: answer?.principalOfficers ?? [],
    iraAcctStatement: answer?.iraAcctStatement ?? getEmptyFile(usesMultifile),
    form990: answer?.form990 ?? getEmptyFile(usesMultifile),

    // Cayman V2
    protectors: answer?.protectors ?? [],
    enforcers: answer?.enforcers ?? [],
  };
}

// excludes document and owner data
export function getTypeDetailKeysToDisplay(
  type,
  jurisdiction = jurisdictions.CAYMAN,
  isRootNode = false,
) {
  let typeSpecificDisplayKeys = [];
  const tinAndHqKeys = ['tin', 'hqAddress'];
  const tinOrEinAndHq = ['tinEin', 'hqAddress'];

  switch (type) {
    case TYPE_INDIVIDUAL:
      // exclude useSsn as we do not display that value in the UI
      typeSpecificDisplayKeys = ['dob', 'ssn', 'residentialAddress'];
      if (jurisdiction === jurisdictions.SEI) {
        typeSpecificDisplayKeys.push('nationality');
      } else if (jurisdiction === jurisdictions.USA_V3) {
        typeSpecificDisplayKeys.push('countriesOfCitizenship');
      }
      break;
    case TYPE_LIMITED_PARTNERSHIP:
      if (jurisdiction === jurisdictions.SEI) {
        typeSpecificDisplayKeys = tinOrEinAndHq;
      } else {
        typeSpecificDisplayKeys = tinAndHqKeys;
      }
      break;
    case TYPE_JOINT_ACCOUNT:
      typeSpecificDisplayKeys = [];
      break;
    case TYPE_IRA:
      typeSpecificDisplayKeys = [];
      break;
    case TYPE_PRIVATE_COMPANY:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_PUBLIC_COMPANY:
      typeSpecificDisplayKeys = [...tinOrEinAndHq, 'ticker'];
      break;
    case TYPE_LISTED_COMPANY:
      if (
        jurisdiction === jurisdictions.USA_V2 ||
        jurisdiction === jurisdictions.USA_V3
      ) {
        typeSpecificDisplayKeys = tinOrEinAndHq;
      } else if (jurisdiction === jurisdictions.LUXEMBOURG) {
        typeSpecificDisplayKeys = ['hqAddress'];
      } else {
        typeSpecificDisplayKeys = tinAndHqKeys;
      }

      break;
    case TYPE_LLC:
      if (
        jurisdiction === jurisdictions.USA_V2 ||
        jurisdiction === jurisdictions.USA_V3
      ) {
        typeSpecificDisplayKeys = tinOrEinAndHq;
      } else {
        typeSpecificDisplayKeys = tinAndHqKeys;
      }

      break;
    case TYPE_PRIVATE_TRUST:
      typeSpecificDisplayKeys = ['tin', 'trustType'];
      break;
    case TYPE_PENSION_SCHEME:
      typeSpecificDisplayKeys = ['schemeAdminDetails', 'amlEntityDetails'];
      break;
    case TYPE_CHARITY_OR_FOUNDATION:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_SCHOOL:
    case TYPE_SCHOOL_ENDOWMENT:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_S_CORP:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_PARTNERSHIP:
      if (
        jurisdiction === jurisdictions.USA_V2 ||
        jurisdiction === jurisdictions.USA_V3
      ) {
        typeSpecificDisplayKeys = tinOrEinAndHq;
      } else {
        typeSpecificDisplayKeys = tinAndHqKeys;
      }

      break;
    case TYPE_PRIVATE_CORPORATION:
      if (jurisdiction === jurisdictions.USA_V3) {
        typeSpecificDisplayKeys = tinOrEinAndHq;
      } else {
        typeSpecificDisplayKeys = tinAndHqKeys;
      }
      break;
    case TYPE_OTHER_PRIVATE_CORPORATION:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_SUBSIDIARY_OR_AFFILIATE:
      typeSpecificDisplayKeys = tinOrEinAndHq;
      break;
    case TYPE_SPOUSAL_TRUST:
      typeSpecificDisplayKeys = ['tin'];
      break;
    case TYPE_TRUST:
      if (jurisdiction === jurisdictions.USA_V3) {
        typeSpecificDisplayKeys = ['tin'];
      } else {
        typeSpecificDisplayKeys = tinAndHqKeys;
      }
      break;
    case TYPE_OTHER_TRUST:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_PENSION_PLAN:
      if (jurisdiction === jurisdictions.SEI) {
        typeSpecificDisplayKeys = tinAndHqKeys;
      } else {
        typeSpecificDisplayKeys = ['hqAddress'];
      }

      break;
    case TYPE_NON_PROFIT:
      if (jurisdiction === jurisdictions.LUXEMBOURG) {
        typeSpecificDisplayKeys = tinAndHqKeys;
      } else {
        typeSpecificDisplayKeys = tinOrEinAndHq;
      }
      break;
    case TYPE_NON_PROFIT_ENDOWMENT_OR_FOUNDATION:
      typeSpecificDisplayKeys = tinOrEinAndHq;
      break;
    case TYPE_SOLE_PROPRIETORSHIP:
      typeSpecificDisplayKeys = ['ssn', 'hqAddress'];
      break;
    case TYPE_REGULATED_FINANCIAL_INSTITUTION:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_NOMINEE:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_GOV_ENTITY:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_FOUNDATION:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_PRIVATE_SCHOOL:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_GOV_BODY:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_INVESTMENT_FUND:
      typeSpecificDisplayKeys = [
        ...tinAndHqKeys,
        'fundRegulationStatus',
        'fundManagerRegulationStatus',
      ];
      break;
    case TYPE_CLUBS:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_PENSION_FUND:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    case TYPE_FOREIGN_ENTITY:
      typeSpecificDisplayKeys = tinAndHqKeys;
      break;
    default:
      typeSpecificDisplayKeys = [];
      break;
  }

  const config = getOwnerRequirementsForJurisdiction(jurisdiction);
  const universalKeys = [...genericDisplayDetailKeys];
  if (
    SOURCE_OF_WEALTH_JURISDICTIONS.includes(jurisdiction) &&
    isRootNode &&
    !config[type]?.disableSourceOfWealth
  ) {
    universalKeys.push('sourceOfWealth', 'sourceOfWealthDetails');
  }

  return universalKeys.concat(typeSpecificDisplayKeys);
}

export function getDetailKeysForAdminEditing(
  type,
  jurisdiction = jurisdictions.CAYMAN,
  isRootNode = false,
) {
  const typeKeys = getTypeDetailKeysToDisplay(type, jurisdiction, isRootNode);

  if (typeKeys.includes('ssn')) {
    typeKeys.push('useSsn');
  }

  return typeKeys;
}

export function getDocumentConfigsForKeys(type, docKeys, jurisdiction) {
  const relevantJurisdictionData =
    getOwnerRequirementsForJurisdiction(jurisdiction);

  const allDocFields = relevantJurisdictionData[type]?.documents || [];
  const disableSourceOfWealth =
    relevantJurisdictionData[type]?.disableSourceOfWealth;
  const relevantDocFields = allDocFields.filter((docField) =>
    docKeys.includes(docField.key),
  );

  if (!disableSourceOfWealth && docKeys.includes(TYPE_PROOF_SOURCE_OF_FUNDS)) {
    relevantDocFields.push(proofOfSourceOfFundsDocConfig);
  }

  return relevantDocFields;
}

export function getDocumentFields(
  type,
  jurisdiction = jurisdictions.USA,
  isRootNode = false,
) {
  if (!type) {
    return [];
  }

  const relevantJurisdictionData =
    getOwnerRequirementsForJurisdiction(jurisdiction);
  // copy data here to ensure that the conditional push doesn't modify
  // underlying config data
  const docFields = relevantJurisdictionData[type]?.documents || [];
  const disableSourceOfWealth =
    relevantJurisdictionData[type]?.disableSourceOfWealth;
  const docFieldsCopy = [...docFields];

  if (
    !disableSourceOfWealth &&
    isRootNode &&
    SOURCE_OF_WEALTH_JURISDICTIONS.includes(jurisdiction)
  ) {
    docFieldsCopy.push(proofOfSourceOfFundsDocConfig);
  }

  return docFieldsCopy;
}

export function getOwnerFields(type, jurisdiction = jurisdictions.USA) {
  if (type) {
    const relevantJurisdictionData =
      getOwnerRequirementsForJurisdiction(jurisdiction);
    return relevantJurisdictionData[type]?.owners || [];
  }

  return [];
}

export function buildMinOwnerRequirementAlerts(ownerFieldData) {
  if (!ownerFieldData || ownerFieldData.length === 0) {
    return [];
  }

  const requirements = ownerFieldData.reduce((acc, ownerData) => {
    if (ownerData?.minNum) {
      acc[ownerData.key] = [
        `You must provide at least ${
          ownerData.minNum
        } names for ${ownerData.pluralLabel?.toLowerCase()}.`,
      ];
    }

    return acc;
  }, {});

  return requirements;
}

function addDocs(props, form, isRootNode, jurisdiction) {
  const propsWithDocs = { ...props };

  const docs = getDocumentFields(props.type, jurisdiction, isRootNode);

  if (docs.length) {
    docs.forEach((docConfig) => {
      propsWithDocs[docConfig.key] = Array.isArray(form[docConfig.key])
        ? form[docConfig.key].map((file) => file?.fileId)
        : form[docConfig.key]?.fileId;
    });
  }

  return propsWithDocs;
}

function addOwners(props, form, jurisdiction) {
  const propsWithOwners = { ...props };

  const owners = getOwnerFields(props.type, jurisdiction);

  if (owners.length) {
    owners.forEach((ownerConfig) => {
      propsWithOwners[ownerConfig.key] = form[ownerConfig.key];
    });
  }

  return propsWithOwners;
}

export function filterEmptyDataFields(diligenceData) {
  return Object.keys(diligenceData).reduce((acc, key) => {
    const value = diligenceData[key];

    // allow invalid date objects to remain in the data to be submitted,
    // they will be turned into null when calling the object's tojson() method
    // and then the backend will return a custom error msg
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      objectEqualsEmptyAddress(value)
    ) {
      return acc;
    }

    acc[key] = value;
    return acc;
  }, {});
}

export function parseRelevantProps(
  form,
  isRootNode,
  jurisdiction = jurisdictions.USA,
) {
  if (form.idToReuse) {
    return {
      idToReuse: form.idToReuse,
    };
  }

  const props = {
    name: form.name,
    type: form.type,
    optOut: form.optOut,
  };

  const detailKeys = getTypeDetailKeysToDisplay(
    form.type,
    jurisdiction,
    isRootNode,
  );

  detailKeys.forEach((key) => {
    props[key] = form[key];
  });

  if (detailKeys.includes('ssn')) {
    // useSSN is a supporting piece of answer state that should not be displayed,
    // but which is used if ssn validation should be applied against the
    // value submitted by the user
    props.useSsn = form.useSsn;
  }

  const propsWithDocs = addDocs(props, form, isRootNode, jurisdiction);
  const propsWithAllData = addOwners(propsWithDocs, form, jurisdiction);

  // Filter out any fields that were not filled in so the backend doesn't
  // validate them.
  return filterEmptyDataFields(propsWithAllData);
}

const useStyles = makeStyles((theme) => ({
  greenBackground: {
    fill: theme.palette.success.main,
  },
  optOut: {
    fill: theme.palette.warning.main,
  },
  error: {
    fill: theme.palette.error.main,
  },
}));

function getIcon({ inProgress, isComplete, optOut, hasErrors }) {
  const classes = useStyles();
  if (hasErrors) {
    return <AlertCircle className={classes.error} />;
  }
  if (optOut) {
    return <MinusCircleIcon className={classes.optOut} />;
  }
  if (isComplete) {
    return <CheckCircleIcon className={classes.greenBackground} />;
  }
  if (inProgress) {
    return <CircleSlice3Icon />;
  }
  return <RadioButtonUncheckedIcon />;
}

function areAdditionalDetailsComplete(form, jurisdiction, isRootNode) {
  const requiredDataKeys = getTypeDetailKeysToDisplay(
    form.type,
    jurisdiction,
    isRootNode,
  );

  return requiredDataKeys.every((key) => {
    if (key === 'hqAddress' || key === 'residentialAddress') {
      return Boolean(form[key]) && isValidAddress(form[key]);
    }
    if (key === 'sourceOfWealth') {
      return Boolean(form.sourceOfWealth?.length > 0);
    }
    if (key === 'sourceOfWealthDetails') {
      // sourceOfWealthDetails is always optional
      return !form.sourceOfWealth.includes('Other') || Boolean(form[key]);
    }
    return Boolean(form[key]);
  });
}

export function isDetailsSectionComplete(
  form,
  jurisdiction = jurisdictions.USA,
  isRootNode = false,
) {
  return (
    Boolean(form.name) &&
    Boolean(form.type) &&
    areAdditionalDetailsComplete(form, jurisdiction, isRootNode)
  );
}

export function isDetailsSectionInProgress(form, isComplete) {
  // NOTE - each nested node will initialize with details section
  // in progress as it will pull name from parent node, root node
  // will only display subsection ui after a type is selected
  const started = Boolean(form.name) || Boolean(form.type);
  return started && !isComplete;
}

function hasDetailsErrors(form, jurisdiction, isRootNode, formErrors) {
  const requiredDataKeys = getTypeDetailKeysToDisplay(
    form.type,
    jurisdiction,
    isRootNode,
  );

  return (
    formErrors?.name ||
    formErrors?.type ||
    requiredDataKeys.some((key) => Boolean(formErrors[key]))
  );
}

export function getDetailsIcon(form, jurisdiction, isRootNode, formErrors) {
  // as we display the name input from the details section even if reusing
  // data, we can simply check if that value is provided for visual completeness
  const isComplete =
    isDetailsSectionComplete(form, jurisdiction, isRootNode) || form.idToReuse;
  const inProgress = isDetailsSectionInProgress(form, isComplete);
  const hasErrors = hasDetailsErrors(
    form,
    jurisdiction,
    isRootNode,
    formErrors,
  );
  return getIcon({ inProgress, isComplete, hasErrors });
}

export function isDocumentsSectionComplete(form, docFields) {
  const emptyFileData = getEmptyFile();

  const requiredDocFields = docFields.filter((docField) => !docField.optional);

  return requiredDocFields.every(
    (docData) =>
      Boolean(form[docData.key]) &&
      !objectEquals(form[docData.key], emptyFileData) &&
      !(Array.isArray(form[docData.key]) && form[docData.key].length === 0),
  );
}

export function isDocumentsSectionInProgress(form, docFields, isComplete) {
  const emptyFileData = getEmptyFile();

  const atLeastOneFileSubmitted = docFields.some(
    (docData) =>
      Boolean(form[docData.key]) &&
      !objectEquals(form[docData.key], emptyFileData),
  );

  return atLeastOneFileSubmitted && !isComplete;
}

function hasDocumentErrors(formErrors, docFields) {
  return docFields.some((docData) => formErrors?.[docData.key]);
}

export function getDocumentsIcon(
  form,
  type,
  jurisdiction,
  isRootNode,
  formErrors,
) {
  const docFields = getDocumentFields(type, jurisdiction, isRootNode);

  const isComplete = isDocumentsSectionComplete(form, docFields);
  const inProgress = isDocumentsSectionInProgress(form, docFields, isComplete);
  const hasErrors = hasDocumentErrors(formErrors, docFields);

  return getIcon({ inProgress, isComplete, optOut: form.optOut, hasErrors });
}

export function isOwnersSectionInProgress(form, ownerFields, isComplete) {
  const atLeastOneOwnerGiven = ownerFields.some(
    (ownerData) => form[ownerData.key]?.length > 0,
  );
  return atLeastOneOwnerGiven && !isComplete;
}

export function hasOwnerDataForEachCategory(form, ownerFields) {
  const needsNoOwners = ownerFields.length === 0;
  const isJointAccount = JOINT_ACCOUNT_TYPES.includes(form?.type);

  const meetsMinNumOwnerRequirements = ownerFields.every((ownerData) => {
    if (isJointAccount && ownerData.key === 'holders') {
      return form?.holders?.length >= 2;
    }

    return form?.[ownerData.key]?.length > 0;
  });

  return needsNoOwners || meetsMinNumOwnerRequirements;
}

export function isOwnersSectionComplete(form, ownerFields) {
  const isJointAccount = JOINT_ACCOUNT_TYPES.includes(form?.type);
  if (isJointAccount) {
    return form?.holders?.length >= 2;
  }

  const needsNoOwners = ownerFields.length === 0;
  const hasAtLeastOneOwner = ownerFields.some(
    (ownerData) => form[ownerData.key]?.length > 0,
  );

  return needsNoOwners || hasAtLeastOneOwner;
}

export const ALL_OWNERS_KEY = 'allOwners';

export function hasOwnerErrors(formErrors, ownerFields) {
  return Boolean(
    formErrors?.[ALL_OWNERS_KEY] ||
      ownerFields?.some((owner) => formErrors?.[owner.key]),
  );
}

export function getOwnersIcon(form, type, jurisdiction, formErrors) {
  const ownerFields = getOwnerFields(type, jurisdiction);

  const isComplete = isOwnersSectionComplete(form, ownerFields);
  const inProgress = isOwnersSectionInProgress(form, ownerFields, isComplete);
  const hasErrors = hasOwnerErrors(formErrors, ownerFields);

  return getIcon({ inProgress, isComplete, hasErrors });
}

export function getFirstIncompleteSubsection(form, jurisdiction, isRootNode) {
  if (!form.type) {
    return SUBSECTION_DETAILS;
  }

  const docsData = getDocumentFields(form.type, jurisdiction, isRootNode);
  const ownerFields = getOwnerFields(form.type, jurisdiction);
  const detailsComplete = isDetailsSectionComplete(
    form,
    jurisdiction,
    isRootNode,
  );
  const documentsComplete = isDocumentsSectionComplete(form, docsData);
  const ownersComplete = isOwnersSectionComplete(form, ownerFields);

  if (!detailsComplete) {
    return SUBSECTION_DETAILS;
  }
  if (ownerFields.length > 0 && !ownersComplete) {
    return SUBSECTION_OWNERS;
  }
  if (docsData.length > 0 && !documentsComplete) {
    return SUBSECTION_DOCUMENTS;
  }
  return SUBSECTION_DETAILS;
}

function getSubsectionComplete(form, section, jurisdiction, isRootNode) {
  if (section === SUBSECTION_DETAILS) {
    return isDetailsSectionComplete(form, jurisdiction, isRootNode);
  }
  if (section === SUBSECTION_DOCUMENTS) {
    const docsData = getDocumentFields(form.type, jurisdiction, isRootNode);
    return isDocumentsSectionComplete(form, docsData);
  }
  if (section === SUBSECTION_OWNERS) {
    const ownerFields = getOwnerFields(form.type, jurisdiction);
    return isOwnersSectionComplete(form, ownerFields);
  }

  return false;
}

function getApplicableSubsections(form, jurisdiction, isRootNode) {
  // Get all relevant subsections by form type in order
  // example: ["DETAILS", "DOCUMENTS"] or ["DETAILS", "OWNERS"]
  const applicableSubsections = [SUBSECTION_DETAILS]; // Every form has details
  if (!form?.type) {
    return applicableSubsections;
  }

  const docsData = getDocumentFields(form.type, jurisdiction, isRootNode);
  const ownerFields = getOwnerFields(form.type, jurisdiction);
  if (ownerFields.length > 0) {
    applicableSubsections.push(SUBSECTION_OWNERS);
  }
  if (docsData.length > 0) {
    applicableSubsections.push(SUBSECTION_DOCUMENTS);
  }
  return applicableSubsections;
}

export function getNextIncompleteSubsection(
  form,
  currentSubsection,
  jurisdiction,
  isRootNode,
) {
  const applicableSections = getApplicableSubsections(
    form,
    jurisdiction,
    isRootNode,
  );
  const onLastSubsection =
    currentSubsection === applicableSections[applicableSections.length - 1];
  if (onLastSubsection) {
    return null;
  }

  /*
  Go through all subsections for this node, if we hit a section that's incomplete
  that's past where we are right now, we go to it. Otherwise we return null to indicate
  no incomplete subsection to go to next.
  */
  const currIndex = applicableSections.findIndex(
    (s) => s === currentSubsection,
  );
  /* eslint-disable-next-line no-restricted-syntax */
  for (const [index, section] of applicableSections.entries()) {
    if (form?.optOut && section === SUBSECTION_DOCUMENTS) {
      // users can only opt out of providing documents, so skip over that section

      /* eslint-disable-next-line no-continue */
      continue;
    }

    if (
      index > currIndex &&
      !getSubsectionComplete(form, section, jurisdiction, isRootNode)
    ) {
      return section;
    }
  }

  return null;
}

export const goToSubsection = (
  subsection,
  goToDetails,
  goToDocuments,
  goToOwners,
) => {
  if (subsection === 'DETAILS') {
    goToDetails();
  } else if (subsection === 'DOCUMENTS') {
    goToDocuments();
  } else if (subsection === 'OWNERS') {
    goToOwners();
  }
};

export const hasAdditionalDataBeenProvided = (answers, keysToIgnore = []) => {
  if (!answers) {
    return false;
  }

  const emptyFile = getEmptyFile();

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(answers)) {
    if (keysToIgnore.includes(key)) {
      /* eslint-disable no-continue */
      continue;
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        return true;
      }
    } else if (
      Boolean(value) &&
      !objectEquals(emptyFile, value) &&
      !objectEqualsEmptyAddress(value)
    ) {
      return true;
    }
  }
  return false;
};

export const questionHasAdditionalDataThatWillBeLost = (
  allDiligenceQuestions,
  tgtId,
) => {
  const questionToAlter = allDiligenceQuestions.find(
    (question) => question.label === tgtId,
  );

  if (!questionToAlter) {
    return false;
  }

  const answersToReference = questionToAlter?.answer;

  if (!answersToReference) {
    return false;
  }

  // as both a diligence node's name and type are set
  // by its parent before the child is even created,
  // we don't view those key values as additional data
  // that was provided by filling out that node
  return hasAdditionalDataBeenProvided(answersToReference, ['name', 'type']);
};

export function preorderDiligenceAnswerTreeTraversal(
  diligenceQuestions,
  jurisdiction = jurisdictions.USA,
) {
  // using preorder traversal to determine node ordering as this
  // traversal order corresponds to the vertical layout
  // of diligence nodes in the frontend's ui
  const rootDiligenceId = 'QD1';
  const preorderTraversalIds = [];

  const rootQuestion = diligenceQuestions.find(
    (question) => question.label === rootDiligenceId,
  );

  if (!rootQuestion) {
    return [];
  }

  const stack = [];
  stack.push(rootQuestion);

  while (stack.length > 0) {
    const currQuestion = stack.pop();

    preorderTraversalIds.push(currQuestion.label);

    const answers = currQuestion?.answer;
    const type = answers?.type;

    const sortedOwners = getOwnerFields(type, jurisdiction);
    const sortedOwnerKeys = sortedOwners.map((ownerData) => ownerData.key);
    const reversedOwnerKeys = [...sortedOwnerKeys].reverse();

    for (let i = 0; i < reversedOwnerKeys.length; i += 1) {
      const currOwnerKey = reversedOwnerKeys[i];
      const currOwnerTypeList = answers?.[currOwnerKey] || [];
      const reversedOwnerTypeList = [...currOwnerTypeList].reverse();

      for (let j = 0; j < reversedOwnerTypeList.length; j += 1) {
        const currOwnerId = reversedOwnerTypeList[j].id;
        const currOwner = diligenceQuestions.find(
          (question) => question.label === currOwnerId,
        );
        if (currOwner) {
          stack.push(currOwner);
        }
      }
    }
  }

  return preorderTraversalIds;
}

export function getNonAncestorNodes(
  structuredNodesRoot,
  diligenceQuestions,
  jurisdiction,
  parentId,
) {
  const preorderDiligenceIds = preorderDiligenceAnswerTreeTraversal(
    diligenceQuestions,
    jurisdiction,
  );

  const preorderNodes = preorderDiligenceIds.map((id) =>
    structuredNodesRoot?.findNodeByLabel(id),
  );

  return preorderNodes?.filter((node) => {
    if (node === undefined || node === null) {
      return false;
    }
    const descendants = node?.getAllChildren();
    return (
      node?.question?.label !== parentId &&
      !descendants?.some(
        (descendant) => descendant?.question?.label === parentId,
      )
    );
  });
}

export function findOriginalQuestionDataPerCleanedName(
  preorderDiligenceIds,
  diligenceQuestions,
  label,
  cleanedIncomingName,
) {
  const seenNames = new Set();
  const firstDiligenceQuestionPerCleanedName = {};

  for (let i = 0; i < preorderDiligenceIds.length; i += 1) {
    const currId = preorderDiligenceIds[i];
    const currQuestion = diligenceQuestions.find((q) => q.label === currId);
    if (currQuestion?.answer?.idToReuse) {
      // Don't include reused nodes
      continue;
    }

    // have to account for both saved data (which may be stale) and the potentially
    // new name from the frontend that has not yet been saved
    let cleanedCurrName = currQuestion?.answer?.name?.toLowerCase();

    if (currId === label && cleanedIncomingName !== cleanedCurrName) {
      cleanedCurrName = cleanedIncomingName;
    }

    if (cleanedCurrName && !seenNames.has(cleanedCurrName)) {
      seenNames.add(cleanedCurrName);

      const relevantDataPoints = {
        label: currQuestion.label,
        // retains formatting of the original node's name
        // to correctly display what node is the original
        name: currQuestion?.answer?.name,
      };

      firstDiligenceQuestionPerCleanedName[cleanedCurrName] =
        relevantDataPoints;
    }
  }

  return firstDiligenceQuestionPerCleanedName;
}

export function genOwnerErrors(dataToSubmit) {
  const nameErrors = {};
  const genericNameErrorMessage = 'You must provide a name.';
  const genericTypeErrorMessage = 'You must provide a type.';

  /* eslint-disable no-restricted-syntax */
  for (const [key, value] of Object.entries(dataToSubmit)) {
    if (!isOwnerType(key, value)) {
      continue;
    }

    // only node owners are stored in lists
    let currOwnerListContainsError = false;
    const currOwnerListErrors = [];

    for (let i = 0; i < value.length; i += 1) {
      const currOwnerEntry = value[i];
      const currEntryErrorData = {};

      if (currOwnerEntry.name === '') {
        currOwnerListContainsError = true;
        currEntryErrorData.name = genericNameErrorMessage;
      }
      if (currOwnerEntry.type === '') {
        currOwnerListContainsError = true;
        currEntryErrorData.type = genericTypeErrorMessage;
      }

      currOwnerListErrors.push(currEntryErrorData);
    }

    if (currOwnerListContainsError) {
      nameErrors[key] = currOwnerListErrors;
    }
  }
  return nameErrors;
}

export function genDataTestLabel(originalLabel) {
  const lowerCaseLabel = originalLabel.toLowerCase();
  return lowerCaseLabel.replace(/\s/g, '_');
}

export function getOwnerTypeDisplay(ownerType) {
  switch (ownerType) {
    case TYPE_LLC:
      return 'Limited Liability Company (LLC)';
    case TYPE_IRA:
      return 'Individual Retirement Account (IRA)';
    default:
      return ownerType;
  }
}

export function getGroupedNodeTypes(jurisdiction = jurisdictions.USA) {
  const relevantJurisdictionData =
    getGroupedOwnerRequirementsForJurisdiction(jurisdiction) || {};

  if (!relevantJurisdictionData) {
    return [];
  }
  const entries = Object.entries(relevantJurisdictionData);

  // due to constraints on how mui select uses menu sub headers, we must return data like this
  return entries.map(([groupName, ownerData]) => [
    { name: groupName, isGroupName: true },
    ...Object.keys(ownerData),
  ]);
}

function gatherDependentNodes(dependentLabels, diligenceQuestions) {
  return diligenceQuestions.filter((q) => dependentLabels.includes(q.label));
}

function combineDependentNodeData(diligenceQuestions) {
  // should be called after gatherAllDependentNodes
  return diligenceQuestions.map((question) => {
    const dependentNodes = gatherDependentNodes(
      question.dependentLabels,
      diligenceQuestions,
    );

    const numDependentNodeComments = dependentNodes.reduce(
      (acc, curr) => acc + curr?.numCommentEvents,
      0,
    );
    const numUnresolvedDependentNodes = dependentNodes.filter(
      (node) => !node?.isThreadResolved,
    ).length;

    return {
      ...question,
      numUnresolvedThreads:
        (!question?.isThreadResolved ? 1 : 0) + numUnresolvedDependentNodes,
      numCommentEvents: question?.numCommentEvents + numDependentNodeComments,
    };
  });
}

export function annotateDiligenceQuestions(diligenceQuestions) {
  // compute additional question attributes based off of
  // state in other questions
  const markedWithReusedIds = gatherAllDependentNodes(diligenceQuestions);
  const renamedQuestionsWithNames =
    addNameDataToReuseQuestions(markedWithReusedIds);

  return combineDependentNodeData(renamedQuestionsWithNames);
}

export function mapLabelToType(diligenceQuestions) {
  if (!diligenceQuestions) {
    return {};
  }

  return diligenceQuestions.reduce((acc, question) => {
    if (question?.answer?.idToReuse) {
      const originalId = question.answer.idToReuse;
      const originalQuestion = diligenceQuestions.find(
        (q) => q.label === originalId,
      );

      acc[question.label] = originalQuestion?.answer?.type || '';
      return acc;
    }

    acc[question.label] = question?.answer?.type || '';
    return acc;
  }, {});
}

export function annotateOwnersWithTypes(diligenceQuestions) {
  if (!diligenceQuestions) {
    return diligenceQuestions;
  }

  const questionLabelToType = mapLabelToType(diligenceQuestions);

  for (let i = 0; i < diligenceQuestions.length; i += 1) {
    const question = diligenceQuestions[i];
    if (!question.answer || Object.keys(question.answer)?.length === 0) {
      continue;
    }

    const answerKeys = Object.keys(question.answer);
    for (let j = 0; j < answerKeys.length; j += 1) {
      const key = answerKeys[j];
      const ownerList = question.answer[key];
      if (!isOwnerType(key, ownerList)) {
        continue;
      }

      for (let k = 0; k < ownerList.length; k += 1) {
        const currOwnerData = ownerList[k];

        currOwnerData.type = questionLabelToType[currOwnerData.id];
      }
    }
  }

  return diligenceQuestions;
}

export function getExpirationDate(file, expirationDates) {
  return {
    file: file.fileId,
    expirationDate:
      expirationDates?.find(
        (date) =>
          date.file?.fileId === file.fileId || date.file === file.fileId,
      )?.expirationDate || null,
    fileError: null,
    dateError: null,
  };
}
