import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ContentCopyIcon from 'mdi-material-ui/ContentCopy';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import SelectionDragIcon from 'mdi-material-ui/SelectionDrag';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Button } from 'components/button';

import { BoxInput } from './box_input';

import { HellosignBoxUsageWarnings } from '../warnings/usage_warnings';
import { boxTypes } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  choiceContext: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  choiceNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  choiceName: {
    fontWeight: '600',
    color: theme.palette.text.secondary,
  },
  cardContent: {
    paddingTop: 0,
  },
  choiceText: {
    backgroundColor: 'white',
  },
  leftInput: {
    marginRight: theme.spacing(1),
  },
  warning: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export function ChoiceInput({
  id,
  text,
  exclusive,
  index,
  onChange,
  onDelete,
  onCopy,
  hellosignBoxes,
  setPage,
  changeBox,
  deleteBox,
  dragBox,
  addBox,
  answerType,
  highlightBoxId,
  setHighlightBoxId,
  questions,
  handleClickItem,
  questionId,
  handleAddChoiceLink,
  links,
  replaceLink,
  deleteLink,
  selectDocument,
}) {
  const classes = useStyles();

  const hellosignOutputBoxes = hellosignBoxes.filter(
    (box) => box.type !== boxTypes.CONTEXT,
  );
  const choiceContextBoxes = hellosignBoxes.filter(
    (box) => box.type === boxTypes.CONTEXT,
  );

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent className={classes.cardContent}>
        <HellosignBoxUsageWarnings
          classes={classes}
          boxes={hellosignOutputBoxes}
          answerType={answerType}
        />

        <div className={classes.actions}>
          <div className={classes.choiceNameContainer}>
            <DragIndicatorIcon />
            <Typography className={classes.choiceName}>
              Choice {index + 1}
            </Typography>
            <Tooltip title={<Typography>Delete</Typography>}>
              <IconButton aria-label="delete" onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography>Copy</Typography>}>
              <IconButton aria-label="copy" onClick={onCopy}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </div>

          <div>
            <Button
              variant="text"
              startIcon={<SelectionDragIcon />}
              onClick={() => handleAddChoiceLink(id)}
            >
              Context
            </Button>
            <Button
              variant="text"
              className={classes.leftInput}
              startIcon={<SelectionDragIcon />}
              onClick={() => addBox({ type: boxTypes.TEXTBOX, choice: id })}
            >
              Text
            </Button>
            <Button
              variant="text"
              startIcon={<SelectionDragIcon />}
              onClick={() => addBox({ type: boxTypes.CHECKBOX, choice: id })}
            >
              Checkbox
            </Button>
          </div>
        </div>
        <TextField
          value={text}
          className={classes.choiceText}
          onChange={(e) => {
            onChange({
              id,
              text: e.target.value,
              ...(answerType === 'multi_select' && { exclusive }),
            });
          }}
          variant="outlined"
          multiline
          minRows={3}
          fullWidth
          required
          inputProps={{ style: { resize: 'revert' } }}
          label="Choice text"
        />
        {answerType === 'multi_select' ? (
          <FormControl className={classes.root}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={exclusive}
                  onChange={(e) => {
                    onChange({ id, text, exclusive: e.target.checked });
                  }}
                  name="exclusive"
                />
              }
              label="Exclusive"
            />
          </FormControl>
        ) : null}
        {choiceContextBoxes.map((choiceContextBox) => {
          const choiceContextLink = links.find(
            (link) => link.id === choiceContextBox.linkId,
          );
          const onDeleteChoiceLink = () => {
            deleteLink(choiceContextBox.linkId);
          };
          return (
            <Card
              key={choiceContextBox.id}
              variant="outlined"
              className={classes.choiceContext}
            >
              <Typography className={classes.choiceName}>Context</Typography>
              <TextField
                value={choiceContextLink.text}
                onChange={(e) => {
                  replaceLink({
                    ...choiceContextLink,
                    text: e.target.value,
                  });
                }}
                variant="outlined"
                fullWidth
                required
                label="Link text"
              />
              <BoxInput
                box={choiceContextBox}
                onChange={changeBox}
                onDelete={onDeleteChoiceLink}
                onSelectDrag={dragBox}
                setPage={setPage}
                highlight={highlightBoxId === choiceContextBox.id}
                setHighlightBoxId={setHighlightBoxId}
                questions={questions}
                handleClickItem={handleClickItem}
                questionId={questionId}
                contextOnly
                selectDocument={selectDocument}
              />
            </Card>
          );
        })}
        {hellosignOutputBoxes.map((box) => (
          <div key={box.id}>
            <BoxInput
              box={box}
              onChange={changeBox}
              onDelete={deleteBox}
              onSelectDrag={dragBox}
              setPage={setPage}
              highlight={highlightBoxId === box.id}
              setHighlightBoxId={setHighlightBoxId}
              questions={questions}
              handleClickItem={handleClickItem}
              questionId={questionId}
              selectDocument={selectDocument}
            />
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
