/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Modal, Alert } from '@passthrough/uikit';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';

import * as api from 'services/api';
import { useToast } from 'services/toast';
import { fundStaffToolsUrl } from 'services/urls';
import { getAutocompleteOptionLabelByName } from 'services/utils';
import { DataValueDisplay } from 'components/data_value_display';
import { getJurisdictionDisplayName } from 'components/jurisdiction_select';
import { ManagedDiligenceInputs } from 'components/managed_diligence';

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    userSelect: 'none',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  managedDiligenceInputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const EDIT_STEP = 0;
const REVIEW_STEP = 1;

const NO_RADIO_BUTTON_VALUE = 'no';
const YES_RADIO_BUTTON_VALUE = 'yes';

function EditStepContent({
  fundName,
  setFundName,
  orgExists,
  setOrgExists,
  allOrgs,
  organizationId,
  organizationName,
  setOrganizationName,
  setOrganizationId,
  amlCoEnabled,
  setAmlCoEnabled,
  setManagedDiligenceEnabled,
  managedDiligenceEnabled,
  possibleDiligenceReviewers,
  diligenceReviewerEmail,
  setDiligenceReviewerEmail,
  initialJurisdiction,
  setInitialJurisdiction,
}) {
  const classes = useStyles();

  const selectedOrg = allOrgs.find((org) => org.id === organizationId);

  const organizationContainsManagedDiligence =
    selectedOrg?.managedDiligenceEnabledDefault;
  const showDisableManagedDiligenceAlert =
    organizationContainsManagedDiligence && !managedDiligenceEnabled;

  return (
    <>
      <TextField
        autoFocus
        required
        id="name"
        variant="outlined"
        label="Fund name"
        type="text"
        value={fundName}
        onChange={(e) => setFundName(e.target.value)}
        fullWidth
      />

      <FormControl required component="fieldset" fullWidth>
        <FormLabel component="legend">
          Does the organization already exist?
        </FormLabel>

        <RadioGroup
          row
          aria-label="organization-exists"
          name="row-radio-buttons-group"
          value={orgExists}
          onChange={(e) => {
            setOrgExists(e.target.value);
          }}
        >
          <FormControlLabel
            value={NO_RADIO_BUTTON_VALUE}
            control={<Radio />}
            label="No"
          />
          <FormControlLabel
            value={YES_RADIO_BUTTON_VALUE}
            control={<Radio />}
            label="Yes"
          />
        </RadioGroup>
      </FormControl>

      {orgExists === NO_RADIO_BUTTON_VALUE ? (
        <TextField
          required
          id="organization-name"
          variant="outlined"
          label="Organization name"
          type="text"
          value={organizationName}
          onChange={(e) => setOrganizationName(e.target.value)}
          helperText="The fund will be created under this new organization"
          fullWidth
        />
      ) : (
        <Autocomplete
          selectOnFocus
          options={allOrgs}
          value={organizationId}
          onChange={(e, org) => {
            setOrganizationId(org?.id);
            setOrganizationName(org?.name);
            setManagedDiligenceEnabled(org?.managedDiligenceEnabledDefault);
            setAmlCoEnabled(org?.amlCoEnabled);
          }}
          renderOption={(option) => <>{option.name}</>}
          getOptionLabel={(option) =>
            getAutocompleteOptionLabelByName(option, allOrgs)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Organization"
              variant="outlined"
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: 'chrome-off',
                // avoiding id to thwart chrome autofill
                'data-test': 'staff-organization-select',
              }}
            />
          )}
        />
      )}

      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            checked={managedDiligenceEnabled}
            onChange={(e) => {
              setManagedDiligenceEnabled(e.target.checked);
            }}
          />
        }
        label="Enable managed diligence service"
      />

      {showDisableManagedDiligenceAlert ? (
        <Alert severity="warning">
          This organization already contains a managed diligence fund. Are you
          sure that this new fund should not be managed too?
        </Alert>
      ) : null}

      <Collapse in={managedDiligenceEnabled}>
        <div className={classes.managedDiligenceInputs}>
          <ManagedDiligenceInputs
            possibleDiligenceReviewers={possibleDiligenceReviewers}
            diligenceReviewerEmail={diligenceReviewerEmail}
            setDiligenceReviewerEmail={setDiligenceReviewerEmail}
            initialJurisdiction={initialJurisdiction}
            setInitialJurisdiction={setInitialJurisdiction}
            enableEditingAmlCO={
              orgExists !== YES_RADIO_BUTTON_VALUE || !selectedOrg?.amlCoEnabled
            }
            amlCoEnabled={amlCoEnabled}
            setAmlCoEnabled={setAmlCoEnabled}
            askJurisdiction
          />
        </div>
      </Collapse>
    </>
  );
}

function ReviewStepContent({
  errorMsg,
  fundName,
  orgExists,
  orgName,
  managedDiligenceEnabled,
  diligenceReviewerEmail,
  initialJurisdiction,
}) {
  return (
    <>
      {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : null}

      <DataValueDisplay label="Fund name" value={fundName} />

      <DataValueDisplay
        label="Does the organization already exist?"
        value={orgExists === YES_RADIO_BUTTON_VALUE ? 'Yes' : 'No'}
      />

      <DataValueDisplay label="Organization name" value={orgName} />

      <DataValueDisplay
        label="Managed diligence service"
        value={managedDiligenceEnabled ? 'Enabled' : 'Disabled'}
      />

      <DataValueDisplay
        label="Diligence reviewer email"
        value={diligenceReviewerEmail}
      />
      {managedDiligenceEnabled ? (
        <DataValueDisplay
          label="Diligence jurisdiction"
          value={getJurisdictionDisplayName(initialJurisdiction)}
        />
      ) : null}
    </>
  );
}

export function CreateFundModal({ open, handleClose }) {
  const { successToast } = useToast();
  const history = useHistory();

  const [allOrgs, setAllOrgs] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [fundName, setFundName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [organizationId, setOrganizationId] = useState(null);
  const [orgExists, setOrgExists] = useState(NO_RADIO_BUTTON_VALUE); // Toggle for input field type
  const [managedDiligenceEnabled, setManagedDiligenceEnabled] = useState(false);
  const [amlCoEnabled, setAmlCoEnabled] = useState(false);
  const [diligenceReviewerEmail, setDiligenceReviewerEmail] = useState(null);
  const [initialJurisdiction, setInitialJurisdiction] = useState(null);
  const [possibleDiligenceReviewers, setPossibleDiligenceReviewers] = useState(
    [],
  );
  const [step, setStep] = useState(EDIT_STEP);

  const cannotReviewYet =
    !fundName ||
    !organizationName ||
    (managedDiligenceEnabled &&
      (!diligenceReviewerEmail || !initialJurisdiction));

  function handleSubmit(e) {
    e.preventDefault();

    const trimmedName = fundName.trim();
    const trimmedOrgName = organizationName.trim();
    api
      .staffFundCreate({
        fundName: trimmedName,
        organizationName: trimmedOrgName,
        organizationId,
        managedDiligenceEnabled,
        diligenceReviewerEmail: managedDiligenceEnabled
          ? diligenceReviewerEmail
          : null,
        initialJurisdiction: managedDiligenceEnabled
          ? initialJurisdiction
          : null,
        amlCoEnabled: managedDiligenceEnabled ? amlCoEnabled : null,
      })
      .then((response) => {
        handleClose();
        successToast(
          `Created ${trimmedName} with organization ${trimmedOrgName}.`,
        );
        history.push(fundStaffToolsUrl({ fundId: response.data.id }));
        setFundName('');
        setOrganizationName('');
        setOrganizationId(null);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          // Should only be one error from the serializer at a time
          const { data } = error.response;
          if (data && data.nonFieldErrors) {
            setErrorMsg(error.response.data.nonFieldErrors);
          }
        }
      });
  }

  function getOrganizations() {
    api.allOrganizations().then((response) => {
      setAllOrgs(response.data);
    });
  }

  useEffect(() => {
    api.listDiligenceReviewers().then((response) => {
      setPossibleDiligenceReviewers(response.data);
    });
  }, []);
  useEffect(getOrganizations, []);
  useEffect(() => {
    setOrganizationName('');
    setOrganizationId(null);
  }, [orgExists]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      headerLabel="New fund"
      primaryButtonText={step === EDIT_STEP ? 'Next: review' : 'Create fund'}
      onSubmit={(e) => {
        if (step === EDIT_STEP) {
          setStep(REVIEW_STEP);
          setErrorMsg(null);
        }

        if (step === REVIEW_STEP) {
          handleSubmit(e);
        }
      }}
      primaryButtonDisabled={step === EDIT_STEP && cannotReviewYet}
      onBack={
        step === REVIEW_STEP
          ? () => {
              setStep(EDIT_STEP);
            }
          : null
      }
      onExited={() => {
        setStep(EDIT_STEP);
        setErrorMsg(null);
        setFundName('');
        setOrganizationName('');
        setOrganizationId(null);
        setManagedDiligenceEnabled(false);
        setAmlCoEnabled(false);
        setDiligenceReviewerEmail(null);
      }}
    >
      {step === EDIT_STEP ? (
        <EditStepContent
          fundName={fundName}
          setFundName={setFundName}
          orgExists={orgExists}
          setOrgExists={setOrgExists}
          allOrgs={allOrgs}
          organizationId={organizationId}
          organizationName={organizationName}
          setOrganizationName={setOrganizationName}
          setOrganizationId={setOrganizationId}
          setManagedDiligenceEnabled={setManagedDiligenceEnabled}
          managedDiligenceEnabled={managedDiligenceEnabled}
          possibleDiligenceReviewers={possibleDiligenceReviewers}
          diligenceReviewerEmail={diligenceReviewerEmail}
          setDiligenceReviewerEmail={setDiligenceReviewerEmail}
          initialJurisdiction={initialJurisdiction}
          setInitialJurisdiction={setInitialJurisdiction}
          amlCoEnabled={amlCoEnabled}
          setAmlCoEnabled={setAmlCoEnabled}
        />
      ) : null}

      {step === REVIEW_STEP ? (
        <ReviewStepContent
          errorMsg={errorMsg}
          fundName={fundName}
          orgExists={orgExists}
          orgName={organizationName}
          managedDiligenceEnabled={managedDiligenceEnabled}
          diligenceReviewerEmail={diligenceReviewerEmail}
          initialJurisdiction={initialJurisdiction}
        />
      ) : null}
    </Modal>
  );
}
