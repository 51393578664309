import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuestionnaire } from 'services/providers/questionnaire';

import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { DndMultiFileUpload } from 'components/dnd_file_upload/multi_file_upload';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'inline-block',
  },
  fileUploadMinWidth: {
    minWidth: '600px',
    [theme.breakpoints.down('md')]: {
      minWidth: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '250px',
    },
  },
}));

export function MultiFileUploadQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  isRequired,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialFiles = answer || [];
  const [files, setFiles] = useState(initialFiles);
  const [errorMsg, setErrorMsg] = useState('');
  const classes = useStyles();
  const hasInput = Boolean(files.length);
  const { lpDocumentId } = useParams();
  const { buildSingleFileUploadUrl } = useQuestionnaire();
  const url = buildSingleFileUploadUrl(lpDocumentId);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({
      label,
      answer: files.map((file) => file.fileId),
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer[0]);
      }
      return error;
    });
  }

  function handleChange(newFiles) {
    setSaving(UNSAVED);
    setFiles(newFiles);
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setFiles(initialFiles);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <FormControl className={classes.form} error={!!errorMsg}>
          <div className={classes.fileUploadMinWidth}>
            <DndMultiFileUpload
              files={files}
              onChange={handleChange}
              url={url}
            />
          </div>
          <FormHelperText className={classes.error}>{errorMsg}</FormHelperText>
        </FormControl>
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={files.length === 0 && isRequired}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
