import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { GIINInput } from 'components/giin';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

// the letter O will never be used in a valid GIIN code
const giinRegex = new RegExp(
  /^([A-NP-Z0-9]){6}\.([A-NP-Z0-9]){5}\.[A-Z]{2}\.\d{3}$/,
);
const emptyGiin = '______._____.__.___';

export function GIINQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialGiin = answer || '';
  const [giin, setGiin] = useState(initialGiin);
  const [errorMsg, setErrorMsg] = useState('');
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    const result = giin.match(giinRegex);
    setValid(result);
  }, [giin]);

  const hasInput = Boolean(giin);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: giin, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  const handleChange = (e) => {
    setSaving(UNSAVED);
    const uppercaseGIIN = e.target.value.toUpperCase();
    if (uppercaseGIIN === emptyGiin) {
      setGiin('');
    } else {
      setGiin(uppercaseGIIN);
    }
  };

  // Reset the input when we change questions.
  useEffect(() => {
    setGiin(initialGiin);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        <TextField
          id="giin"
          autoFocus
          label="GIIN"
          variant="outlined"
          value={giin}
          error={!!errorMsg}
          helperText={errorMsg}
          onChange={handleChange}
          InputProps={{
            inputComponent: GIINInput,
          }}
          fullWidth
        />
        <QuestionStepper
          handleSubmit={handleSubmit}
          disabled={!isValid}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
