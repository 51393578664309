/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Alert } from 'components/alert';
import { FileUpload } from '@passthrough/uikit';

import { Button, LinkButton } from 'components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'components/dialog/index';
import * as api from 'services/api';
import { getSingleErrorFromResponse } from 'services/utils';
import { getEmptyFile } from 'components/file_upload';

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(0.5, 0),
  },
  error: {
    padding: theme.spacing(0, 3),
    overflowWrap: 'anywhere',
  },
  content: {
    width: '100%',
    minWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export function UploadLogoDialog({ open, handleClose }) {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [file, setFile] = React.useState(getEmptyFile());
  const [fileUrl, setFileUrl] = React.useState(null);
  const [name, setName] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  function onSubmit(e) {
    e.preventDefault();
  }

  const updateFile = (f) => {
    setFile(f);

    if (!name) {
      setName(f.name);
    }
  };

  function uploadFiles(acceptedFiles) {
    updateFile(acceptedFiles[0]);
    return Promise.resolve(acceptedFiles);
  }

  const handleSubmit = () => {
    setErrorMsg('');
    setLoading(true);
    api
      .uploadLogo({ file, name })
      .then((response) => {
        setFileUrl(response.data.url);
      })
      .catch((e) => {
        setErrorMsg(getSingleErrorFromResponse(e.response));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (open) {
      setErrorMsg('');
      setName('');
      setFile(getEmptyFile());
      setFileUrl(null);
      setLoading(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={onSubmit} autoComplete="off">
        <DialogTitle id="form-dialog-title">Upload logo</DialogTitle>
        {errorMsg ? (
          <div className={classes.error}>
            <Alert severity="error">{errorMsg}</Alert>
          </div>
        ) : null}
        <DialogContent className={classes.content}>
          {fileUrl ? (
            <LinkButton url={fileUrl}>{name}</LinkButton>
          ) : (
            <>
              <FileUpload
                files={{
                  ...file,
                  fileName: name || file?.fileName,
                  fileUrl: fileUrl || file?.fileUrl,
                }}
                onAccept={updateFile}
                onDelete={() => {
                  updateFile(getEmptyFile());
                }}
                uploadFiles={uploadFiles}
                acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                helperText="Upload an image file (.png, .jpeg, .jpg)"
                extraInputProps={{ disabled: isLoading }}
              />
              <TextField
                required
                className={classes.input}
                id="name"
                variant="outlined"
                label="Logo name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                disabled={isLoading}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {fileUrl ? (
            <Button
              type="button"
              onClick={handleClose}
              variant="contained"
              color="primary"
              fullWidth
            >
              Close
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={!file?.name || !name || isLoading}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
            >
              Upload
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
