import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Button } from 'components/button';
import { useQuestionnaire } from 'services/providers/questionnaire';
import { OverviewQuestionRowV2 } from './overview_question_row_v2';
import { SAVED } from './saved';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navRoot: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      rowGap: theme.spacing(2),
      alignItems: 'inherit',
    },
    marginTop: theme.spacing(3),
    maxWidth: '1200px',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  questionDiv: {
    maxWidth: '1200px',
    width: '100%',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    overflow: 'hidden',
  },
  nextDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    rowGap: theme.spacing(1),
  },
  advanceButton: {
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
  },
  nextSectionNameDisplay: {
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
}));

function SectionOverViewNavigation({ onConfirmSection, navRef }) {
  const classes = useStyles();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { scrollToTop } = useQuestionnaire();

  const nav = navRef.current;
  const section = nav.getCurrentSection();

  const showPrevButton = nav.innerStep > 0 || nav.outerStep > 0;
  const showConfirmButton = section.isCompleted && !section.isConfirmed;
  const showNextRequiredButton = !section.isCompleted || section.isConfirmed;
  const showNextSectionName = section.isCompleted || section.isConfirmed;
  const nextSectionName = nav.getNextSectionToWorkOn();

  function handleSubmit(e) {
    e.preventDefault();
    setConfirmLoading(true);

    const sectionId = section.id ? section.id : section.label;

    return onConfirmSection({ sectionId }).then(() => {
      navRef.current.toNextSection(navRef.lpDoc);
      setConfirmLoading(false);
    });
  }

  useEffect(() => {
    scrollToTop();
  }, [navRef]);

  return (
    <div className={classes.navRoot}>
      <Button
        style={{ visibility: showPrevButton ? '' : 'hidden' }}
        variant="text"
        color="default"
        size="xl"
        startIcon={<KeyboardArrowLeftIcon />}
        onClick={() => nav.decrement()}
      >
        Back
      </Button>

      <div className={classes.nextDiv}>
        {showConfirmButton ? (
          <Button
            id="continue"
            size="xl"
            variant="contained"
            selected={section.isConfirmed}
            loading={confirmLoading}
            onClick={handleSubmit}
            className={classes.advanceButton}
          >
            Confirm
          </Button>
        ) : null}

        {showNextRequiredButton ? (
          <Button
            id="section-next-required"
            size="xl"
            variant="contained"
            endIcon={<KeyboardArrowRightIcon />}
            onClick={() => nav.toNextRequired()}
            className={classes.advanceButton}
          >
            Next required
          </Button>
        ) : null}

        {showNextSectionName && nextSectionName ? (
          <Typography
            variant="body2"
            align="center"
            className={classes.nextSectionNameDisplay}
          >
            Next: {nextSectionName}
          </Typography>
        ) : null}
      </div>
    </div>
  );
}

export function SectionOverview({
  onConfirmSection,
  navRef,
  setSaving,
  outlined,
}) {
  const nav = navRef.current;

  const classes = useStyles();
  const section = nav.getCurrentSection();

  const questions = section.questions
    // remember what their index was
    .map((q, i) => ({
      ...q,
      questionIndex: i,
    }));

  useEffect(() => {
    // reset the saving state when landing on a new section
    setSaving(SAVED);
  }, [section.id]);

  return (
    <Fade in>
      <div className={classes.container}>
        <Typography variant="h5" align="center" className={classes.title}>
          {section.label}
        </Typography>

        <div className={classes.questionDiv}>
          {questions.map((question) => (
            <OverviewQuestionRowV2
              key={question.label}
              question={question}
              outlined={outlined}
              navigateToQuestion={() => {
                nav.toQuestion(question.questionIndex);
              }}
            />
          ))}
        </div>

        <SectionOverViewNavigation
          onConfirmSection={onConfirmSection}
          navRef={navRef}
        />
      </div>
    </Fade>
  );
}
