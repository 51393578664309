import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@passthrough/uikit';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullWidth: {
    width: '100%',
  },
}));

const SMS_TIMEOUT = 30;
const LOCAL_STORAGE_KEY = 'mfa:sms:lastSentAt';

function getRemainingTime(lastSentAt) {
  if (!lastSentAt) return 0;
  const diff = new Date() - new Date(lastSentAt);
  return Math.max(SMS_TIMEOUT - Math.floor(diff / 1000), 0);
}

export function useSmsOtpTimer({ onEnd }) {
  const storedLastSentAt = localStorage.getItem(LOCAL_STORAGE_KEY);
  const initialRemaining = getRemainingTime(storedLastSentAt);

  const [remaining, setRemaining] = useState(initialRemaining);

  const timeoutText =
    remaining > 0
      ? `Too soon to resend. Try again in ${remaining} seconds.`
      : null;
  const allowSend = remaining <= 0;

  const onSend = () => {
    const now = new Date().toISOString();
    localStorage.setItem(LOCAL_STORAGE_KEY, now);
    setRemaining(SMS_TIMEOUT);
  };

  useEffect(() => {
    if (allowSend) return () => {};

    const interval = setInterval(() => {
      setRemaining((prevRemaining) => {
        if (prevRemaining <= 1) {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
          onEnd();
          return 0;
        }
        return prevRemaining - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [allowSend]);

  return { timeoutText, allowSend, onSend };
}

export function ResendVerificationButton({
  allowSend,
  timeoutText,
  errorMsg = null,
  ...buttonProps
}) {
  const classes = useStyles();
  const [showError, setShowError] = useState(false);

  const { onClick, disabled, loading, fullWidth } = buttonProps;

  // Pseudo disabled means that you can press the button, but
  // it renders timeoutText instead calling onClick
  const buttonIsPseudoDisabled = disabled || !allowSend;

  useEffect(() => {
    if (allowSend) {
      setShowError(false);
    }
  }, [allowSend]);

  function handleClick(e) {
    if (buttonIsPseudoDisabled) {
      e.preventDefault();
      setShowError(true);
      return;
    }
    onClick(e);
  }

  return (
    <div
      className={clsx(classes.container, { [classes.fullWidth]: fullWidth })}
    >
      <div className={clsx({ [classes.fullWidth]: fullWidth })}>
        <Button {...buttonProps} disabled={loading} onClick={handleClick} />
      </div>
      {showError ? <FormHelperText error>{timeoutText}</FormHelperText> : null}
      {errorMsg ? <FormHelperText error>{errorMsg}</FormHelperText> : null}
    </div>
  );
}
