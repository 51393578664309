import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DisplayLink } from 'components/link';
import { GIINInput } from 'components/giin';
import { Answers } from '../answers';
import { UNSAVED } from '../saved';

// the letter O will never be used in a valid GIIN code
const giinRegex = new RegExp(
  /^([A-NP-Z0-9]){6}\.([A-NP-Z0-9]){5}\.[A-NP-Z]{2}\.\d{3}$/,
);
const emptyGiin = '______._____.__.___';

export function GIINWithEscapeQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  QuestionStepper,
  QuestionPromptComponent,
}) {
  const initialGiin = answer || '';
  const [giin, setGiin] = useState(initialGiin);
  const [errorMsg, setErrorMsg] = useState('');
  const isValid = giin.match(giinRegex);

  const [showSmartInput, setShowSmartInput] = useState(!giin || isValid);
  const hasInput = Boolean(giin);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMsg('');

    return updateLpDoc({ label, answer: giin, skipQuestion: !hasInput }).catch(
      (error) => {
        setSaving(UNSAVED);
        if (error.response?.status === 400) {
          setErrorMsg(error.response.data.answer);
        }
        return error;
      },
    );
  }

  const handleChange = (e) => {
    setSaving(UNSAVED);
    const uppercaseGIIN = e.target.value.toUpperCase();
    if (uppercaseGIIN === emptyGiin) {
      setGiin('');
    } else {
      setGiin(uppercaseGIIN);
    }
  };

  // IMask comp (used in giin comp) still displays the masked string even if no input is provided
  // as opposed to NumberFormat (from ssn component) -> must manually clear giin state
  // when disabling smartInput state if giin only includes formatting chars
  function disableGIIN() {
    setShowSmartInput(false);
    const inputChars = /[^A-N,P-Z,0-9]/gm;
    const nonFormattedChars = giin.replace(inputChars, '');

    if (nonFormattedChars === '') {
      setGiin('');
    }
  }

  // Reset the input when we change questions.
  useEffect(() => {
    setGiin(initialGiin);
    setErrorMsg('');
  }, [label]);

  return (
    <form onSubmit={handleSubmit}>
      <QuestionPromptComponent />
      <Answers>
        {showSmartInput ? (
          <>
            <TextField
              id="giin"
              autoFocus
              label="GIIN"
              variant="outlined"
              value={giin}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={handleChange}
              InputProps={{
                inputComponent: GIINInput,
              }}
              fullWidth
            />
            <FormHelperText>
              <DisplayLink onClick={disableGIIN}>
                I don't have a GIIN ›
              </DisplayLink>
            </FormHelperText>
          </>
        ) : (
          <>
            <TextField
              id="giin"
              autoFocus
              label="Other Tax ID"
              variant="outlined"
              value={giin}
              error={!!errorMsg}
              helperText={errorMsg}
              onChange={handleChange}
              fullWidth
            />
            <FormHelperText>
              <DisplayLink
                onClick={() => {
                  setShowSmartInput(true);
                }}
              >
                I have a GIIN ›
              </DisplayLink>
            </FormHelperText>
          </>
        )}
        <QuestionStepper
          disabled={(!isValid && showSmartInput) || (!giin && !showSmartInput)}
          handleSubmit={handleSubmit}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
