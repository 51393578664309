/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { isValid, isBefore, isAfter } from 'date-fns';

const MIN_DATE = new Date('1900-01-01'); // default keyboardPicker min date
const MAX_DATE = new Date('2100-01-01'); // default keyboardPicker max date

export function isValidDate(date) {
  return isValid(date) && isBefore(date, MAX_DATE) && isAfter(date, MIN_DATE);
}

export function DatePicker({ date, setDate, ...props }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        id="date-picker-inline"
        value={date}
        onChange={(d) => {
          setDate(d);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
