import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Typography, Icons, Button, Chip } from '@passthrough/uikit';

import { MethodTable } from 'pages/mfa_setup_flow/method_table';
import * as constants from 'pages/mfa_setup_flow/constants';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { useMe } from 'services/providers/me';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import { PageContainer } from 'components/page_container';
import { NameModal } from './name_modal';
import { DisableMFAModal } from './disable_mfa_modal';
import { CTAForMFA } from './cta_for_mfa';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 1),
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    flexDirection: 'column',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  withChip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  gap: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const NAME_MODAL = 'name';
const DISABLE_MODAL = 'disable';

export function AccountPage({ goToMFASetup }) {
  const classes = useStyles();
  const [me, onChangeMe] = useMe();
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(me.firstName || '');
  const [lastName, setLastName] = useState(me.lastName || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [modal, setModal] = useState(null);
  const { successToast } = useToast();

  const fullName = `${me.firstName} ${me.lastName}`;
  const mfaMethod = constants.getMethod({
    isTotp: me.totpEnabled,
    isSms: me.smsEnabled,
  });

  function onSubmit(e) {
    e.preventDefault();

    setSaving(true);
    api
      .saveUserSettings({ firstName, lastName })
      .then(() => {
        setSaving(false);
        successToast('Settings updated.');
        setModal(null);
        setFirstNameError('');
        setLastNameError('');
        onChangeMe();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setFirstNameError(error.response.data.firstName);
          setLastNameError(error.response.data.lastName);
        }
      })
      .finally(() => {
        setSaving(false);
      });
  }

  useEffect(() => {
    setFirstName(me.firstName);
    setLastName(me.lastName);
  }, [me]);

  return (
    <>
      <PageContainer maxWidth="sm">
        <Paper className={classes.paper}>
          <div className={classes.top}>
            <Icons.PersonOutline fontSize="large" />
            <Typography variant="section-heading">Account settings</Typography>
          </div>

          <KeyValuePairs>
            <KeyValuePair icon={<Icons.EmailOutlined />}>
              <Typography>{me.email}</Typography>
            </KeyValuePair>

            <KeyValuePair icon={<Icons.PersonOutline />}>
              <div className={classes.spaceBetween}>
                <Typography>{fullName}</Typography>
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => setModal(NAME_MODAL)}
                >
                  Edit
                </Button>
              </div>
            </KeyValuePair>

            {me.hasPassword && !me.mfaEnabled ? (
              <KeyValuePair icon={<Icons.LockOutlined />}>
                <div className={classes.spaceBetween}>
                  <div className={classes.withChip}>
                    <Typography>Password</Typography>
                    <Chip label="Enabled" variant="success" size="small" />
                  </div>
                  <Typography
                    variant="label"
                    size="small"
                    color="text.secondary"
                  >
                    Contact support to make changes
                  </Typography>
                </div>
              </KeyValuePair>
            ) : null}

            {me.requiresSaml ? null : (
              <KeyValuePair alignIconToTop icon={<Icons.SecurityOutlined />}>
                <div className={classes.gap}>
                  <div className={classes.spaceBetween}>
                    <Typography variant="card-heading">
                      Two-factor authentication (2FA)
                    </Typography>
                    {me.mfaEnabled ? (
                      <Button
                        variant="text"
                        size="small"
                        onClick={() => setModal(DISABLE_MODAL)}
                      >
                        Disable 2FA
                      </Button>
                    ) : null}
                  </div>
                  {me.mfaEnabled ? (
                    <MethodTable
                      totpEnabled={me.totpEnabled}
                      smsEnabled={me.smsEnabled}
                    />
                  ) : (
                    <CTAForMFA goToMFASetup={goToMFASetup} />
                  )}
                </div>
              </KeyValuePair>
            )}
          </KeyValuePairs>
        </Paper>
      </PageContainer>

      <NameModal
        open={modal === NAME_MODAL}
        onClose={() => setModal(null)}
        firstName={firstName}
        setFirstName={setFirstName}
        firstNameError={firstNameError}
        lastName={lastName}
        setLastName={setLastName}
        lastNameError={lastNameError}
        onSubmit={onSubmit}
        saving={saving}
      />
      <DisableMFAModal
        mfaMethod={mfaMethod}
        open={modal === DISABLE_MODAL}
        onClose={() => setModal(null)}
        userId={me.id}
      />
    </>
  );
}
