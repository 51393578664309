import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';

import { Answers } from '../answers';
import { UNSAVED } from '../saved';
import { TextWithContextLinks } from '../text_with_context_links';

const useStyles = makeStyles((theme) => ({
  answer: {
    margin: theme.spacing(3),
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '350px', // same as <Question tall>
    textAlign: 'left',
  },
  wordy: {
    fontSize: '1rem !important',
    padding: theme.spacing(2),
  },
  label: {
    width: '100%',
  },
  terse: {
    fontSize: '1.5rem !important',
    padding: theme.spacing(2),
    width: '100%',
  },
  actionArea: {
    margin: theme.spacing(1, 0),
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 1,
      },
    },
  },
  compact: {
    minHeight: '100px',
  },
}));

const useLabelStyles = makeStyles(() => ({
  label: {
    fontSize: '1.5rem',
  },
}));

export function SelectQuestion({
  updateLpDoc,
  setSaving,
  answer,
  label,
  frontendLabel,
  choices,
  QuestionStepper,
  QuestionPromptComponent,
  compact,
  links,
  pdfBoxes,
}) {
  const classes = useStyles();
  const labelClasses = useLabelStyles();
  const initialChoice = answer || '';
  const [choice, setChoice] = useState(initialChoice);
  const [errorMsg, setErrorMsg] = useState('');

  const hasInput = Boolean(choice);
  const isWordy = choices.some((c) => c.text.length > 100);

  function handleSubmit(e) {
    e.preventDefault();

    return updateLpDoc({
      label,
      answer: choice,
      skipQuestion: !hasInput,
    }).catch((error) => {
      setSaving(UNSAVED);
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.answer);
      }
      return error;
    });
  }

  function handleChange(value) {
    setSaving(UNSAVED);
    setChoice(value);
  }

  // Reset the choice when changing questions
  useEffect(() => {
    setChoice(initialChoice);
    setErrorMsg('');
  }, [initialChoice]);

  return (
    <form>
      <QuestionPromptComponent short />
      <Answers tall={!compact}>
        <div
          className={clsx(classes.radioContainer, {
            [classes.compact]: compact,
          })}
        >
          <FormControl component="fieldset" error={!!errorMsg}>
            <RadioGroup
              aria-label={frontendLabel}
              name={frontendLabel}
              value={choice}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            >
              {choices.map((c) => (
                <CardActionArea className={classes.actionArea} key={c.text}>
                  <Paper variant="outlined">
                    <FormControlLabel
                      className={clsx(
                        {
                          [classes.wordy]: isWordy,
                          [classes.terse]: !isWordy,
                        },
                        classes.label,
                      )}
                      classes={isWordy ? null : labelClasses}
                      key={c.text}
                      value={c.text}
                      control={<Radio />}
                      label={
                        <TextWithContextLinks
                          text={c.text}
                          choiceId={c.id}
                          allLinksForQuestion={links}
                          allPdfBoxesForQuestion={pdfBoxes}
                        />
                      }
                    />
                  </Paper>
                </CardActionArea>
              ))}
            </RadioGroup>
            <FormHelperText error>{errorMsg}</FormHelperText>
          </FormControl>
        </div>
        <QuestionStepper
          disabled={!choice}
          handleSubmit={handleSubmit}
          hasInput={hasInput}
        />
      </Answers>
    </form>
  );
}
