import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { DndMultiFileUpload } from 'components/dnd_file_upload/multi_file_upload';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { MultiPDFNameChecker } from 'components/document_upload/multi_pdf_name_checker';
import { genDataTestLabel } from '../utils';

const useStyles = makeStyles((theme) => ({
  helperText: {
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'normal',
    lineHeight: '1.5rem',
  },
  upload: {
    display: 'block',
    margin: theme.spacing(1, 0),
  },
}));

export function DiligenceMultiFileInput({
  files: currentFiles,
  fileErrors,
  fileUploadUrl,
  onChange,
  formLabel,
  helpText = '',
  investorName,
  isStaff = false,
  enableNameChecks = false,
  ...extraInputProps
}) {
  const classes = useStyles();
  const { MULTIFILE_UPLOADS_PDF_NAME_CHECKER } = useFeatureFlags();
  const fileDataTestLabel = genDataTestLabel(formLabel);
  const [filesToCheck, setFilesToCheck] = React.useState([]);

  const handleChange = (files) => {
    if (!MULTIFILE_UPLOADS_PDF_NAME_CHECKER) {
      onChange(files);
      return;
    }
    const newFiles = files.filter(
      (file) =>
        !currentFiles.some((currentFile) => currentFile.fileId === file.fileId),
    );
    if (enableNameChecks && isStaff && newFiles.length) {
      setFilesToCheck(newFiles);
    } else {
      onChange(files);
    }
  };

  return (
    <FormControl className={classes.upload} fullWidth error={!!fileErrors}>
      <FormLabel className={classes.label} component="legend">
        {formLabel}
      </FormLabel>

      <FormHelperText className={classes.helperText}>{helpText}</FormHelperText>

      <DndMultiFileUpload
        files={currentFiles}
        onChange={handleChange}
        url={fileUploadUrl}
        {...extraInputProps}
        data-test={`diligence_v2_${fileDataTestLabel}_file_input`}
      />

      {MULTIFILE_UPLOADS_PDF_NAME_CHECKER && isStaff && enableNameChecks ? (
        <MultiPDFNameChecker
          files={currentFiles}
          investorName={investorName}
          investorLegalName={null}
          setFiles={onChange}
          filesToCheck={filesToCheck}
          setFilesToCheck={setFilesToCheck}
          isStaff={isStaff}
          entityType="node"
        />
      ) : null}
      {fileErrors ? <FormHelperText>{fileErrors}</FormHelperText> : null}
    </FormControl>
  );
}
